<template>
  <bc-wizard-step-panel>
    <div class="default">
      <bc-dropzone
        v-model="files"
        :accept="['.xlsx', '.xls', '.pdf', '.PDF', '.XLSX', '.XLS']"
        :title="$t('core.import.select.description')"
        :action-label="$t('core.import.select.button')"
        height="600px"
      />
    </div>
  </bc-wizard-step-panel>
</template>

<script>
import { BcWizardStepPanel, BcDropzone } from '@brain/ui'
import FileDownload from 'js-file-download'

export default {
  name: 'SelectFileStep',
  components: { BcWizardStepPanel, BcDropzone },
  props: {
    value: {
      type: Array
    },
    label: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      hasValidate: false
    }
  },
  mounted() {
    this.hasValidate = false
  },
  methods: {
    isAllValid() {
      return this.files.length > 0
    },
    validate() {
      this.hasValidate = true
      if (this.isAllValid()) {
        return true
      }
      return false
    },
    async doExport() {
      const result = await this.service.getExcelContainerTransfer(this.label)
      FileDownload(result.data, `${this.label}.xlsx`)
    }
  },
  computed: {
    files: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  background: #22648b;
}
</style>
