<template>
  <div class="result-area upper-case">
    <div class="result-box success" v-if="!hasErrors">
      <p class="icon"><i class="fas fa-check-square"></i></p>
      <p class="desc">
        {{ $t('core.import.result.importSuccess') }}
      </p>
    </div>
    <div class="result-box errors" v-else>
      <p class="icon"><i class="fas fa-times-octagon"></i></p>
      <p class="desc" v-if="isInvalidFile">
        {{ $t('core.import.result.importWithInvalidFile') }}
      </p>
      <p class="desc" v-if="hasCustomError">
        {{ resultData.customError }}
      </p>
      <p class="desc" v-if="hasImportLog">
        {{ $t('core.import.result.importWithError') }}
      </p>
      <p class="download" v-if="hasValidFileWithErrors" @click="downloadFile">
        {{ $t('core.import.result.exportLinesWithError') }}
      </p>
    </div>
    <div class="result-box alerts" v-if="hasAlert">
      <p class="desc" v-if="hasAlert">
        {{ $t('application.misc.import.result.importWithAlert') }}
      </p>
      <p
        class="download"
        v-if="hasValidFileWithAlert"
        @click="downloadAlertFile"
      >
        {{ $t('application.misc.import.result.exportWithAlert') }}
      </p>
    </div>
  </div>
</template>

<script>
import FileDownload from 'js-file-download'

export default {
  name: 'ImportResultTable',
  props: {
    resultData: { type: Object, require: true },
    name: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    }
  },
  computed: {
    hasErrors() {
      return (
        this.isInvalidFile ||
        (this.resultData?.errorRows && this.resultData.errorRows > 0)
      )
    },
    hasAlert() {
      return this.resultData?.alertRows && this.resultData.alertRows > 0
    },
    hasValidFileWithErrors() {
      return (
        this.hasImportLog &&
        this.resultData?.errorRows &&
        this.resultData.errorRows > 0
      )
    },
    hasValidFileWithAlert() {
      return (
        this.hasImportLog &&
        this.resultData?.alertRows &&
        this.resultData.alertRows > 0
      )
    },
    isInvalidFile() {
      return !this.hasCustomError && (this.resultData?.invalidFile || false)
    },
    hasCustomError() {
      return this.resultData?.customError || false
    },
    hasImportLog() {
      return !this.isInvalidFile && !this.hasCustomError
    }
  },
  methods: {
    async downloadFile() {
      const result = await this.service.exportResult(this.resultData.id)
      FileDownload(result.data, `${this.resultData.module || this.name}.xlsx`)
    },
    async downloadAlertFile() {
      const result = await this.service.exportAlertResult(this.resultData.id)
      FileDownload(result.data, result.filename)
    }
  }
}
</script>

<style lang="scss" scoped>
.upper-case {
  text-transform: uppercase;
}

.result-area {
  height: 350px;
  padding-bottom: 20rem;
  .success {
    padding-top: (10/1.4) + em;
    color: #89cd4d;
    background: transparent !important;
  }

  .errors {
    padding-top: (10/1.4) + em;
    color: #d2354f;
  }
  .alerts {
    color: #ffa500;
  }
  .result-box {
    text-align: center;

    .icon {
      font: normal normal normal 62px/46px Allen;
    }
    .desc {
      margin-top: 15px;
      font: normal normal bold 26px/36px Aller;
    }
    .download {
      padding-top: 0.1em;
      font: normal normal bold 14px/63px Aller;
      color: #fff;
      cursor: pointer;
    }
    .download:hover {
      text-decoration: underline;
    }
  }
}
</style>
