export default {
  application: {
    enums: {
      notificationEvent: {
        stuffingPortal: 'Portal de Estufagem',
        takeUp: 'Take-Up',
        takeUpProducerMonitoring: 'Portal Take-Up - Acompanhamento do Produtor',
        takeUpLotReportInput: 'Portal Take-Up - Upload Take-Up',
        takeUpSchedules: 'Portal Take-Up - Agendamentos',
        takeUpPremiumDiscountManagement: 'Portal Take-Up - Prêmio/Desconto',
        takeUpContractsPosition: 'Portal Take-Up - Posição de Contratos',
        takeUpItasIntegration: 'Portal Take-Up - Integração ITAS',
        ibpMonthlyReview: 'Plano Total'
      },
      controlTowerNotificationEvent: {
        stuffingPortal: 'Portal de Estufagem',
        takeUp: 'Take-Up',
        takeUpProducerMonitoring: 'Portal Take-Up - Acompanhamento do Produtor',
        takeUpLotReportInput: 'Portal Take-Up - Upload Take-Up',
        takeUpSchedules: 'Portal Take-Up - Agendamentos',
        takeUpPremiumDiscountManagement: 'Portal Take-Up - Prêmio/Desconto',
        takeUpContractsPosition: 'Portal Take-Up - Posição de Contratos',
        takeUpItasIntegration: 'Portal Take-Up - Integração ITAS',
        ibpMonthlyReview: 'Plano Total'
      },
      cockpitRecomendation: {
        replanCollect: 'Replanejar coleta',
        replanStuffing: 'Replanejar estufagem',
        replanBoarding: 'Replanejar embarque',
        instructionSplit: 'Realizar split da instrução',
        evaluateResponsibleInstruction: 'Avaliar com responsável pela instrução'
      },
      modalType: {
        road: 'Rodoviário',
        rail: 'Ferroviário',
        waterway: 'Hidroviário'
      },
      logisticPortalStatusSemaphoreType: {
        red: 'Pendente - Atrasado',
        green: 'Realizado',
        greenOnTime: 'Realizado - No prazo',
        greenLate: 'Realizado - Com Atraso',
        greenFob: 'Realizado - FOB',
        greyFob: 'Pendente - FOB',
        orange: 'Pendente - Em Risco',
        grey: 'Pendente - No Prazo',
        greyUndefined: '-'
      },
      stuffingPortalStatusSemaphoreBulkCompleteType: {
        red: 'Pendente',
        green: 'Complete'
      },
      stuffingPortalStatusSemaphoreFreeGroupsCompleteType: {
        red: 'Pendente',
        green: 'Complete'
      },
      stuffingPortalStatusSemaphoreFobControlInstructionType: {
        red: 'Avaliar LSD CAK / Inpeção do MAPA',
        green: 'Concluída',
        orange: 'Retirada de containers não iniciada',
        grey: '-',
        yellow: 'Carregamento pendente'
      },
      stuffingPortalUnloadingType: {
        unloadingfinished: 'Concluído',
        unloadingnotstarted: 'Não Iniciado',
        unloadingstarted: 'Iniciado'
      },
      stuffingPortalLoadingType: {
        loadingfinished: 'Concluído',
        loadingnotstarted: 'Não Iniciado',
        loadingstarted: 'Iniciado'
      },
      unloadStatusType: {
        waitingunloading: 'Aguardando descarga',
        unloaded: 'Descarregado',
        stuffed: 'Estufado',
        waitingemptywithdrawal: 'Aguardando liberação de vazios'
      },
      logisticPortalVehicleLayoverStatus: {
        invalid: '-',
        notApplicable: 'Não Aplicável',
        charging: 'Carregamento',
        unloading: 'Descarga',
        chargingAndUnloading: 'Carregamento e Descarga'
      },
      logisticPortalExportControlStatus: {
        pendingOnTime: 'Pendente - No Prazo',
        pendingRisk: 'Pendente - Risco',
        pendingOverdue: 'Pendente - Atrasado',
        doneOnTime: 'Realizado - No Prazo',
        doneWithDelay: 'Realizado - Com Atraso'
      },
      logisticPortalExportControlAnalysis: {
        check: 'Verificar',
        ok: 'OK',
        fob: 'FOB'
      },
      logisticPortalExportControlCak: {
        fob: 'FOB',
        casa: 'CASA'
      },
      logisticPortalExportControlFreeTimeStatus: {
        undefined: '-',
        freeTimeExpired: 'Free Time Expirado',
        freeTimeExpiring: 'Free Time a Expirar'
      },
      logisticPortalExportControlFreeGroupsStatus: {
        pending: 'Pendente',
        ok: 'OK'
      },
      stuffingPortalDocumentationType: {
        documentationwaitingstuffing: 'Aguardando estufagem',
        documentationimportpermitpending:
          'Estufagem Iniciada - Import Permit Pendente',
        documentationwaitingmapinspection:
          'Estufagem Iniciada - Inspeção MAPA pendente',
        documentationwaitingstuffingfinalization:
          'Aguardando finalização da estufagem',
        documentationstuffingfinishedimportpermitpending:
          'Estufagem Finalizada - Import Permit Pendente',
        documentationstuffingfinishedwaitingmapinspection:
          'Estufagem Finalizada - Inspeção MAPA pendente',
        documentatonwaitingdraftsubmission: 'Aguardando submissão do Draft',
        documentationwaitingclearencedue: 'Aguardando desembaraço da DUE',
        documentationwaitingdeposit: 'Aguardando depósito',
        documentationwaitingloading: 'Aguardando embarque',
        documentationloaded: 'Embarcado'
      },
      stuffingPortalContainerWithdrawalType: {
        containerwithdrawalfinished: 'Concluído',
        containerwithdrawalnotstarted: 'Não Iniciado',
        containerwithdrawalstarted: 'Iniciado'
      },
      stuffingPortalEmptyWithdrawalProblem: {
        absenceempty: 'Falta de vazios',
        bookingcanceled: 'Booking cancelado',
        absenceregistrationcarrier: 'Falta de cadastro no armador',
        awaitingwithdrawal: 'Aguardando retirada',
        others: 'Outros'
      },
      stuffingPortalReserveType: {
        new: 'Novo',
        changed: 'Modificado',
        containerwithdrawalfinished: 'Retirada de Container - Concluída',
        containerwithdrawalnotstarted: 'Retirada do Container - Não iniciada',
        containerwithdrawalstarted: 'Retirada de Container - Iniciada',
        unloadingfinished: 'Descarga - Concluída',
        unloadingnotstarted: 'Descarga - Não iniciada',
        unloadingstarted: 'Descarga - Iniciada',
        loadingfinished: 'Embarque - Concluído',
        loadingnotstarted: 'Embarque - Não Iniciado',
        loadingstarted: 'Embarque - Iniciado',
        documentationwaitingstuffing: 'Documentação - Aguardando estufagem',
        documentationimportpermitpending:
          'Documentação - Estufagem Iniciada - Import Permit Pendente',
        documentationwaitingmapinspection:
          'Documentação - Estufagem Iniciada - Inspeção MAPA pendente',
        documentationwaitingstuffingfinalization:
          'Documentação - Aguardando finalização da estufagem',
        documentationstuffingfinishedimportpermitpending:
          'Documentação - Estufagem Finalizada - Import Permit Pendente',
        documentationstuffingfinishedwaitingmapinspection:
          'Documentação - Estufagem Finalizada - Inspeção MAPA pendente',
        documentatonwaitingdraftsubmission:
          'Documentação - Aguardando submissão de Draft',
        documentationwaitingclearencedue:
          'Documentação - Aguardando liberação DUE',
        documentationwaitingloading: 'Documentação - Aguardando embarque',
        documentationwaitingdeposit: 'Documentação - Aguardando depósito',
        documentationloaded: 'Documentação - Embarcada'
      },
      stuffingPortalChargingType: {
        chargingfinished: 'Concluído',
        chargingnotstarted: 'Não iniciado',
        chargingstarted: 'Iniciado'
      },
      stuffingPortalImportPermitType: {
        importpermitok: 'OK',
        importpermitpending: 'Pendente'
      },
      stuffingPortalFreightType: {
        freightok: 'OK',
        freightpending: 'Pendente'
      },
      stuffingPortalOpenTextType: {
        opentextok: 'OK',
        opentextpending: 'Pendente'
      },
      stuffingPortalStuffingType: {
        stuffingnotstarted: 'Não iniciado',
        stuffingpartial: 'Parcial',
        stuffingfinished: 'Completo'
      },
      stuffingPortalFobControlType: {
        fobcontrolwaitingcharging: 'Aguardando carregamento',
        fobcontrolwaitingcontainerwithdrawal:
          'Aguardando retirada de containers',
        fobcontrolcontainerwithdrawalproblem:
          'Problema de retirada de containers',
        fobcontrolwaitingstuffing: 'Aguardando estufagem',
        fobcontrolwaitingweightworksheet: 'Aguardando planilha de peso',
        fobcontrolwaitingmapinspection: 'Aguardando inspeção do MAPA',
        fobcontrolnwaitingdraftsubmission: 'Aguardando submissão do Draft',
        fobcontrolwaitingdeposit: 'Aguardando depósito',
        fobcontrolwaitingloading: 'Aguardando embarque',
        fobcontrolloaded: 'Embarcado'
      },
      stuffingPortalDocumentationStatus: {
        red: 'Pendente',
        green: 'OK',
        grey: '-'
      },
      stuffingPortalStatusSemaphoreType: {
        red: 'Pendente',
        green: 'OK',
        grey: '-'
      },
      takeUpGreatnessType: {
        quality: 'Qualidade',
        contamination: 'Contaminante',
        qualityAggregate: 'Qualidade - Agregado'
      },
      comparisonTest: {
        gt: 'Maior que',
        lt: 'Menor que',
        eq: 'Igual a'
      },
      evaluationCriteria: {
        min: 'Mínimo',
        med: 'Média'
      },
      incoterm: {
        cfr: 'CFR',
        cif: 'CIF',
        cip: 'CIP',
        casa: 'CASA',
        exw: 'EXW',
        fob: 'FOB'
      },
      company: {
        br: 'BR',
        rg: 'RG'
      },
      takeUpLotStatus: {
        disapproved: 'REPROVADO',
        approved: 'APROVADO',
        inNegociation: 'EM NEGOCIAÇÃO'
      },
      brazilianStates: {
        ac: 'AC',
        al: 'AL',
        ap: 'AP',
        am: 'AM',
        ba: 'BA',
        ce: 'CE',
        df: 'DF',
        es: 'ES',
        go: 'GO',
        ma: 'MA',
        mt: 'MT',
        ms: 'MS',
        mg: 'MG',
        pa: 'PA',
        pb: 'PB',
        pr: 'PR',
        pe: 'PE',
        pi: 'PI',
        rj: 'RJ',
        rn: 'RN',
        rs: 'RS',
        ro: 'RO',
        rr: 'RR',
        sc: 'SC',
        sp: 'SP',
        se: 'SE',
        to: 'TO'
      },
      contaminationGreatness: {
        bark: 'BARK',
        grass: 'GRASS',
        scnSeed: 'SCN/SEED',
        fumaginaHon: 'FUMAGINA/HON',
        bidens: 'BIDENS'
      },
      gradeRating: {
        better: 'Better',
        low: 'Low'
      },
      offerStatus: {
        approved: 'Aprovado',
        disapproved: 'Reprovado',
        awaitingEvaluation: 'Aguardando Avaliação'
      },
      bookStatus: {
        notAvailable: 'Não Disponibilizado',
        available: 'Disponibilizado'
      },
      takeUpContractMarketplace: {
        domestic: 'Doméstico',
        export: 'Exportação'
      },
      takeUpPeriod: {
        morning: 'Manhã',
        afternoon: 'Tarde',
        night: 'Noite'
      },
      takeUpProducerMonitoringStatus: {
        notStarted: 'Não iniciado',
        reaping: 'Colhendo',
        benefiting: 'Beneficiando',
        waitingForHvi: 'Aguardando HVI',
        partialHvi: 'HVI Parcial',
        hviCompleted: 'HVI Concluído',
        takeUpAvailable: 'Take up disponível',
        notApplicable: 'Não aplicável '
      },
      takeUpCalculationRuleType: {
        default: 'Padrão',
        smaller: 'Menor'
      },
      takeUpScheduleLotStatus: {
        notStarted: 'Não Iniciado',
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        awaitingCargillReview: 'Aguardando Avaliação Cargill',
        hviStandby: 'HVI - STANDBY',
        takeUpStandby: 'TAKE UP - STANDBY',
        awaitingProducerResponse: 'Aguardando Resposta Produtor',
        committee: 'Comitê',
        cargillDecisionPdWithoutHvi: 'Decisão Cargill P/D sem HVI',
        producerAgreementWithoutHvi: 'Acordo Produtor sem HVI',
        cargillDecisionPdWithoutTakeUp: 'Decisão Cargill P/D sem Take Up',
        producerAgreementWithoutTakeUp: 'Acordo Produtor sem Take Up',
        takeUpProducerAgreementWithoutCargillDecision:
          'Acordo Produtor Take Up sem Decisão Cargill',
        hviProducerAgreementWithoutCargillDecision:
          'Acordo Produtor HVI sem Decisão Cargill',
        check: 'Verificar'
      },
      takeUpLotDivergenceStatus: {
        ok: 'OK',
        divergent: 'Divergente'
      },
      takeUpNegotiationStatus: {
        approved: 'Aprovado',
        disapproved: 'Reprovado',
        inNegociation: 'Em Negociação'
      },
      takeUpStatusHvi: {
        ok: 'OK',
        pending: 'Pendente'
      },
      takeUpUsBucketContaminationOperator: {
        equal: 'Igual',
        notEqual: 'Diferente'
      },
      takeUpUsBucketGradeOperator: {
        equal: 'Igual',
        notEqual: 'Diferente'
      },
      takeUpUsBucketCalculatorTriggerType: {
        manual: 'Manual',
        uploadTakeUp: 'Upload Take-Up'
      },
      takeUpAccomplishedStatus: {
        accomplished: 'Realizado',
        pending: 'Pendente'
      },
      takeUpHviTemplate: {
        brain: 'Template Brain',
        cargill: 'Template Cargill',
        abapa: 'Template ABAPA',
        bf: 'Template BF',
        bj: 'Template BJ',
        scheffer: 'Template SCHEFFER',
        amaggi: 'Template AMAGGI'
      },
      takeUpMarketType: {
        exp: 'EXP',
        domb: 'DOMB',
        tbd: 'A SER DEFINIDO'
      },
      takeUpPremiumDiscountAgreement: {
        yes: 'Sim',
        no: 'Não',
        underProducerReview: 'Sob Análise Produtor'
      },
      takeUpPremiumDiscountCargillDecision: {
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        underCargillReview: 'Sob Análise Cargill'
      },
      takeUpPremiumDiscountDecision: {
        approved: 'Aprovado',
        rejected: 'Rejeitado',
        underReview: 'Sob Análise'
      },
      takeUpContractsPositionType: {
        recap: 'RECAP',
        standard: 'PADRÃO'
      },
      takeUpContractsPositionMtm: {
        receiveTheMajor: 'Receber a Maior',
        receiveTheMinor: 'Receber a Menor'
      },
      ibpMonthlyReviewDashboardView: {
        default: 'Consolidado',
        usBucket: 'US Bucket',
        subRegional: 'Sub-Regional'
      },
      ibpMonthlyReviewDashboardSeries: {
        executed: 'Executado',
        planned: 'Planejado',
        unrestricted: 'Irrestrita',
        sales: 'Vendas',
        origination: 'Originação',
        takeUpImport: 'Take-Up Import',
        purchase: 'Compras',
        gamePlan: 'Game Plan',
        purchased: 'Comprado',
        imported: 'Importado',
        invoiced: 'Faturado',
        exported: 'Exportado',
        programmed: 'Programado',
        originationGamePlan: 'GP Originação',
        originalGamePlan: 'GP Original',
        attendance: 'Atendimento',
        attendedGamePlan: 'GP Atendido',
        sold: 'Vendido'
      },
      takeUpPremiumDiscountApplicationStatus: {
        pending: 'Pendente',
        ok: 'OK',
        divergent: 'Divergente'
      },
      takeUpUsBucketIntrinsicComparationRule: {
        interval: 'Intervalo',
        greaterEqual: 'Maior ou igual',
        lessEqual: 'Menor ou igual'
      },
      takeUpImportsPreparationImportInItas: {
        yes: 'Sim',
        no: 'Não',
        divergent: 'Divergente',
        invalid: 'Inválido'
      },
      takeUpClaimsType: {
        return: 'Devolução',
        insuranceClaim: 'Sinistro',
        others: 'Outros'
      },
      takeUpStatusReturnsAndClaims: {
        automatic: 'Automatico',
        pending: 'Pendente',
        approved: 'Aprovado',
        refused: 'Recusado'
      }
    },
    misc: {
      details: 'Detalhes',
      filter: 'Filtro',
      selectTemplate: 'Selecionar Template',
      exportTemplate: 'Exportar Template',
      applyToAll: 'Aplicar a Todos',
      notPublished:
        'Não há cenário publicado, nem existe publicação em andamento.',
      publishing:
        'Há uma publicação de cenário em andamento. Uma notificação será enviada quando a publicação for concluída.',
      alertMessage:
        'Você tem alguns Alertas a ser observada, Deseja continuar a Alteração mesmo assim? {0}'
    }
  },
  controlTower: {
    pages: {
      notificationMessages: {
        teste: 'Esse é um teste com dois args: {0} e {1}',
        emptyWithdrawalProblemMessage:
          '{0} ({1}): Problema de retirada de containers',
        draftDeadlineNotRealizedMessage:
          'Analista {0} ({1}): Draft não submetido',
        instructionNotLoadedMessage:
          'Analista {0} ({1}): Instrução não embarcada',
        loadingDeadLineIsCommingMessage:
          'Analista {0} ({1}): Agendamento do Terminal atrasado',
        loadingNotStartedMessage:
          'Analista {0} ({1}): Carregamento não iniciado',
        loadingStartedButNoContainerWithdrawalMessage:
          'Analista {0} ({1}): Carregamento iniciado e nenhum container retirado',
        newCommentCargillMessage:
          'Analista {0} ({1}): Cargill deixou um comentário',
        newCommentTerminalMessage:
          'Analista {0} ({1}): Terminal {2} deixou um comentário',
        newCommentTakeUpCargillMessage:
          'Analista {0} ({1}): Cargill {2} deixou um comentário',
        newCommentTakeUpClassifierMessage:
          'Analista {0} ({1}): Classificador {2} deixou um comentário',
        unloadingNotStartedMessage: 'Analista {0} ({1}): Descarga não iniciada',
        newNotificationTakeUpSchedulesMessage:
          'O Take-Up do lote {0} do produtor {1} do contrato {2} foi definido como Não Aprovado.',
        takeUpPremiumDiscountManagementLotContractChange:
          'Safra/Lote {0} foi alterado do contrato {1} para o contrato {2}.',
        takeUpPremiumDiscountManagementHviDisapprovedByCargill:
          'O HVI do lote {0} do contrato {1} foi reprovado pela Cargill.',
        takeUpPremiumDiscountManagementTakeUpDisapprovedByCargill:
          'O Take Up do lote {0} do contrato {1} foi reprovado pela Cargill.',
        ibpMonthlyReviewScenarioOfficializationError:
          'Não foi possível publicar os dados do cenário oficial "{0}" no Plano Total. Entre em contato com o administrador do sistema.',
        ibpMonthlyReviewScenarioOfficializationSuccessfullyCanceled:
          'O cancelamento da oficialização do cenário "{0}" foi executado com sucesso no Plano Total.',
        ibpMonthlyReviewItasIntegrationError:
          'A oficialização do cenário "{0}" foi executada com sucesso! Mas, não foi possível integrar os dados ITAS. Por favor, execute o fluxo da integração "ITAS - IBP Monthly Review" de forma manual pela tela de Integração do Control Tower',
        ibpMonthlyReviewScenarioOfficializationSuccessfully:
          'A oficialização do cenário "{0}" e a integração dos dados ITAS foram executados com sucesso no Plano Total.',
        application: {
          pages: {
            takeUpPremiumDiscountCalculator: {
              errors: {
                noReferenceGradeDefinition:
                  'Não foi identificado o TCG para calcular o Prêmio/Desconto Tipo,  do lote {0} do contrato {1}.',
                thereIsNoQualityTranche:
                  'Não foram identificadas tranches de qualidade do contrato {0} para calcular o Prêmio/Desconto Tipo do lote {1}.',
                thereIsNoQualityTrancheForGrade:
                  'Não existe tranche de qualidade para o TCG {0} no contrato {1} do lote {2} para calcular o Prêmio/Desconto do HVI/Tipo.',
                thereIsNoQualityTrancheForIntrinsicCharacteristics:
                  'O HVI do lote {0} não respeita as regras da tranche de qualidade do contrato {1} para o TCG {2} para calcular o Prêmio/Desconto do HVI/Tipo.',
                noReferenceTableContamination:
                  'Não foi definida a tabela de referência de contaminação do lote {0} do contrato {1}.',
                unableCalculateContaminationPremiumDiscountBark:
                  'Não foi possível calcular o Prêmio/Desconto de contaminação relacionado ao Bark do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateContaminationPremiumDiscountBidens:
                  'Não foi possível calcular o Prêmio/Desconto de contaminação relacionado ao Bidens do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateContaminationPremiumDiscountFumagHone:
                  'Não foi possível calcular o Prêmio/Desconto de contaminação relacionado ao Fumag/Hone do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateContaminationPremiumDiscountGrass:
                  'Não foi possível calcular o Prêmio/Desconto de contaminação relacionado ao Grass do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateContaminationPremiumDiscountScnSeed:
                  'Não foi possível calcular o Prêmio/Desconto de contaminação relacionado ao SCN/SEED do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateIntrinsicPremiumDiscountLength:
                  'Não foi possível calcular o Prêmio/Desconto de HVI relacionado ao UHML [mm] do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateIntrinsicPremiumDiscountStrength:
                  'Não foi possível calcular o Prêmio/Desconto de HVI relacionado ao GPT [G/tex] do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateIntrinsicPremiumDiscountMic:
                  'Não foi possível calcular o Prêmio/Desconto de HVI relacionado ao Mic do lote {0} do contrato {1}, na tabela de referência {2}.',
                unableCalculateIntrinsicPremiumDiscountSfi:
                  'Não foi possível calcular o Prêmio/Desconto de HVI relacionado ao SFI do lote {0} do contrato {1}, na tabela de referência {2}.',
                thereIsNoRuleForGradeInReferenceTable:
                  'Não existe regra para o tipo {0} no contrato {1} do lote {2} para calcular o Prêmio/Desconto do Tipo, na tabela de referência {3}.',
                contractualOptionNotRegistered:
                  'Opção contratual não cadastrada para o tipo {0} no contrato {1} do lote {2} para calcular o Prêmio/Desconto do Tipo.',
                noHviInputReferenceTable:
                  'Não subiu HVI no lote {0} do contrato {1}. Não é possível calcular Prêmio/Desconto HVI com tabela referência.',
                noHviInputContractBase:
                  'Não subiu HVI no lote {0} do contrato {1}. Não é possível calcular Prêmio/Desconto HVI base contrato.',
                noTakeUpInputContamination:
                  'Não subiu Take-Up no lote {0} do contrato {1}. Não é possível calcular Prêmio/Desconto Contaminação.',
                noTakeUpInputGrade:
                  'Não subiu Take-Up no lote {0} do contrato {1}. Não é possível calcular Prêmio/Desconto Tipo.',
                noHeaderContract:
                  'O header do contrato {0} não foi encontrado.',
                noOfferReferenceGrade:
                  'Não foi cadastrado Tipo para o lote {0} da oferta {1} e não tem Take-Up.  Não é possível calcular Prêmio/Desconto do HVI/Tipo.',
                offerWithoutReferenceContractOrReferenceTable:
                  'Lote {0} da oferta {1} sem definição de contrato referência/tabela referência. Não é possível calcular Prêmio/Desconto do HVI/Tipo.',
                thereIsFinalDecisionForPd:
                  'Não foi possível calcular o Prêmio/Desconto do lote {0} do contrato {1}. Existe decisão final para o P/D HVI e Take-Up.',
                thereIsFinalDecisionForPdHvi:
                  'Não foi possível calcular o Prêmio/Desconto de HVI do lote {0} do contrato {1}. Existe decisão final para o P/D HVI.',
                thereIsFinalDecisionForPdTakeUp:
                  'Não foi possível calcular o Prêmio/Desconto de Tipo/Contaminação do lote {0} do contrato {1}. Existe decisão final para o P/D Take-Up.',
                invalidbasetypecontract:
                  'Contrato de Compra com tipo base (tranche) não cadastrada. Contrato: {0}. Tipo: {1}.'
              }
            },
            takeUpContractsPositionCalculator: {
              errors: {
                headerContractWasNotCreated:
                  'O contrato header não foi criado pela integração de contratos! Contratos com esse problema: {0}.',
                thereIsNoUnitConversion:
                  'Não existe conversão de unidades entre a unidade {0} ({1}) e {2} ({3}).'
              }
            },
            takeUpPurchaseContractItas: {
              errors: {
                producerChangeError:
                  'O produtor do contrato {0} foi modificado no ITAS. Dados do Take-Up desse contrato estão INVÁLIDOS. Antigo produtor: {1}. Produtor atual: {2}.'
              }
            }
          }
        }
      },
      notificationUser: {
        title: 'Notificações'
      },
      controlTowerNotification: {
        title: 'Notificações',
        message: 'Mensagem',
        notificationDate: 'Data de Notificação',
        notificationEvent: 'Evento',
        notificationModule: 'Módulo',
        notificationType: 'Tipo',
        readDate: 'Lida em',
        isRead: 'Lida'
      },
      ibpMonthlyReviewExecutedExport: {
        title: 'Plano Total - Exportação Executado'
      },
      ibpMonthlyReviewExecutedDomesticMarket: {
        title: 'Plano Total - Mercado Doméstico Executado'
      },
      ibpMonthlyReviewExecutedOrigination: {
        title: 'Plano Total - Originação Executado'
      },
      ibpMonthlyReviewExecutedCarrierUtilization: {
        title: 'Plano Total - Utilização do Armador Executado'
      },
      stuffingPortalInstruction: {
        title: 'Instrução'
      },
      stuffingPortalCompaniesUsers: {
        title: 'Usuários'
      },
      stuffingPortalContainersWithdrawal: {
        title: 'Retirada dos containers'
      },
      stuffingPortalContainersWithdrawalContainer: {
        title: 'Retirada de Container'
      },
      stuffingPortalDocumentation: {
        title: 'Documentação'
      },
      stuffingPortalDocumentationRequest: {
        title: 'Solicitação de Documentação'
      },
      stuffingPortalDocumentationBatch: {
        title: 'Lote'
      },
      stuffingPortalLoading: {
        title: 'Embarque'
      },
      stuffingPortalLoadingContainer: {
        title: 'Embarque do Container'
      },
      stuffingPortalReserve: {
        title: 'Reserva'
      },
      stuffingPortalReserveRequest: {
        title: 'Solicitação de Reserva'
      },
      stuffingPortalUnloading: {
        title: 'Descarga'
      },
      stuffingPortalUnloadingBatch: {
        title: 'Lote'
      },
      stuffingPortalUnloadingDriver: {
        title: 'Motorista'
      },
      logisticPortalExportControl: {
        title: 'Controle CASA + FOB'
      },
      logisticPortalExportControlInventory: {
        title: 'Controle CASA + FOB - Inventory'
      },
      inventoryItas: {
        title: 'ITAS - Estoque'
      },
      takeUpInventoryItas: {
        title: 'Take-Up - ITAS - Estoque'
      },
      purchasesAndSalesItas: {
        title: 'ITAS - Contrato (Compra/Venda)'
      },
      bookingItas: {
        title: 'ITAS - Booking'
      },
      openContractItas: {
        title: 'ITAS - Open Contract'
      },
      stuffingPortalCompanies: {
        title: 'Empresas',
        code: 'Código',
        name: 'Nome',
        fullAccess: 'Acesso Completo',
        stuffingPortalCompaniesUsers: {
          title: 'Usuários',
          user: 'Usuário',
          company: 'Empresa'
        }
      },
      shipmentItasDto: {
        title: 'Shipment - ITAS'
      },
      logisticPortal: {
        title: 'Portal Logístico'
      },
      stuffingPortal: {
        title: 'Exportação (Porto)',
        export: 'Exportar',
        transferContainers: 'Transferir Containers',
        transferOfContainers: 'Trânsferência de Containers',
        import: 'Importar Dados',
        viewData: 'Visualizar Dados',
        reserve: 'Reserva',
        containersWithdrawal: 'Retirada dos containers',
        unload: 'Descarga',
        boarding: 'Embarque',
        documentation: 'Documentação',
        period: 'Período',
        comments: 'Comentários',
        leaveComment: 'Deixe uma mensagem para enviar',
        deleteSuccess: 'Deleção realizada com Sucesso',
        deleteError: 'Deleção falhou',
        commentSendSuccess: 'Comentário Enviado com Sucesso',
        commentSendError: 'Erro ao enviar comentário',
        emptyCommentError: 'Erro: Comentário Vazio',
        control: 'Controle CASA + FOB',
        errors: {
          dateNotGreatherOrEqualToday: 'Data deve ser maior ou igual a hoje',
          dateGreatherAndNotEqualToday: 'Data deve ser menor ou igual a hoje'
        },
        reserveTable: {
          title: 'Reserva',
          stuffingPortalReserve: {
            update: 'Atualizar Reserva',
            registryDate: 'Data do Registro',
            instruction: 'Instrução',
            registryStatus: 'Status registro',
            stuffingTerminal: 'Terminal de Estufagem',
            draftDeadline: 'DDL do Draft',
            loadDeadline: 'DDL de carga',
            containersQuantity: 'Qtd. Containers',
            startUnloadWindow: 'Início da Janela de Descarga',
            endUnloadWindow: 'Fim da Janela de Descarga',
            cadency: 'Cadência',
            terminalAproval: 'Aprovação terminal',
            awareChanges: 'Ciente de alterações',
            originColectDate: 'Data coleta origem',
            actions: 'Ações',
            lastStatusUpdateDate: 'Data da última atualização',
            incoterm: 'Incoterm',
            partialAllowed: 'Partial Allowed',
            cropYear: 'Safra',
            statusItas: 'Status ITAS',
            incotermDetail: 'Incoterm Detalhe',
            balesQuantity: 'Quantidade Fardos',
            cakLsd: 'CAK LSD',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK',
            destinationPort: 'Porto de Destino',
            freightForwarder: 'Freight FRWD',
            netWeight: 'Peso Líquido (KG)',
            logisticResponsible: 'Resp Crgl Log:',
            weightController: 'Controladora de peso',
            fumigation: 'Fumigadora',
            forwardingAgent: 'Despachante',
            booking: 'Booking',
            ets: 'Ets',
            carrier: 'Armador',
            travel: 'Viagem',
            ship: 'Navio',
            destinationCountry: 'País Destino'
          }
        },
        containersWithdrawalTable: {
          title: 'Retirada dos containers',
          errors: {
            invalidWithdrawalDate:
              'InvalidWithdrawalDate deve ser menor ou igual a data de hoje',
            invalidTare: 'InvalidTare deve ser maior que zero'
          },
          stuffingPortalContainersWithdrawal: {
            update: 'Atualizar Container',
            containerWithdrawal: 'Adicionar container',
            registryDate: 'Data do Registro',
            instruction: 'Instrução',
            containerWithdrawalStatus: 'Status da retirada dos containers',
            emptyWithdrawalProblem: 'Problema de retirada de vazios',
            actions: 'Ações',
            stuffingTerminal: 'Terminal de Estufagem',
            lastStatusUpdateDate: 'Data da última atualização',
            incotermDetail: 'Incoterm Detalhe',
            cropYear: 'Safra',
            statusItas: 'Status ITAS',
            containersQuantity: 'Cntrs Solicitados',
            containersRemovedQuantity: 'Cntrs Retirados',
            booking: 'Booking',
            carrier: 'Armador',
            logisticResponsible: 'Resp Crgl Log:'
          },
          stuffingPortalContainersWithdrawalContainer: {
            addwithdrawalcontainer: 'Adicionar Container de Retirada',
            update: 'Atualizar Container de Retirada',
            containerCode: 'Número do Container',
            tare: 'Tara (KG)',
            seal: 'Lacre',
            withdrawalDate: 'Data de Retirada',
            actions: 'Ações',
            oppositeMargin: 'Mesma Margem?'
          }
        },
        unloadTable: {
          title: 'Descarga',
          errors: {
            invalidStartDate: 'StartDate deve ser maior que a data de hoje',
            invalidEndDate: 'InvalidEndDate deve ser maior que a data de hoje',
            invalidRequestedDate:
              'InvalidRequestedDate deve ser maior que a data de hoje',
            invalidScheduledDate:
              'InvalidScheduledDate deve ser maior que a data de hoje',
            invalidVehiclePresentingDate:
              'InvalidVehiclePresentingDate deve ser maior que a data de hoje',
            invalidUnloadDate:
              'InvalidUnloadDate deve ser maior que a data de hoje',
            invalidExpeditionDate:
              'InvalidExpeditionDate deve ser menor ou igual a data de hoje'
          },
          stuffingPortalUnloading: {
            adddriver: 'Adicionar Motorista',
            registryDate: 'Data do Registro',
            update: 'Atualizar Descarga',
            instruction: 'Instrução',
            stuffingTerminal: 'Terminal de Estufagem',
            deadlineDraft: 'DDL de Draft',
            instructionStatus: 'Status da Descarga',
            actions: 'Ações',
            lastStatusUpdateDate: 'Data da última atualização',
            cropYear: 'Safra',
            statusItas: 'Status ITAS',
            booking: 'Booking',
            logisticResponsible: 'Resp Crgl Log:',
            loadDeadline: 'DDL de carga'
          },
          stuffingPortalUnloadingDriver: {
            adddriver: 'Adicionar Motorista',
            addbatch: 'Adicionar Lote',
            update: 'Atualizar Motorista',
            breakdown: 'Avaria',
            sinister: 'Sinistro',
            startDate: 'Data Mínima Esperada da Descarga',
            endDate: 'Data Máxima Esperada da Descarga',
            requestedDate: 'Data Solicitada',
            scheduledDate: 'Data Agendada',
            vehiclePresentingDate: 'Data Apresentação do Veículo',
            unloadDate: 'Data Descarga',
            billingTicket: 'Ticket de Faturamento',
            expeditionDate: 'Emissão',
            firstWeight: '1a Pesagem (KG)',
            secondWeight: '2a Pesagem (KG)',
            netWeight: 'Peso Líquido (KG)',
            actions: 'Ações',
            shippingCompany: 'Transportadora',
            horsePlate: 'Placa do Veículo',
            originLoadingDate: 'Data Carreg. Origem',
            groupNumber: 'Agrupamento',
            planId: 'PlanId',
            purchaseContractReference: 'Contrato de Compra',
            productFiscalNumber: 'Nota Fiscal do Produtor',
            remittanceFiscalNumber: 'Número Fiscal de Remessa',
            remittanceFiscalNumberState: 'Estado do número fiscal da remessa',
            batch: 'Lote',
            originBalesQuantityUnloaded: 'Qtde de fardos carregados na origem',
            balesQuantityUnloaded: 'Qtde de fardos descarregados',
            balesBalance: 'Saldo fardos',
            bulkCompleteStatus: 'Status Bulk',
            freeGroupsCompleteStatus: 'Status Free Groups',
            expectedEndLoadDate: 'Data Máxima Esperada de Carregamento',
            expectedStartLoadDate: 'Data Mínima Esperada de Carregamento',
            purchaseContractIncoterm: 'Incoterm',
            warehouse: 'Warehouse'
          }
        },
        boardingTable: {
          title: 'Embarque',
          stuffingPortalLoading: {
            update: 'Atualizar Embarque',
            registryDate: 'Data do Registro',
            instruction: 'Instrução',
            draftDeadline: 'DDL do Draft',
            loadDeadline: 'DDL da Carga',
            booking: 'Booking',
            stuffingTerminal: 'Terminal de Estufagem',
            ets: 'ETS',
            dueNumber: 'Número da Due',
            exportNFNumber: 'Número da nota fiscal de Exportação',
            instructionStatus: 'Status da Estufagem',
            loadingStatus: 'Status de depósito',
            actions: 'Ações',
            lastStatusUpdateDate: 'Data da última atualização',
            incotermDetail: 'Incoterm Detalhe',
            cropYear: 'Safra',
            statusItas: 'Status ITAS',
            openText: 'OpenText',
            freight: 'Frete',
            logisticResponsible: 'Resp Crgl Log:',
            ship: 'Navio',
            stuffedContainers: 'Containers Estufados'
          },
          stuffingPortalLoadingContainer: {
            update: 'Atualizar Embarque do Container',
            containerCode: 'Número do Container',
            loadingTerminalSchedule: 'Agendamento do terminal de embarque',
            windowPending: 'Janela Pendente',
            depositDate: 'Data de depósito',
            carrierContractedFreeTime: 'Free Time Contratado do Armador',
            carrierRemainingFreeTime: 'Free Time Restante do Armador',
            stuffingTerminalContractedFreeTime:
              'Free Time Contratado do Terminal de Estufagem',
            stuffingTerminalRemainingFreeTime:
              'Free Time Restante do Terminal de Estufagem',
            actions: 'Ações',
            bales: 'Fardos Estufados',
            stuffingStatus: 'Status de Estufagem',
            stuffingDate: 'Data de Estufagem'
          }
        },
        documentationTable: {
          title: 'Documentação',
          stuffingPortalDocumentation: {
            adddocumentation: 'Adicionar Documentação',
            update: 'Atualizar Documentação',
            registryDate: 'Data do registro',
            instruction: 'Instrução',
            stuffingTerminal: 'Terminal de Estufagem',
            status: 'Status',
            weightSupervisor: 'Supervisora de peso',
            fumigation: 'Fumigadora',
            forwardingAgent: 'Despachante',
            actions: 'Ações',
            container: 'Container',
            realizationMapDate: 'Realização Mapa',
            mapInspectionStatus: 'Status da vistoria do mapa',
            mapFumigationStatus: 'Status da fumigação',
            fumigationDate: 'Data da fumigação',
            draftDeadlineDate: 'DDL de Draft',
            draftDeadlineRealizationDate: 'Data de Cumprimento do Draft',
            vgmDeadlineDate: 'DDL do VGM',
            loadDeadlineDate: 'DDL de Carga',
            vgmDate: 'Data do VGM',
            vgmSubmissionDate: 'Submissão do VGM',
            dueClearanceDate: 'Desembaraço da Due',
            dueNumber: 'Número da due',
            exportationFiscalNumber: 'Número da nota fiscal de Exportação',
            loadingDate: 'Data de Embarque (BL)',
            scrollQuantity: 'Qtde de rolagem',
            scrollingReason: 'Motivo da rolagem',
            scrolling: 'Rolagem',
            checkweigherSealNumber: 'VGM',
            lastStatusUpdateDate: 'Data da última atualização',
            deadlineSubmitted: 'Prazo Enviado',
            incoterm: 'Incoterm',
            approvalWeightWorksheetDate: 'Aprovação Planilha de Peso',
            partialAllowed: 'Partial Allowed',
            cropYear: 'Safra',
            statusItas: 'Status ITAS',
            incotermDetail: 'Incoterm Detalhe',
            importPermit: 'Import Permit',
            logisticResponsible: 'Resp Crgl Log:',
            documentation: 'Resp Crgl Doc:',
            cakLsd: 'CAK LSD',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK'
          },
          stuffingPortalDocumentationRequest: {
            checkweigherSealNumber: 'VGM',
            adddocumentationrequest: 'Adicionar Requisição de Documentação',
            update: 'Atualizar Requisição Documentação',
            container: 'Container',
            seal: 'Lacre',
            batch: 'Lote',
            fiscalNumber: 'Número Fiscal',
            balesQuantity: 'Quantidade de Fardos',
            grossWeight: 'Peso Bruto',
            tareCargo: 'Tara',
            netWeight: 'Peso Líquido',
            average: 'Média',
            m3: 'M3',
            density: 'Densidade',
            actions: 'Ações'
          },
          stuffingPortalDocumentationBatch: {
            adddocumentationbatch: 'Adicionar Lote',
            update: 'Atualizar Lote',
            batch: 'Lote',
            fiscalNumber: 'NF',
            balesQuantity: 'Quantidade de Fardos',
            actions: 'Ações'
          }
        },
        controlTable: {
          title: 'Controle CASA + FOB',
          logisticPortalExportControl: {
            title: 'Controle CASA + FOB',
            registryDate: 'Data do registro',
            cak: 'CAK',
            instruction: 'Instrução',
            cropYear: 'Safra',
            instructionCode: 'Instrução',
            statusItas: 'Status ITAS',
            logisticResponsible: 'Resp Crgl Log:',
            status: 'Status',
            instructionStatus: 'Status da Instrução',
            instructionStatusExcel: 'Status da Instrução',
            documentation: 'Resp Crgl Doc:',
            loadStatus: 'Status do Carregamento',
            unloadStatus: 'Status da Descarga',
            quantity: 'Quantidade (Tons)',
            balesQuantity: 'Quantidade de Fardos Total',
            loadedBalesQuantity: 'Quantidade de Fardos Carregados',
            booking: 'Booking',
            containersQuantity: 'Qtd. Containers',
            ship: 'Navio',
            travel: 'Viagem',
            ets: 'ETS',
            draftDeadline: 'DDL do Draft',
            loadDeadline: 'DDL de carga',
            destinationCountry: 'País Destino',
            destinationPort: 'Porto de Destino',
            carrier: 'Armador',
            freightForwarder: 'Freight FRWD',
            originPort: 'Porto de Origem',
            departureTerminal: 'Terminal de Embarque',
            chargingStatus: 'Carregamento',
            containerWithdrawalStatus: 'Retirada de Container',
            stuffingTerminal: 'Terminal de Estufagem',
            qtyContainerRetreat: 'Qtd Vazios Retirados',
            emptyWithdrawalProblem: 'Problema de Retirada de Container',
            firstDateContainerRetreat: '1ª Data da Retirada Vazios',
            firstStuffingDate: '1ª Data da Estufagem',
            endFreeTimeDetention: 'End (Free Time Detention)',
            statusFreeTimeDetention: 'Status (Free Time Detention)',
            endFreeTimeStorage: 'End (Free Time Armazenagem)',
            statusFreeTimeStorage: 'Status (Free Time Armazenagem)',
            stuffedContainers: 'Containers Estufados',
            mtFinishedStuffing: 'MT Finalizado Estufagem',
            percentageFinishedStuffing: '% Finalizado Estufagem',
            approvalWeightWorksheetDate: 'Aprovação Planilha de Peso',
            updateInquiryOrFreeGroups: 'Atualização Enquiry / Free Groups',
            realizationMapDate: 'Realização Mapa',
            fumigation: 'Fumigação',
            draftDeadlineRealizationDate: 'Data de Cumprimento do Draft',
            due: 'DUE',
            containerDepositDate: 'Depósito Cheios',
            loadingDate: 'Data de Embarque (BL)',
            splitOrRoll: 'Split or Roll',
            reasonForRolling: 'Reason for Rolling',
            delayOrProblem: 'Delay/Problem',
            totalHcUsageDays: 'Total HC Usage (Days)',
            freetimeDetention: 'Freetime Detention',
            detentionAnalysis: 'Análise Detention',
            freetimeTerminalStuffing: 'Freetime Terminal Estufagem',
            terminalStuffingAnalysis: 'Análise Terminal Estufagem',
            freetimeDepartureTerminal: 'Freetime Terminal Embarque',
            departureTerminalAnalysis: 'Análise Terminal Embarque',
            oppositeMargin: 'Margem Oposta',
            cakLsd: 'CAK LSD',
            partialAllowed: 'Partial Allowed',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK',
            supplier: 'Supplier',
            freight: 'Frete',
            openText: 'OpenText',
            lastStatusUpdateDate: 'Data da última atualização',
            lastBookingItasUpdatedDate: 'Last Booking Itas Updated Date',
            lastShipmentItasUpdatedDate: 'Last Shipment Itas Updated Date',
            actions: 'Ações',
            mapScheduleDate: 'Agendamento do Mapa',
            update: 'Atualizar Controle FOB',
            weightSupervisor: 'Supervisora de peso',
            observation: 'Observação'
          }
        }
      },
      cockpitExecution: {
        title: 'Cockpit',
        cleanFilter: 'Limpar',
        exportDetails: 'Exportar relatório completo',
        lastUpdate: 'Última atualização:',
        harvest: 'Safra',
        fiscalYear: 'Ano Fiscal',
        weeklyPlannedVolume: 'Volume planejado para a semana',
        weeklyPlannedInstructions: 'Instruções planejadas para semana',
        roadFreight: 'Frete Rodoviário',
        instructionStatus: 'Status da Instrução',
        loading: 'Carregamento',
        stuffing: 'Estufagem',
        boarding: 'Embarque',
        contractBoogey: 'Contrat./Boogey',
        hired: 'Contratado',
        boogey: 'Boogey',
        lost: 'Perdidos',
        late: 'Atrasados',
        risky: 'Com Risco',
        biInTime: 'Bi no prazo',
        planned: 'Planejado',
        fulfilled: 'Realizado',
        accumulatedLoadingVolume: 'Volume carregado Acumulado',
        accumulatedStuffingVolume: 'Volume estufado Acumulado',
        accumulatedBoardingVolume: 'Volume embarcado Acumulado',
        cargoLoading: 'Carregamento',
        gerencialInformations: 'Informações gerenciais',
        instructionStatusMultiple: 'Status das Instruções',
        currentWeek: 'Semana Atual (S0)',
        loadingTitle: 'Carregamento (Algodoeira)',
        stuffingTitle: 'Estufagem (Terminal de Estufagem)',
        boardingTitle: 'Embarque (Terminal de Embarque)',
        accumulatedFulfilledDemand: 'Demanda Atendida Acumulada',
        warning: 'Alerta',
        error: 'Erro',
        information: 'Aviso',
        externalMarket: 'Mercado Externo (ME)',
        internalMarket: 'Fiação (MI)',
        market: 'Mercado',
        unityMeasure: 'Unidade de Medida',
        zoomLevel:
          'A escala de resolução para esta tela está diferente de 100% e pode resultar na quebra de layout',
        instructionsDetailing: 'Detalhamento de Instruções',
        instructionsLegend: 'Legenda',
        table: {
          cockpitInstructionDetailsMasterMetaData: {
            instructionCode: 'Instrução',
            destinyCountry: 'País Destino',
            modality: 'Modalidade',
            contractVolumeKg: 'Vol. Contrat. (KG)',
            loading: 'Carregamento',
            stuffing: 'Estufagem',
            boarding: 'Embarque',
            deadline: 'Deadline',
            instruction: 'Instrução',
            lateBoarding: 'Atraso Emb.',
            recomendation: 'Recomendação com relação ao atraso da carga',
            incoterm: 'Incoterm',
            instructionVolume: 'Volume Total da Instrução (KG)',
            instructionStatus: 'Status do Carregamento da Instrução',
            stuffingStatus: 'Status de Estufagem da Instrução',
            boardingStatus: 'Status do Embarque',
            deadLineDate: 'Deadline da Carga (ME) / Entrega Cliente (MI)',
            concludedStatus: '% Concluída da Instrução',
            //instruction: 'Instrução',
            loadingStatus: 'Status do Carregamento da Instrução',
            destinyContry: 'País Destino',
            //modality: 'Modalidade',
            contractKgVolume: 'Vol. Contrat.(kg)',
            planID: 'Plan ID',
            planId: 'Plan ID',
            cottonOrigin: 'Algodoeira de Origem',
            shippingCompany: 'Transportadora',
            originCity: 'Cidade Origem',
            destinyCity: 'Cidade Destino',
            contractBalesVolume: 'Volume Contrat.(Fardos)',
            port: 'Porto',
            effectiveLoadingStartDate:
              'Inicio efetivo do Carreg. na Algodoeira (Data Prog.)',
            effectiveUnLoadingEndDate: 'Fim do Carregamento (Data Efetiva)',
            effectiveLoadingProgramedDate:
              'Fim do Carregamento (Data Programada)',
            loadingBalesVolume: 'Volume Carregado (Fardos)',
            balesBalance: 'Saldos (Fardos)',
            terminalPrecisionArrivalDate:
              'Precisão de chegada no terminal ME (Calculada) ou Fiação (MI)',
            vehiclePresentingDate:
              'Data de Apresentação do Veículo no terminal de descarga (ME) ou Fiação (MI)',
            loadingstatus: 'Status do Carregamento',
            effectiveUnloadingStartDate: 'Data Efetiva do Início da Descarga',
            unloadingStartDate: 'Início da Descarga (Data Programada)',
            unloadingEndDate: 'Data de fim da Descarga',
            unloadingContainerDate: 'Data de Retirada dos Containers',
            freeTimeAvailable: 'Free time Disponível',
            // stuffingStatus: 'Status da Estufagem',
            mapInspectionDate: 'Data de Vistoria do Mapa',
            draftDeadline: 'Deadline de Draft',
            vGMSubmissionDate: 'Data de Submissão do VGM',
            dueClearanceDate: 'Data de desembaraço da Due',
            loadingDate: 'Data de Embarque',
            loadDeadline: 'Deadline da carga (ME) entrega cliente (MI)',
            doneInstructionPercentage: '% Concluída da Instrução',
            loadLateRecomendation: 'Recomendação com relação ao atraso da carga'
          },
          instructionDetailsMetaData: {
            company: 'Companhia',
            harvest: 'Safra',
            cropYear: 'Safra',
            responsible: 'Responsável Logística',
            biReceiptDate: 'Data de Recebimento do BI',
            statusItas: 'Status ITAS',
            itas: 'Status ITAS',
            originPort: 'Porto de Origem',
            stuffingTerminal: 'Terminal de Estufagem',
            fumigation: 'Fumigadora',
            forwardingAgent: 'Despachante',
            carrier: 'Armador',
            booking: 'Booking',
            ship: 'Navio',
            weightSupervisor: 'Supervisora de Peso',
            availableCarrierFreeTime:
              'Free Time Disponível Do Armador (Calculado)',
            availableStuffingTerminalFreeTime:
              'Free Time Disponível Do Terminal De Estufagem (Calculado)',
            withdrawalContainersDate:
              'Data Calculada Para Iniciar Retirar Containers',
            instructionContainersQuantity: 'Qtde. De Containers Na Instrução',
            removedContainersQuantity: 'Containers Retirados',
            voidRemovalProblem: 'Problema De Retirada De Vazios',
            stuffedContainersQuantity: 'Qtde. De Containers Estufados',
            calculatedMapInspectionDate: 'Data Da Vistoria Do Mapa (Calculada)',
            effectiveMapInspectionDate: 'Data Efetiva Da Vistoria Do Mapa',
            fumigationDate: 'Data De Fumigação (Calculada)',
            effectiveFumigationDate: 'Data Efetiva De Fumigação',
            calculatedDraftDeadlineDate: 'Deadline De Draft (Calculada)',
            draftDeadlineDate: 'Deadline De Draft',
            fiscalExportNoteNumber: 'No. Nota Fiscal De Exportação',
            calculatedDueClearanceDate:
              'Data De Desembaraço Da Due (Calculada)',
            dueClearanceDate: 'Data De Desembaraço Da Due',
            calculatedSchedulingBoardingTerminalDate:
              'Agendamento Do Terminal De Embarque (Calculado)',
            schedulingBoardingTerminalDate:
              'Agendamento Do Terminal De Embarque',
            schedulingTerminalBoardingStatus:
              'Status Do Agendamento Do Terminal De Embarque',
            calculatedVgmDate: 'Deadline Do Vgm  (Calculada)',
            calculatedDepositDate: 'Data De Depósito (Calculado)',
            depositStatus: 'Status do Depósito',
            effectiveEtsDate: 'Ets',
            calculatedEtsDate: 'Ets (Calculado)',
            boardingDate: 'Data De Embarque',
            scrollingQuantity: 'Qtde De Rolagem',
            scrollingReason: 'Rolagem',
            vgmSubmissionDate: 'Data de Submissão do VGM',
            planId: 'Plan Id',
            farmProducer: 'Algodoeira / Produtor',
            modalType: 'Modal',
            shippingCompany: 'Transporadora',
            destination: 'Destino',
            originCityState: 'Cidade Origem/UF',
            destinationCityState: 'Cidade Destino/UF',
            bogeyTable: 'Tabela Bogey',
            bogeyTableUnity: 'Unidade - Tabela Bogey',
            contractedFreight: 'Frete Contratado',
            contractedFreightUnity: 'Unidade - Frete Contratado',
            originLoadingStartDate:
              'Início Do Carregamento Na Origem (Data Programada)',
            loadingEndDate: 'Fim Do Carregamento',
            effectiveLoadingEndDate: 'Fim Do Carregamento (Data Efetiva)',
            planIdStatus: 'Status Do Carregamento Do Plan Id',
            contractedBalesQuantity: ' Quantidade De Fardos Contratado',
            loadedBalesQuantity: 'Quantidade De Fardos Carregados',
            balesBalance: 'Saldo (Fardos)',
            vehiclePlate: 'Placa Do Veículo',
            expectedDestinationArrivalDate:
              'Previsão De Chegada No Destino (Data Programada)',
            destinationArrivalShippingCompanyDate:
              'Chegada No Destino (Visão Transportadora)',
            destinationArrivalTerminalDate:
              'Chegada No Destino (Visão Terminal / Fiação)',
            unLoadingStartDate: 'Início Da Descarga (Data Programada)',
            effectiveUnLoadingStartDate: 'Data Efetiva Do Início Da Descarga',
            unLoadingEndDate: 'Fim Da Descarga (Data Programada)',
            effectiveUnLoadingEndDate: 'Data Efetiva De Fim Da Descarga',
            statusByUnLoadingByPlanId: 'Status Da Descarga Por Plan Id',
            unloadingTerminal: 'Terminal de Descarga(ME) ou Fiação(MI)'
          },
          planIdVehicleContractedLotVolumePlateDetailsMetaData: {
            contractedLotVolume: 'Volume do Lote (KG)',
            contractedLotReferenceCode: 'Código do Lote Referência Contratado',
            loadedLotCode: 'Código do Lote Carregado',
            salesInvoice: 'Nota Fiscal de Venda (Produtor)',
            cargillShippingInvoice: 'Nota Fiscal de Remessa Cargill (ME)',
            cargillSalesInvoice: 'Nota Fiscal de Venda Cargill (MI)',
            counterNote: 'Contra Nota',
            stuffingDate: 'Data de Estufagem',
            totalStuffingVolume: 'Volume Estufado'
          },
          cellRenderLegend: {
            notStarted: 'Não Iniciado',
            onTime: 'No Prazo',
            riskOfDelay: 'Risco de Atraso',
            late: 'Atrasado'
          }
        }
      },
      totalPlanDashboard: {
        title: 'Plano Total',
        headerTitle: 'Plano Total',
        cropYear: 'Safra',
        viewSelector: {
          title: 'Visão',
          clear: 'Limpar Visão',
          viewType: 'Tipo de Visão',
          series: 'Coluna'
        },
        pptExport: {
          exportConsolidatedTitle: 'Exportação',
          domesticMarketConsolidatedTitle: 'Mercado Doméstico',
          originationConsolidatedTitle: 'Originação',
          bookingCapacity: 'Capacidade de Bookings'
        },
        exportConsolidated: {
          title: 'Exportação'
        },
        domesticMarketConsolidated: {
          title: 'Mercado Doméstico'
        },
        originationConsolidated: {
          title: 'Originação'
        },
        pdConsolidated: {
          title: 'Prêmio e Desconto'
        },
        storageCostConsolidated: {
          title: 'Custo de Armazenagem'
        },
        executionTitle: 'Executado',
        programTitle: 'Programa',
        executionConsolidated: {
          title: 'Execução - Consolidado',
          exportConsolidatedTitle: 'Exportação',
          domesticMarketConsolidatedTitle: 'Mercado Doméstico',
          originationConsolidatedTitle: 'Originação'
        },
        programConsolidated: {
          title: 'Programa - Consolidado',
          exportConsolidatedTitle: 'Exportação',
          domesticMarketConsolidatedTitle: 'Mercado Doméstico',
          originationConsolidatedTitle: 'Originação',
          pdConsolidatedTitle: 'Prêmio e Desconto',
          storageCostConsolidatedTitle: 'Custo de Armazenagem'
        },
        domesticMarketProgram: {
          title: 'Gráfico - Programa Mercado Doméstico'
        },
        bookingCapacity: {
          title: 'Gráfico - Capacidade de Bookings'
        },
        exportProgram: {
          title: 'Gráfico - Programa de Exportação'
        },
        originationProgram: {
          title: 'Gráfico - Programa de Originação'
        },
        tooltipStorageCost:
          'Consolidado exibido contempla apenas informações de períodos futuros. Não será considerado o custo de armazenagem executado.',
        tooltipPD:
          'P/D exibido contempla apenas informações de períodos futuros. Não será considerado o cálculo de P/D de volumes realizados.'
      },
      controlTower: {
        title: 'Torre de Controle'
      },
      controlTowerUnity: {
        title: 'Unidade',
        code: 'Codigo',
        description: 'Descrição',
        symbol: 'Símbolo',
        category: 'Categoria'
      },
      controlTowerUnityConversion: {
        title: 'Conversão de Unidades',
        beginHorizon: 'Período Início',
        endHorizon: 'Período Fim',
        fromUnity: 'Unidade De',
        toUnity: 'Unidade Para',
        active: 'Ativo',
        factor: 'Fator de Conversão',
        errors: {
          sameUnits: 'Não é possível realizar conversão entre a mesma unidade'
        },
        hints: {
          factor:
            'O fator de conversão é uma multiplicação em relação a unidade DE'
        }
      },
      forecast: {
        forecast: 'Previsão',
        consolidated: 'Consolidado'
      },
      forecastSales: {
        title: 'Acurácia da previsão de venda',
        graphTitle: 'Acurácia de Venda',
        consolidated: 'Consolidado',
        period: 'Período',
        filter: 'Filtro',
        till: 'Até',
        indicator: {
          title: 'Indicadores',
          fa: 'PRINCIPAIS OFENSORES DE FA',
          clients: 'PRINCIPAIS OFENSORES DE CLIENTES',
          destiny: 'PRINCIPAIS OFENSORES DE PAÍSES DE DESTINO'
        }
      },
      forecastOrigin: {
        title: 'Aderência ao Plano de Originação',
        graphTitle: 'Aderência ao Plano de Originação',
        consolidated: 'Consolidado',
        period: 'Período',
        filter: 'Filtro',
        till: 'Até',
        indicator: {
          title: 'Indicadores',
          pa: 'PRINCIPAIS OFENSORES DE PA',
          producers: 'PRINCIPAIS OFENSORES DE PRODUTORES',
          uf: 'PRINCIPAIS OFENSORES UF'
        }
      },
      capacityUtilization: {
        title: 'Utilização de Capacidade',
        harvest: 'Safra',
        fiscalYear: 'Ano Fiscal',
        consolidated: 'Consolidado',
        ibp: 'IBP',
        to: 'A',
        graphTitle: 'Utilização da capacidade realizada e planejada',
        fulfilled: 'Realizado',
        planned: 'Planejado',
        indicators: {
          indicators: 'Indicadores',
          utilityOpen: 'Abertura de utilização (Planejado)'
        },
        filter: {
          title: 'Filter',
          period: 'Período',
          harvest: 'Safra',
          fiscalYear: 'Ano Fiscal'
        },
        railroadCapacity: 'Capacidade Ferrovia + hidrovia + estufagem',
        shipownerCapacity: 'Capacidade armador',
        werehouse: 'Capacidade armazém'
      },
      goal: {
        title: 'Meta',
        date: 'Data',
        goalType: 'Tipo de Meta',
        target: 'Target',
        value: 'Meta'
      },
      originationFulfilled: {
        title: 'Originação Realizada',
        date: 'Data',
        uf: 'UF',
        producer: 'Produtor',
        value: 'Originação'
      },
      plannedOrigination: {
        title: 'Originação Planejada',
        scenario: 'Cenário',
        date: 'Data',
        uf: 'UF',
        producer: 'Produtor',
        value: 'Originação'
      },
      fulfilledSale: {
        title: 'Venda Realizada',
        date: 'Data',
        country: 'País',
        client: 'Cliente',
        customer: 'Cliente',
        value: 'Venda'
      },
      plannedSale: {
        title: 'Venda Planejada',
        scenario: 'Cenário',
        date: 'Data',
        country: 'País',
        client: 'Cliente',
        customer: 'Cliente',
        value: 'Venda'
      },
      otifMonthly: {
        title: 'On Time In Full - Mensal',
        date: 'Date',
        onTime: 'On Time',
        inFull: 'In Full',
        customer: 'Cliente',
        originPort: 'Porto de Origem',
        destinyCountry: 'País de Destino'
      },
      otifWeekly: {
        title: 'On Time In Full - Semanal',
        date: 'Date',
        onTime: 'On Time',
        inFull: 'In Full',
        customer: 'Cliente',
        originPort: 'Porto de Origem',
        destinyCountry: 'País de Destino'
      },
      fulfilledCapacityUtilization: {
        title: 'Utilização de Capacidade Realizada',
        scenario: 'Cenário',
        date: 'Data',
        value: 'Utilização de Capacidade',
        capacityUtilizationType: 'Tipo de Utilização de Capacidade',
        name: 'Nome'
      },
      plannedCapacityUtilization: {
        title: 'Utilização de Capacidade Planejada',
        scenario: 'Cenário',
        date: 'Data',
        value: 'Utilização de Capacidade',
        capacityUtilizationType: 'Tipo de Utilização de Capacidade',
        name: 'Nome'
      },
      maximumCapacityUtilization: {
        title: 'Capacidade Máxima de Utilização',
        date: 'Data',
        name: 'Nome',
        maximumCapacity: 'Capacidade Máxima',
        capacityUtilizationType: 'Tipo de Utilização de Capacidade'
      },
      onTimeInFull: {
        graphTitle: 'OTIF - On Time in Full',
        otifLabel: 'OTIF Realizado',
        metaLabel: 'Meta OTIF',
        to: 'A',
        till: 'Até',
        consolidated: 'Consolidado',
        OTIF: 'OTIF',
        filter: 'Filtro',
        period: 'Período',
        title: 'Indicadores de Planejamento',
        customers: 'Clientes',
        destinyCountries: 'Países Destino',
        originPorts: 'Portos de Origem',
        indicator: {
          title: 'Indicadores',
          customers: 'Clientes com baixo OTIF',
          destinyCountries: 'Países Destino com baixo OTIF',
          originPorts: 'Porto Origem com baixo OTIF'
        }
      },
      weeklyPlanningDashboard: {
        title: 'Planejamento semanal',
        unityMeasure: 'unidade Medida',
        details: 'Detalhes',
        filter: 'Filtro'
      },
      takeUpPortal: {
        title: 'Portal Take-Up'
      },
      takeUpPurchaseContract: {
        messages: {
          generateBooks: 'Gerar Livros',
          confirmGenerateBooks:
            'Tem certeza que deseja gerar Livros para o contrato?',
          confirmGenerateBooksByFilter:
            'Tem certeza que deseja gerar Livros para os contratos filtrados?',
          confirmGenerateBooksByFilterOnAllItems:
            'NÃO há filtro aplicado! Tem certeza que deseja gerar Livros em TODOS os contratos?'
        },
        title: 'Contratos',
        company: 'Companhia',
        contractReference: 'Contrato',
        parent: 'Contrato Pai',
        cropYear: 'Safra',
        quantity: 'Quantidade',
        quantityUnit: 'Unidade de Medida - Quantidade',
        counterPartySector: 'Tipo de Faturamento',
        positionMonth: 'Posição',
        contractDate: 'Data do Contrato',
        counterpartyName: 'Contraparte',
        originState: 'UF Origem',
        ccQuality: 'Tipo Base (Tranche)',
        grade: 'Qualidade Esperada',
        producer: 'Produtor',
        counterpartyCode: 'Código Contraparte',
        baseTypeHeader: 'Tipo Base (Header)',
        brazilGradeCode: 'Brazil Grade Code',
        brazilGradePdPts: 'Brazil Grade P/d Pts',
        pdCurrency: 'Moeda do P/D',
        pdPriceCode: 'Price Code do P/D',
        minStaple: 'Min Staple',
        maxStaple: 'Max Staple',
        minAvgStaple: 'Min Avg Staple',
        minMic: 'Min Mic',
        maxMic: 'Max Mic',
        minStrength: 'Min Strength',
        maxStrength: 'Max Strength',
        minAvgStrength: 'Min Avg Strength',
        minSfi: 'Min Sfi',
        currency: 'Moeda',
        setUpDateContractMaintenace: 'Set Up Date (Contract Maintenace)',
        counterPartRef: 'Counter Part Ref',
        brazilGradeCodeFull: 'Brazil Grade Code',
        incoterm: 'Incoterm',
        tolerance: 'Variação'
      },
      takeUpReportInformationMaster: {
        messages: {
          calculateReport: 'Calcular Relatório',
          calculateReportSuccessMsg:
            'Cálculo de Relatório realizado com sucesso'
        },
        title: 'Relatório de Take-Up',
        cropYear: 'Safra',
        contract: 'Contrato',
        producerCode: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        month: 'Mês',
        classifier: 'Classificador',
        takeUpVolume: 'Volume do Take Up (kg)',
        lotQuantity: 'Quantidade de Lotes',
        producerRequestedChange: 'Solicitação de Troca',
        hviStatus: 'HVI',
        takeUpLotReportInformationSummary: {
          title: 'Detalhamento',
          book: 'Livro',
          takeUpInput: 'Take-Up Input',
          takeUpReportDate: 'Data do Report Take-Up',
          purchaseNo: 'Contrato',
          takeUpDate: 'Data do Take-Up',
          hviRecDate: 'Data Recebimento HVI',
          shipmentMonth: 'Shipment Month',
          lotRef: 'Lot Ref',
          noBales: 'No. Fardos',
          grossWeight: 'Volume de Take-Up',
          balesPerCntr: 'Fardos por cntrs',
          akt: 'AKT#',
          location: 'Localidade',
          gin: 'Algodoeira',
          ginCode: 'Algodoeira',
          region: 'Região',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySummary: 'Qlty Summary',
          bark: 'Caule Nível',
          bidens: 'Picao Nível',
          fumagHone: 'Fumagina Incid',
          grass: 'Capim Incid',
          scnSeed: 'Semente Incid',
          comments: 'Comentários',
          infLength: 'Inf Length',
          minLength: 'Min Length',
          maxLength: 'Max Length',
          avLength: 'Av Length',
          infStrength: 'Inf Strength',
          minStrength: 'Min Strength',
          maxStrength: 'Max Strength',
          avStrength: 'Av Strength',
          infMic: 'Inf Mic',
          minMic: 'Min Mic',
          maxMic: 'Max Mic',
          avMic: 'Av Mic',
          supMic: 'Sup Mic',
          sfiGreaterThan10: 'SFI>10',
          avSfi: 'Av SFI',
          avSci: 'Av SCI',
          avMat: 'Av Mat',
          avUi: 'Av UI',
          avElg: 'Av Elg',
          lenLte2659: 'Len <= 26.59',
          len2660To2739: 'Len 26.60 - 27.39',
          len2740To2742: 'Len 27.40 - 27.42',
          len2743To2809: 'Len 27.43 - 28.09',
          len2810To2817: 'Len 28.10 - 28.17',
          len2818To2897: 'Len 28.18 - 28.97',
          lenGte2898: 'Len >= 28.98',
          strLte2100: 'Str <= 21.00',
          str2100To2299: 'Str 21 - 22.99',
          str2300To2499: 'Str 23 - 24.99',
          str2500To2699: 'Str 25 - 26.99',
          str2700To2799: 'Str 27 - 27.99',
          str2800To2899: 'Str 28 - 28.99',
          str2900To3199: 'Str 29 - 31.99',
          strGte3200: 'Str >= 32.00',
          micLte249: 'Mic <= 2.49',
          mic250To269: 'Mic 2.50 - 2.69',
          mic270To299: 'Mic 2.70 - 2.99',
          mic300To329: 'Mic 3.00 - 3.29',
          mic330To349: 'Mic 3.30 - 3.49',
          mic350To429: 'Mic 3.50 - 4.29',
          mic430To459: 'Mic 4.30 - 4.59',
          mic460To490: 'Mic 4.60 - 4.90',
          mic491To529: 'Mic 4.91 - 5.29',
          micGte530: 'Mic >= 5.30',
          netWeight: 'Peso Líquido',
          staple: 'Staple',
          controller: 'Controller',
          buyingOffice: 'Buying Office',
          part4: 'Part 4',
          status: 'Status'
        }
      },
      takeUpLocality: {
        title: 'Localidades',
        city: 'Município',
        state: 'UF',
        subRegional: 'Sub-Regional'
      },
      takeUpContaminationValue: {
        title: 'Contaminação',
        greatness: 'Grandeza',
        description: 'Descrição',
        acceptableValue: 'Valor Aceitável'
      },
      takeUpHvi: {
        title: 'Upload HVI',
        cropYear: 'Safra',
        contract: 'Contrato',
        producerCode: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        month: 'Mês',
        classifier: 'Classificador',
        lotQuantity: 'Quantidade de Lotes',
        takeUpStatus: 'Take-Up',
        messages: {
          deleteAllInputHvi: 'Deletar HVI do contrato',
          confirmationToDeleteAllInputHvi:
            'Deseja apagar o HVI do contrato selecionado?',
          confirmationToDeleteRejectedLots:
            'Há lotes rejeitados no P/D. Deseja deletar HVI relacionado a lotes rejeitados?'
        },
        takeUpHviInput: {
          title: 'HVI',
          takeUpStatus: 'Take-Up',
          company: 'Companhia',
          cropYear: 'Safra',
          month: 'Mês',
          recDate: 'Data de recebimento',
          companyClass: 'Classificador',
          producerName: 'Produtor',
          ginName: 'Algodoeira',
          cak: 'Contrato',
          lot: 'Lote',
          saiCode: 'S.A.I. Code (Brz)',
          baleId: 'Fardo ID',
          sci: 'SCI',
          mic: 'Mic',
          mat: 'Mat',
          uhmlInch: 'UHML [inch]',
          uhmlMm: 'UHML [mm]',
          ui: 'UI [%]',
          sfi: 'SFI [%]',
          str: 'Str [G/tex]',
          elg: 'Elg [%]',
          csp: 'Csp',
          rd: 'Rd',
          plusB: '+ b',
          cGrg: 'CGrg',
          trCnt: 'Tr Cnt',
          trAr: 'Tr Ar',
          trId: 'Tr ID',
          book: 'Livro',
          username: 'Importado por'
        }
      },
      qualityAndContamination: {
        title: 'Qualidade e Contaminação'
      },
      takeUpCompany: {
        title: 'Empresas',
        code: 'Código',
        name: 'Nome',
        fullAccess: 'Acesso Completo',
        takeUpCompaniesUsers: {
          title: 'Usuários',
          user: 'Usuário',
          company: 'Empresa'
        },
        takeUpCompanyProducer: {
          title: 'Produtor',
          company: 'Classificador',
          producer: 'Código',
          producerName: 'Produtor'
        }
      },
      takeUpBook: {
        messages: {
          makeNotAvailable: 'Tornar não disponível',
          makeAvailable: 'Tornar disponível',
          confirmChangeBookStatus:
            'Tem certeza que deseja mudar status do livro?',
          confirmChangeBookStatusByFilter:
            'Tem certeza que deseja mudar status dos livros filtrados?',
          confirmChangeBookStatusByFilterOnAllItems:
            'NÃO há filtro aplicado! Tem certeza que deseja mudar status em TODOS os livros?',
          generateProducerMonitoring: 'Gerar Acompanhamento do Produtor',
          confirmGenerateProducerMonitoring:
            'Tem certeza que deseja gerar Acompanhamento do Produtor para o livro?',
          confirmGenerateProducerMonitoringByFilter:
            'Tem certeza que deseja gerar Acompanhamento do Produtor para os livros filtrados?',
          confirmGenerateProducerMonitoringByFilterOnAllItems:
            'NÃO há filtro aplicado! Tem certeza que deseja gerar Acompanhamento do Produtor em TODOS os livros?'
        },
        title: 'Livros',
        cropYear: 'Safra',
        company: 'Companhia',
        contract: 'Contrato',
        month: 'Mês',
        volume: 'Volume (T)',
        state: 'UF',
        region: 'Região',
        producerCode: 'Produtor',
        producerName: 'Nome do Produtor',
        brainOption: 'Opção Brain',
        status: 'Status'
      },
      takeUpGin: {
        title: 'Algodoeira',
        ginSaiCode: 'Código Algodoeira (S.A.I)',
        code: 'Código da Algodoeira / Fazenda',
        companyName: 'Razão',
        region: 'Sub-Regional',
        state: 'UF',
        city: 'Município',
        zipCode: 'CEP',
        streetRoadName: 'Rua / Nome da Estrada',
        number: 'Número',
        latitude: 'Latitude',
        longitude: 'Longitude',
        routing: 'Roteirização',
        restriction: 'Restrição',
        isGinPreview: 'Código Provisório'
      },
      takeUpProducer: {
        title: 'Produtor',
        code: 'Código do Produtor',
        name: 'Produtor',
        responsibleMerchant: 'Merchant Responsável',
        region: 'Sub-Regional',
        state: 'UF do Produtor',
        city: 'Município',
        isProducerPreview: 'Código Provisório',
        isCreateGinPreview: 'Permitir Criar Algodoeira Temporária',
        takeUpProducerGin: {
          title: 'Algodoeira',
          gin: 'Algodoeira',
          producerName: 'Produtor',
          ginSaiCode: 'Código Algodoeira (S.A.I)',
          code: 'Código da Algodoeira / Fazenda',
          companyName: 'Razão',
          region: 'Sub-Regional',
          state: 'UF',
          city: 'Município',
          zipCode: 'CEP',
          streetRoadName: 'Rua / Nome da Estrada',
          number: 'Número',
          latitude: 'Latitude',
          longitude: 'Longitude',
          routing: 'Roteirização',
          restriction: 'Restrição'
        },
        takeUpCounterPartyRef: {
          title: 'Counter Party Ref',
          producer: 'Código do Produtor',
          counterPartyRef: 'Counter Party Ref'
        }
      },
      takeUpReferenceTableIntrinsic: {
        title: 'Tabela de Referência - Intrínseco',
        table: 'Tabela',
        currency: 'Moeda',
        takeUpReferenceTableIntrinsicGradeRule: {
          title: 'Tipo',
          referenceTable: 'Tabela de Referência',
          grade: 'Tipo',
          gradePd: 'P/D do Grade'
        },
        takeUpReferenceTableIntrinsicRule: {
          title: 'Intrínseco',
          referenceTable: 'Tabela de Referência',
          grade: 'Tipo',
          gradePd: 'P/D do Grade',
          greatness: 'Grandeza',
          description: 'Descrição',
          importance: 'Importância',
          group: 'Grupo',
          calculationRuleType: 'Tipo de Cálculo',
          min: 'Min >=',
          max: 'Max <',
          avg: 'Avg >=',
          inferiorLimit: 'Limite Inferior (%)',
          superiorLimit: 'Limite Superior (%)',
          intrinsicPd: 'P/D Intrínseco'
        }
      },
      takeUpReferenceTableContamination: {
        title: 'Tabela de Referência - Contaminação',
        table: 'Tabela',
        default: 'Padrão',
        currency: 'Moeda',
        makeDefault: 'Tornar Padrão',
        makeNotDefault: 'Cancelar Padrão',
        changeDefaultMessage: 'Tem certeza que deseja tornar a tabela padrão?',
        takeUpReferenceTableContaminationRule: {
          title: 'Contaminação',
          referenceTable: 'Tabela de Referência',
          greatness: 'Grandeza',
          description: 'Descrição',
          importance: 'Importância',
          min: 'Min',
          max: 'Max',
          equal: 'Igual',
          notEqual: 'Diferente',
          pd: 'P/D'
        }
      },
      takeUpGrade: {
        title: 'Tipos de Algodão',
        grade1: 'Tipo de Algodão 1',
        grade2: 'Tipo de Algodão 2',
        rating: 'Classificação (1 em relação ao 2)'
      },
      takeUpOffer: {
        title: 'Ofertas',
        contract: 'Contrato',
        cropYear: 'Safra',
        producer: 'Produtor',
        producerName: 'Nome do Produtor',
        classifier: 'Classificador',
        status: 'Avaliação Cargill',
        insertedBy: 'Inserido Por',
        takeUpOfferLot: {
          title: 'Lotes',
          offer: 'Oferta',
          offerContract: 'Oferta',
          cropYear: 'Safra',
          gin: 'Algodoeira',
          ginName: 'Nome da Algodoeira',
          lotReference: 'Lote',
          grade: 'Tipo'
        }
      },
      takeUpCancellations: {
        title: 'Distratos',
        company: 'Companhia',
        contract: 'Contrato',
        cropYear: 'Safra',
        producer: 'Contraparte',
        farm: 'Algodoeira',
        lotReference: 'Lote',
        saiCode: 'S.A.I. Code (Brz)',
        quantity: 'Quantidade (kg)',
        purchaseContract: 'Contrato de Compra',
        producerName: 'Contraparte'
      },
      takeUpSchedules: {
        title: 'Agendamentos',
        cropYear: 'Safra',
        company: 'Companhia',
        producer: 'Produtor',
        contract: 'Contrato',
        lotReference: 'Lote',
        volumeForecast: 'Previsão de Volume (T)',
        hviProducerAgreementDate: 'Data do De Acordo do Produtor de HVI',
        producerMonitoringFarmName:
          'Nome da Algodoeira (Acompanhamento do Produtor)',
        producerMonitoringFarmCode:
          'Código da Algodoeira (Acompanhamento do Produtor)',
        hviFarmName: 'Nome da Algodoeira (HVI)',
        hviFarmCode: 'Código da Algodoeira (HVI)',
        takeUpReportFarmName: 'Nome da Algodoeira (Relatório de Take Up)',
        takeUpReportFarmCode: 'Código da Algodoeira (Relatório de Take Up)',
        warehouseName: 'Nome Warehouse',
        warehouse: 'Código Warehouse',
        scheduledTakeUpDate: 'Data Agendada do Take Up',
        period: 'Período',
        classifier: 'Classificador',
        controller: 'Controller (Email)',
        controllerName: 'Controller (Nome)',
        takeUpState: 'UF do Take Up',
        takeUpCityLabel: 'Cidade do Take Up',
        takeUpCity: 'Cidade do Take Up',
        takeUpDate: 'Data Realizada do Take-Up',
        accomplishedVolume: 'Volume Realizado (Pós Take Up) (kg)',
        lotStatus: 'Status do Lote',
        book: 'Book'
      },
      takeUpProducerMonitoring: {
        title: 'Acompanhamento de Produtor',
        company: 'Companhia',
        cropYear: 'Safra',
        producer: 'Produtor',
        contract: 'Contrato',
        volumeMonth: 'Volume do Mês (t)',
        month: 'Mês',
        modality: 'Modalidade',
        marketplace: 'Mercado',
        classifierCode: 'Classificador',
        status: 'Status',
        lastUpdateClassifier: 'Última Atualização (Classificador)',
        expectedHviDate: 'Data Esperada do HVI',
        expectedTakeUpDate: 'Data Esperada do Take-Up',
        location: 'Código da Algodoeira',
        locationCode: 'Código da Algodoeira',
        locationName: 'Nome da Algodoeira',
        lastUpdatedByName: 'Última Atualização Pelo:',
        comments: 'Comentários',
        leaveComment: 'Deixe uma mensagem para enviar',
        deleteSuccess: 'Deleção realizada com Sucesso',
        deleteError: 'Deleção falhou',
        commentSendSuccess: 'Comentário Enviado com Sucesso',
        commentSendError: 'Erro ao enviar comentário',
        emptyCommentError: 'Erro: Comentário Vazio',
        expectedVolume: 'Volume Previsto (t)'
      },
      takeUpQualityAndContamination: {
        title: 'Qualidade e Contaminação',
        code: 'Código',
        description: 'Descrição',
        type: 'Tipo',
        takeUpQualityAndContaminationTranslation: {
          title: 'Grafias de Qualidade e Contaminação',
          qualityContamination: 'Código Qualidade/Contaminação',
          alternativeCode: 'Código Alternativo'
        }
      },
      takeUpLotReportInputMaster: {
        title: 'Upload Take-Up',
        cropYear: 'Safra',
        contract: 'Contrato',
        producerCode: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        month: 'Mês',
        classifier: 'Classificador',
        takeUpVolume: 'Volume do Take Up (kg)',
        lotQuantity: 'Quantidade de Lotes',
        producerRequestedChange: 'Solicitação de Troca',
        hviStatus: 'HVI',
        acceptableVolume: {
          minLabel: 'Peso Mínimo (kg)',
          maxLabel: 'Peso Máximo (kg)'
        },
        messages: {
          deleteAllInputTakeUp: 'Deletar Input Take-Up do contrato',
          confirmationToDeleteAllInputTakeUp:
            'Deseja apagar o Input Take-Up do contrato selecionado?',
          calculateUsBucket: 'Calcular US Bucket',
          calculateUsBucketSuccessMsg:
            'Cálculo de US Bucket realizado com sucesso. Quality Group: {qualityGroup}',
          calculateUsBucketByBookSuccessMsg:
            'Cálculo de US Bucket realizado com sucesso!',
          calculateUsBucketByBookWarningMsg:
            'Cálculo de US Bucket realizado parcialmente. {hviStandbyQty} lotes com HVI - STANDBY!',
          calculateUsBucketByBookErrorMsg:
            'Cálculo de US Bucket realizado parcialmente. {errorsQty} lotes com erro. Tela de log: {logTitle}',
          confirmationToDeleteRejectedLots:
            'Há lotes rejeitados no P/D. Deseja deletar Take-Up relacionado a lotes rejeitados?',
          settingsAcceptableVolume: 'Configure o volume aceitável',
          infoAcceptableVolume:
            'Informação para validação de peso médio do fardo'
        },
        takeUpLotReportInput: {
          title: 'Input Take-Up',
          reportDate: 'Data do Report',
          producerRequestedChange: 'Produtor Solicitou Alteração?',
          statusHvi: 'Status HVI',
          company: 'Companhia',
          cropYear: 'Safra',
          contract: 'Contrato',
          companyClass: 'Empresa Class',
          takeUpDate: 'Data do Take-Up',
          hviRecDate: 'Data Recebimento HVI',
          lotRef: 'Lot Ref',
          bales: 'Fardos',
          grossWeight: 'Volume de Take-Up',
          balesCntr: 'Fardos p Cntr',
          baleAvg: 'Peso Méd/Fardo',
          deliveryProducer: 'Código do Produtor de Entrega',
          deliveryProducerName: 'Nome do Produtor de Entrega',
          gin: 'Cód Algodoeira',
          ginRegion: 'Região',
          ginName: 'Nome Algodoeira',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySum: 'Qlty Sum.',
          bark: 'Bark',
          bidens: 'Bidens',
          fumagHone: 'Fumag / Hone',
          grass: 'Grass',
          scnSeed: 'SCN / SEED',
          comments: 'Comentários',
          controller: 'Controller',
          lotStatus: 'Status Lote',
          reasonForRefusal: 'Motivo da Recusa',
          negotiationStatus: 'Status de Negociação',
          username: 'Importado por'
        }
      },
      takeUpSchedulesFarmReportLog: {
        title: 'Take-Up Schedules Generator Report Log',
        company: 'Company',
        contract: 'Contract',
        cropYear: 'Crop Year',
        classifier: 'Classifier',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        farmCode: 'Farm Code',
        farmName: 'Farm Name',
        producerMonitoringStatus: 'Producer Monitoring Status',
        producerMonitoringId: 'Producer Monitoring Id',
        contractId: 'Contract Id',
        offerId: 'Offer Id',
        bookId: 'Book Id',
        farmId: 'Farm Id',
        event: 'Event',
        validationEvent: 'Validation Event',
        numberOfSchedulesWithHviFarmUpdated:
          'Number Of Schedules With Hvi Farm Updated',
        schedulesLotsWithHviFarmUpdated: 'Schedules Lots With Hvi Farm Updated',
        numberOfSchedulesWithTakeUpReportFarmUpdated:
          'Number Of Schedules With Take Up Report Farm Updated',
        schedulesLotsWithTakeUpReportFarmUpdated:
          'Schedules Lots With Take Up Report Farm Updated',
        numberOfSchedulesAdded: 'Number Of Schedules Added',
        schedulesLotsAdded: 'Schedules Lots Added',
        numberOfSchedulesDeleted: 'Number Of Schedules Deleted',
        schedulesLotsDeleted: 'Schedules Lots Deleted',
        numberOfSchedulesIgnored: 'Number Of Schedules Ignored',
        schedulesLotsIgnored: 'Schedules Lots Ignored',
        triggerStart: 'Trigger Start',
        triggerEnd: 'Trigger End',
        triggerType: 'Trigger Type',
        key: 'Key',
        contractKey: 'Contract Key'
      },
      takeUpUsBucketCalculatorReportLog: {
        title: 'Take-Up US Bucket Calculator Report Log',
        triggerStart: 'Trigger Start',
        triggerType: 'Trigger Type',
        usBucketTable: 'US Bucket Table',
        company: 'Company',
        contract: 'Contract',
        cropYear: 'Crop Year',
        classifier: 'Classifier',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        ginCode: 'Gin Code',
        ginName: 'Gin Name',
        lotRef: 'Lot Ref',
        noBales: 'No Bales',
        hviNoBales: 'Hvi No Bales',
        grade: 'Grade',
        qltySum: 'Qlty Sum',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag Hone',
        grass: 'Grass',
        scnSeed: 'Scn Seed',
        minLength: 'Min Length',
        maxLength: 'Max Length',
        avLength: 'Av Length',
        minStrength: 'Min Strength',
        maxStrength: 'Max Strength',
        avStrength: 'Av Strength',
        infMic: 'Inf Mic',
        minMic: 'Min Mic',
        maxMic: 'Max Mic',
        avMic: 'Av Mic',
        supMic: 'Sup Mic',
        errorMsg: 'Error Msg'
      },
      takeUpUsBucket: {
        title: 'US Bucket',
        table: 'Tabela',
        default: 'Padrão',
        makeDefault: 'Tornar Padrão',
        makeNotDefault: 'Cancelar Padrão',
        changeDefaultMessage: 'Tem certeza que deseja tornar a tabela padrão?',
        removeDefaultMessage:
          'Tem certeza que deseja cancelar a tabela como padrão?',
        takeUpUsBucketImportance: {
          title: 'Importância',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Nome',
          importance: 'Importância'
        },
        takeUpUsBucketContamination: {
          title: 'Contaminação',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Nome',
          contamination: 'Contaminação',
          operator: 'Operador',
          value: 'Valor'
        },
        takeUpUsBucketGrade: {
          title: 'Tipo',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Nome',
          grade: 'Tipo',
          operator: 'Operador'
        },
        takeUpUsBucketIntrinsic: {
          title: 'Intrínseco',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Nome',
          greatness: 'Grandeza',
          min: 'Min',
          max: 'Max',
          avg: 'Avg',
          equal: 'Igual',
          comparationRule: 'Regra de Comparação'
        }
      },
      takeUpPremiumDiscountManagement: {
        title: 'Cálculo PD',
        showLines: 'Visualizar linhas',
        managerial: 'Gerencial',
        detailing: 'Detalhamento',
        exportForProducer: 'Extrair no formato produtor',
        previewWeightedAverage: 'Prévia ponderada',
        applyWeightedAverage: 'Aplicar média ponderada',
        clearWeightedAverage: 'Limpar média ponderada',
        calculatePremiumDiscount: 'Calcular Prêmio/Desconto',
        hviExtract: 'HVI',
        unsavedChangesMessage:
          'Você tem alterações não salvas. Deseja descartar as alterações e continuar?',
        confirmApplyWeightedAverage:
          'Tem certeza que deseja aplicar a média ponderada nos itens filtrados?',
        confirmApplyWeightedAverageOnAllItems:
          'NÃO há filtro aplicado! Tem certeza que deseja aplicar a média ponderada em TODOS os itens?',
        confirmClearWeightedAverage:
          'Tem certeza que deseja limpar a média ponderada nos itens filtrados?',
        confirmClearWeightedAverageOnAllItems:
          'NÃO há filtro aplicado! Tem certeza que deseja limpar a média ponderada em TODOS os itens?',
        confirmFieldsStatusChange:
          'O status dos campos {0} estavam aprovados para algum registro e foram modificados. Deseja alterar o status?',
        confirmFieldStatusChange:
          'O status do campo {0} estava aprovado para algum registro e foi modificado. Deseja alterar o status?',
        confirmCalculatePremiumDiscount:
          'Tem certeza que deseja calcular Prêmio/Desconto nos itens filtrados?',
        confirmCalculatePremiumDiscountOnAllItems:
          'NÃO há filtro aplicado! Tem certeza que deseja calcular Prêmio/Desconto em TODOS os itens?',
        premiumDiscountActions: 'Ações',
        premiumDiscountCalculateResultsWithAlerts:
          'Cálculo de P/D gerou alertas!<br>{0} linhas com alertas.<br>{1} linhas com sucesso.',
        premiumDiscountCalculateResultItemWithAlerts:
          'Cálculo de P/D gerou {0} alertas.',
        classifier: 'Classificador',
        controller: 'Controller',
        purchaseNo: 'Contrato',
        reportDate: 'Data do Report',
        hviRecDate: 'Data Recebimento HVI',
        takeUpDate: 'Data do Take-Up',
        market: 'Mercado',
        lotRef: 'Lot Ref',
        bales: 'Fardos',
        grossWeight: 'Peso Bruto',
        balesPerCntr: 'Fardos p Cntr',
        baleAvg: 'Peso Méd/Fardo',
        location: 'Localidade',
        warehouseName: 'Nome Warehouse',
        warehouseCode: 'Código Warehouse',
        gin: 'Código da Algodoeira',
        ginName: 'Nome da Algodoeira',
        region: 'Região',
        offerGrade: 'Oferta - Grade',
        grade: 'Grade',
        tcg: 'TCG',
        cargillGrade: 'Cargill Grade (CGL)',
        cropYear: 'Safra',
        company: 'Companhia',
        producer: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        contract: 'Contrato',
        lotOffer: 'Lote (Oferta)',
        referenceQualitySummary: 'Referência Quality Summary',
        pdBrainGrade: 'P/D Brain - Tipo',
        pdCargillGrade: 'P/D Cargill - Tipo',
        rcpPdGrade: 'RCP - P/D Tipo',
        rcpPdContamination: 'RCP - P/D Contaminação',
        qualitySummary: 'Quality Summary',
        contractReference: 'Contrato Referência',
        hviAndGradePdReferenceTable: 'HVI e Tipo: Tabela Referência De P/D',
        contaminationPdReferenceTable:
          'Contaminação - Tabela Referência De P/D',
        gradeBrainRecommendationTakeUp: 'Tipo - Recomendação Brain - Take Up',
        brainRecommendationHvi: 'Recomendação Brain - HVI',
        brainContaminationRecommendationTakeUp:
          'Contaminação Recomendação Brain - Take Up',
        pdBrainHvi: 'P/D Brain - HVI',
        pdCargillHvi: 'P/D Cargill - HVI',
        pdBrainTakeUpType: 'P/D Brain - Take Up (Tipo)',
        pdCargillTakeUpType: 'P/D Cargill - Take Up (Tipo)',
        pdBrainTakeUpContamination: 'P/D Brain - Take Up Contaminação',
        pdCargillTakeUpContamination: 'P/D Cargill - Take Up Contaminação',
        cargillDecisionPdHvi: 'Decisão Cargill P/D - HVI',
        dateOfDecisionCargillHvi: 'Data da Decisão Cargill HVI',
        hviProducerAgreement: 'Acordo Produtor HVI',
        hviAgreementDate: 'Data do Acordo HVI',
        cargillDecisionPdTakeUp: 'Decisão Cargill P/D - Take Up',
        dateDecisionCargillPdTakeUp: 'Data Decisão Cargill P/D - Take Up',
        takeUpProducerAgreement: 'Acordo Produtor Take Up',
        takeUpAgreementDate: 'Data do Acordo Take Up',
        lotStatus: 'Status do Lote',
        totalBrainPd: 'P/D Total Brain',
        totalFinalPd: 'P/D Total Final',
        pdForRecapCases: 'P/D Para Casos De Recap',
        weightedAverage: 'Média Ponderada',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag / Hone',
        grass: 'Grass',
        scnSeed: 'SCN / SEED',
        pdBrainContamination: 'P/D Brain - Contaminação',
        pdCargillContamination: 'P/D Cargill - Contaminação',
        commentsLot: 'Comentários Lote',
        commentsTakeUp: 'Comentários Take Up',
        infLength: 'Inf',
        minLength: 'Min',
        maxLength: 'Max',
        avLength: 'Avg',
        infStrength: 'Inf',
        minStrength: 'Min',
        maxStrength: 'Max',
        avStrength: 'Avg',
        infMic: 'Inf',
        minMic: 'Min',
        maxMic: 'Max',
        avMic: 'Avg',
        supMic: 'Sup',
        sfiGreaterThan10: 'Qtt > 10 %',
        avSfi: 'Avg',
        avSci: 'Avg',
        avMat: 'Avg',
        avUi: 'Avg',
        avElg: 'Avg',
        sfiPdBrain: 'P/D Brain',
        sfiPdCargill: 'P/D Cargill',
        lenLte2659: '< = 26.59',
        len2660To2739: '26.60 - 27.39',
        len2740To2742: '27.40 - 27.42',
        len2743To2809: '27.43 - 28.09',
        len2810To2817: '28.10 - 28.17',
        len2818To2897: '28.18 - 28.97',
        lenGte2898: '> = 28.98',
        lenPercentageUnder2740: '% de Fibras (Todas abaixo de 27,40)',
        lenPercentage35: '% porcentagem de Fibras 35',
        lenPdBrain: 'P/D Brain',
        lenPdCargill: 'P/D Cargill',
        strLte2100: '< = 21.00',
        str2100To2299: '21 - 22.99',
        str2300To2499: '23 - 24.99',
        str2500To2599: '25 - 25.99',
        str2600To2699: '26 - 26.99',
        str2700To2799: '27 - 27.99',
        str2800To2899: '28 - 28.99',
        str2900To3199: '29 - 31.99',
        strGte3200: '> = 32.00',
        strPercentageUnder2700: '% Abaixo de 27',
        strPercentage2700: '% GPT 27',
        strPdBrain: 'P/D Brain',
        strPdCargill: 'P/D Cargill',
        micLte249: '< = 2.49',
        mic250To269: '2.50 - 2.69',
        mic270To299: '2.70 - 2.99',
        mic300To329: '3.00 - 3.29',
        mic330To349: '3.30 - 3.49',
        mic350To429: '3.50 - 4.29',
        mic430To459: '4.30 - 4.59',
        mic460To490: '4.60 - 4.90',
        mic491To529: '4.91 - 5.29',
        micGte530: '> = 5.30',
        micPercentageUnder350: '% Abaixo de 3.50',
        micPercentageOver490: '% Acima de 4.90',
        micPdBrainInfMic: 'P/D Brain - Inf Mic',
        micPdBrainSupMic: 'P/D Brain - Sup Mic',
        micPdBrain: 'P/D Brain',
        micPdCargillInfMic: 'P/D Cargill - Inf Mic',
        micPdCargillSupMic: 'P/D Cargill - Sup Mic',
        micPdCargill: 'P/D Cargill',
        totalHviPdBrain: 'P/D Brain',
        totalHviPdCargill: 'P/D Cargill',
        finalPdBrain: 'P/D Brain',
        resultingPd: 'P/D Resultante',
        commercialArrangement: 'Acerto Comercial',
        finalPdBrainProportional: 'Proporcional',
        pdCurrency: 'Moeda do P/D',
        ptax: 'PTAX',
        active: 'Ativo',
        level1And2: 'Level 1-2 (only Incidence Bark-SCN)',
        estraneousMatter: 'Contaminação',
        hviSummary: 'HVI - Summary',
        hviRanges: 'HVI - Ranges',
        mic: 'Mic',
        uhml: 'UHML [mm]',
        gpt: 'GPT [G/tex]',
        sfi: 'SFI [%]',
        sci: 'SCI',
        mat: 'Mat',
        ui: 'UI [%]',
        elg: 'Elg [%]',
        sfiPd: 'SFI',
        totalHvi: 'Total HVI',
        final: 'Final',
        comments: 'Comentários'
      },
      takeUpPremiumDiscountReportLog: {
        title: 'Cálculo PD - Report Log',
        executionStart: 'Execution Start',
        classifier: 'Classificador',
        controller: 'Controller',
        purchaseNo: 'Contrato',
        reportDate: 'Data do Report',
        hviRecDate: 'Data Recebimento HVI',
        takeUpDate: 'Data do Take-Up',
        market: 'Mercado',
        lotRef: 'Lot Ref',
        bales: 'Fardos',
        grossWeight: 'Peso Bruto',
        balesPerCntr: 'Fardos p Cntr',
        baleAvg: 'Peso Méd/Fardo',
        location: 'Localidade',
        warehouseName: 'Nome Warehouse',
        warehouseCode: 'Código Warehouse',
        gin: 'Código da Algodoeira',
        ginName: 'Nome da Algodoeira',
        region: 'Região',
        grade: 'Grade',
        tcg: 'TCG',
        cargillGrade: 'Cargill Grade (CGL)',
        cropYear: 'Safra',
        company: 'Companhia',
        producer: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        contract: 'Contrato',
        lotOffer: 'Lote (Oferta)',
        referenceQualitySummary: 'Referência Quality Summary',
        pdBrainGrade: 'P/D Brain - Tipo',
        pdCargillGrade: 'P/D Cargill - Tipo',
        rcpPdGrade: 'RCP - P/D Tipo',
        rcpPdContamination: 'RCP - P/D Contaminação',
        qualitySummary: 'Quality Summary',
        contractReference: 'Contrato Referência',
        hviAndGradePdReferenceTable: 'HVI e Tipo: Tabela Referência De P/D',
        contaminationPdReferenceTable:
          'Contaminação - Tabela Referência De P/D',
        gradeBrainRecommendationTakeUp: 'Tipo - Recomendação Brain - Take Up',
        brainRecommendationHvi: 'Recomendação Brain - HVI',
        brainContaminationRecommendationTakeUp:
          'Contaminação Recomendação Brain - Take Up',
        pdBrainHvi: 'P/D Brain - HVI',
        pdCargillHvi: 'P/D Cargill - HVI',
        pdBrainTakeUpType: 'P/D Brain - Take Up (Tipo)',
        pdCargillTakeUpType: 'P/D Cargill - Take Up (Tipo)',
        pdBrainTakeUpContamination: 'P/D Brain - Take Up Contaminação',
        pdCargillTakeUpContamination: 'P/D Cargill - Take Up Contaminação',
        cargillDecisionPdHvi: 'Decisão Cargill P/D - HVI',
        dateOfDecisionCargillHvi: 'Data da Decisão Cargill HVI',
        hviProducerAgreement: 'Acordo Produtor HVI',
        hviAgreementDate: 'Data do Acordo HVI',
        cargillDecisionPdTakeUp: 'Decisão Cargill P/D - Take Up',
        dateDecisionCargillPdTakeUp: 'Data Decisão Cargill P/D - Take Up',
        takeUpProducerAgreement: 'Acordo Produtor Take Up',
        takeUpAgreementDate: 'Data do Acordo Take Up',
        lotStatus: 'Status do Lote',
        totalBrainPd: 'P/D Total Brain',
        totalFinalPd: 'P/D Total Final',
        pdForRecapCases: 'P/D Para Casos De Recap',
        weightedAverage: 'Média Ponderada',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag / Hone',
        grass: 'Grass',
        scnSeed: 'SCN / SEED',
        pdBrainContamination: 'P/D Brain - Contaminação',
        pdCargillContamination: 'P/D Cargill - Contaminação',
        commentsLot: 'Comentários Lote',
        commentsTakeUp: 'Comentários Take Up',
        infLength: 'Inf',
        minLength: 'Min',
        maxLength: 'Max',
        avLength: 'Avg',
        infStrength: 'Inf',
        minStrength: 'Min',
        maxStrength: 'Max',
        avStrength: 'Avg',
        infMic: 'Inf',
        minMic: 'Min',
        maxMic: 'Max',
        avMic: 'Avg',
        supMic: 'Sup',
        sfiGreaterThan10: 'Qtt > 10 %',
        avSfi: 'Avg',
        avSci: 'Avg',
        avMat: 'Avg',
        avUi: 'Avg',
        avElg: 'Avg',
        sfiPdBrain: 'P/D Brain',
        sfiPdCargill: 'P/D Cargill',
        lenLte2659: '< = 26.59',
        len2660To2739: '26.60 - 27.39',
        len2740To2742: '27.40 - 27.42',
        len2743To2809: '27.43 - 28.09',
        len2810To2818: '28.10 - 28.18',
        len2819To2889: '28.19 - 28.89',
        lenGte2890: '> = 28.90',
        lenPercentageUnder2740: '% de Fibras (Todas abaixo de 27,40)',
        lenPercentage35: '% porcentagem de Fibras 35',
        lenPdBrain: 'P/D Brain',
        lenPdCargill: 'P/D Cargill',
        strLte2100: '< = 21.00',
        str2100To2299: '21 - 22.99',
        str2300To2499: '23 - 24.99',
        str2500To2599: '25 - 25.99',
        str2600To2699: '26 - 26.99',
        str2700To2799: '27 - 27.99',
        str2800To2899: '28 - 28.99',
        str2900To3199: '29 - 31.99',
        strGte3200: '> = 32.00',
        strPercentageUnder2700: '% Abaixo de 27',
        strPercentage2700: '% GPT 27',
        strPdBrain: 'P/D Brain',
        strPdCargill: 'P/D Cargill',
        micLte249: '< = 2.49',
        mic250To269: '2.50 - 2.69',
        mic270To299: '2.70 - 2.99',
        mic300To329: '3.00 - 3.29',
        mic330To349: '3.30 - 3.49',
        mic350To429: '3.50 - 4.29',
        mic430To459: '4.30 - 4.59',
        mic460To490: '4.60 - 4.90',
        mic491To529: '4.91 - 5.29',
        micGte530: '> = 5.30',
        micPercentageUnder350: '% Abaixo de 3.50',
        micPercentageOver490: '% Acima de 4.90',
        micPdBrainInfMic: 'P/D Brain - Inf Mic',
        micPdBrainSupMic: 'P/D Brain - Sup Mic',
        micPdBrain: 'P/D Brain',
        micPdCargillInfMic: 'P/D Cargill - Inf Mic',
        micPdCargillSupMic: 'P/D Cargill - Sup Mic',
        micPdCargill: 'P/D Cargill',
        totalHviPdBrain: 'P/D Brain',
        totalHviPdCargill: 'P/D Cargill',
        finalPdBrain: 'P/D Brain',
        resultingPd: 'P/D Resultante',
        commercialArrangement: 'Acerto Comercial',
        finalPdBrainProportional: 'Proporcional',
        pdCurrency: 'Moeda do P/D',
        ptax: 'PTAX',
        level1And2: 'Level 1-2 (only Incidence Bark-SCN)',
        estraneousMatter: 'Contaminação',
        hviSummary: 'HVI - Summary',
        hviRanges: 'HVI - Ranges',
        mic: 'Mic',
        uhml: 'UHML [mm]',
        gpt: 'GPT [G/tex]',
        sfi: 'SFI [%]',
        sci: 'SCI',
        mat: 'Mat',
        ui: 'UI [%]',
        elg: 'Elg [%]',
        sfiPd: 'SFI',
        totalHvi: 'Total HVI',
        final: 'Final'
      },
      takeUpImportsPreparation: {
        title: 'Preparação Imports',
        importInItas: 'Contém dados para importar no ITAS',
        cropYear: 'Safra',
        company: 'Companhia',
        market: 'Mercado',
        producerCode: 'Código do Produtor',
        producerName: 'Nome do Produtor',
        netWeight: 'Volume (KG)',
        takeUpImportReport: {
          title: 'Relatório de Imports',
          importInItas: 'Importar no ITAS',
          cropYear: 'Safra',
          company: 'Companhia',
          market: 'Mercado',
          producerName: 'Nome do Produtor',
          takeUpReportDate: 'Take up Report Date',
          purchaseNo: 'Contrato',
          takeUpDate: 'Data do Take up',
          hviRecDate: 'Data Recebimento HVI',
          producerAgreementDate: 'Data Acordo Produtor',
          shipmentMonth: 'Shipment Month',
          lotRef: 'Lot Ref',
          noBales: 'No. Fardos',
          grossWeight: 'Peso Bruto',
          balesPerCntr: 'Fardos por Cntr',
          akt: 'AKT#',
          location: 'Warehouse',
          gin: 'UK Gin',
          region: 'Região',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySummary: 'Qlty Summary',
          bark: 'Caule Nível',
          bidens: 'Picão Nível',
          fumagHone: 'Fumagina Incid',
          grass: 'Capim Incid',
          scnSeed: 'Semente Incid',
          comments: 'Comentários',
          infLength: 'Inf Length',
          minLength: 'Min Length',
          maxLength: 'Max Length',
          avLength: 'Av Length',
          infStrength: 'Inf Strength',
          minStrength: 'Min Strength',
          maxStrength: 'Max Strength',
          avStrength: 'Av Strength',
          infMic: 'Inf Mic',
          minMic: 'Min Mic',
          maxMic: 'Max Mic',
          avMic: 'Av Mic',
          supMic: 'Sup Mic',
          sfiGreaterThan10: 'SFI>10',
          avSfi: 'Av SFI',
          avSci: 'Av SCI',
          avMat: 'Av Mat',
          avUi: 'Av UI',
          avElg: 'Av Elg',
          lenLte2659: 'Len <= 26.59',
          len2660To2739: 'Len 26.60 - 27.39',
          len2740To2742: 'Len 27.40 - 27.42',
          len2743To2809: 'Len 27.43 - 28.09',
          len2810To2817: 'Len 28.10 - 28.17',
          len2818To2897: 'Len 28.18 - 28.97',
          lenGte2898: 'Len >= 28.98',
          strLte2100: 'Str <= 21.00',
          str2100To2299: 'Str 21 - 22.99',
          str2300To2499: 'Str 23 - 24.99',
          str2500To2699: 'Str 25 - 26.99',
          str2700To2799: 'Str 27 - 27.99',
          str2800To2899: 'Str 28 - 28.99',
          str2900To3199: 'Str 29 - 31.99',
          strGte3200: 'Str >= 32.00',
          micLte249: 'Mic <= 2.49',
          mic250To269: 'Mic 2.50 - 2.69',
          mic270To299: 'Mic 2.70 - 2.99',
          mic300To329: 'Mic 3.00 - 3.29',
          mic330To349: 'Mic 3.30 - 3.49',
          mic350To429: 'Mic 3.50 - 4.29',
          mic430To459: 'Mic 4.30 - 4.59',
          mic460To490: 'Mic 4.60 - 4.90',
          mic491To529: 'Mic 4.91 - 5.29',
          micGte530: 'Mic >= 5.30',
          netWeight: 'Peso Líquido',
          staple: 'Staple',
          controller: 'Controller',
          buyingOffice: 'Buying Office',
          part4: 'Part4'
        }
      },
      takeUpPremiumDiscountApplication: {
        title: 'Aplicação PD',
        premiumDiscount: 'Take-Up Input',
        company: 'Companhia',
        cropYear: 'Safra',
        setupDate: 'Setup Date',
        contract: 'Contrato',
        lotRef: 'Lot Ref',
        pd: 'P/D',
        grade: 'Purchase Grade',
        itasPd: 'ITAS P/D',
        status: 'Status',
        priceCode: 'Price Code',
        ccy: 'CCY',
        ukGin: 'UK GIN',
        ptax: 'PTAX',
        marks: 'MARKS',
        invoiced: 'Faturado (S/N)',
        lastItasUpdatedDate: 'Última Atualização ITAS'
      },
      takeUpHviExtraction: {
        title: 'Extração HVI',
        company: 'Companhia',
        cropYear: 'Safra',
        producerName: 'Produtor',
        warehouseCode: 'Warehouse',
        gin: 'UK Gin',
        contract: 'Contrato',
        marks: 'MARKS',
        setupDate: 'Setup Date',
        lotRef: 'Lote',
        bale: 'Fardo',
        weight: 'Peso',
        weightUnity: 'Unidade',
        lastItasUpdatedDate: 'Última Atualização ITAS',
        messages: {
          packingListExtract: 'Packing List',
          hviExtract: 'HVI'
        }
      },
      takeUpContractsPosition: {
        messages: {
          calculate: 'Calcular',
          confirmCalculateByFilter:
            'Tem certeza que deseja calcular Posição de Contratos do contratos filtrados?',
          confirmCalculateByFilterOnAllItems:
            'NÃO há filtro aplicado! Tem certeza que deseja calcular Posição de Contratos em TODOS os contratos?',
          calculateWithErrors:
            'Durante o cálculo foram identificados erros. Verificar Notificações para mensagens de erros.',
          calculateSuccessMsg:
            'Cálculo de Posição de Contratos realizado com sucesso!'
        },
        title: 'Posição de Contratos',
        contractDate: 'Data Contrato',
        cropYear: 'Safra',
        company: 'Companhia',
        counterPartyName: 'Counter Party Name',
        counterPartyRef: 'Counter Party Ref',
        producerName: 'Produtor',
        region: 'Região',
        type: 'Tipo',
        fixed: 'Fixado',
        toFix: 'A Fixar',
        mtm: 'MTM',
        price: 'Preço',
        currency: 'Moeda',
        state: 'UF',
        fas: 'FAS',
        contract: 'Contrato',
        quantity: 'Quantidade',
        quantityUnit: 'Unidade da Quantidade',
        takeUpAccepted: 'Take-Up (Aceito)',
        takeUpDealing: 'Take-Up (Negociando)',
        balance: 'Saldo',
        terms: 'Terms',
        controller: 'Controller',
        merchant: 'Merchant',
        jul: 'JUL',
        aug: 'AGO',
        sep: 'SET',
        oct: 'OUT',
        nov: 'NOV',
        dec: 'DEZ',
        jan: 'JAN',
        feb: 'FEV',
        mar: 'MAR',
        apr: 'ABR',
        may: 'MAI',
        jun: 'JUN',
        underOver: 'Under / Over'
      },
      logisticPortalDomesticMarket: {
        title: 'Mercado Doméstico (Programação)',
        chargingStatus: 'Status do Carregamento',
        unloadingStatus: 'Status da Descarga',
        cropYear: 'Safra',
        company: 'Companhia',
        purchaseCounterpartyCode:
          'Código da Contra Parte do Contrato de Compra',
        purchaseCounterpartyName: 'Nome da Contra Parte do Contrato de Compra',
        purchaseCounterpartyReference:
          'Referência da Contra Parte do Contrato de Compra',
        ukGin: 'Uk Gin',
        warehouse: 'Warehouse',
        target: 'Destino',
        purchaseContractReference: 'Contrato de Compra',
        lotReference: 'Referência do Lote',
        balesQuantiy: 'Quantidade de Fardos',
        netWeight: 'Peso Líquido',
        purchaseGrade: 'Purchase Grade',
        cargillGrade: 'Cargill Grade',
        purchaseContractPrice: 'Preço do Contrato de Compra',
        price: 'Preço',
        instruction: 'Marks',
        allocatedSale: 'Allocated Sale',
        planId: 'Plan Id',
        groupNumber: 'Group Number',
        part4: 'Part4',
        part5: 'Part5',
        purchaseInvoice: 'Purchase Invoice',
        saleInvoice: 'Sale Invoice',
        suppliersInvoiceReference: 'Suppliers Invoice Ref',
        saleFiscalNote: 'Nota Fiscal de Venda',
        buyer: 'Cliente',
        destination: 'Cidade de Destino',
        subType: 'SubType',
        purchaseCounterpartySector:
          'Setor da Contra Parte do Contrato de Compra',
        purchaseContractTerms: 'Purchase Contract Terms',
        saleContractTerms: 'Contract Terms',
        haulier: 'Transportadora',
        vehiclePlate: 'Placa do Veículo',
        releaseDate: 'Data de Liberação',
        requestedUnloadingDate: 'Data Solicitada da Descarga',
        expectedStartLoadDate: 'Data Mínima Esperada de Carregamento',
        expectedEndLoadDate: 'Data Máxima Esperada de Carregamento',
        farmArrivalDate: 'Data de Chegada na Algodoeira',
        loadDate: 'Data de Carregamento',
        arrivalMinimumDate: 'Data Mínima Esperada da Descarga',
        arrivalMaximumDate: 'Data Máxima Esperada da Descarga',
        expectedArrivalDate: 'Data Esperada da Descarga',
        arrivalDate: 'Data da Descarga',
        storageDate: 'Data da Armazenagem',
        vehicleLayover: 'Estadia',
        reason: 'Motivo',
        inactive: 'Inativo'
      },
      logisticPortalStorage: {
        title: 'Armazenagem (Puxinha)',
        cropYear: 'Safra',
        company: 'Companhia',
        chargingStatus: 'Status do Carregamento',
        unloadingStatus: 'Status da Descarga',
        releaseAllocation: 'Liberação Alocação',
        scheduleSend: 'Envio Programação',
        instruction: 'Marks',
        groupNumber: 'Group Number',
        planId: 'Plan Id',
        ukGin: 'Uk Gin',
        warehouse: 'Warehouse',
        target: 'Destino',
        purchaseContractReference: 'Contrato de Compra',
        lotReference: 'Referência do Lote',
        balesQuantiy: 'Quantidade de Fardos',
        netWeight: 'Peso Líquido',
        purchaseContractTerms: 'Purchase Contract Terms',
        purchaseCounterpartySector:
          'Setor da Contra Parte do Contrato de Compra',
        expectedStartLoadDate: 'Data Mínima Esperada de Carregamento',
        expectedEndLoadDate: 'Data Máxima Esperada de Carregamento',
        farmArrivalDate: 'Data de Chegada na Algodoeira',
        loadDate: 'Data de Carregamento',
        arrivalMinimumDate: 'Data Mínima Esperada da Descarga',
        arrivalMaximumDate: 'Data Máxima Esperada da Descarga',
        expectedArrivalDate: 'Data Esperada da Descarga',
        arrivalDate: 'Data da Descarga',
        bulkCompleteStatus: 'Status Bulk',
        vehicleLayover: 'Estadia',
        reason: 'Motivo',
        freeGroupsCompleteStatus: 'Status Free Groups',
        storageDate: 'Data da Armazenagem'
      },

      takeUpReturnsAndClaimsMaster: {
        title: 'Devoluções e Sinistro TakeUp',
        purchaseContractCompany: 'Companhia',
        contract: 'Contrato',
        purchaseContractCropYear: 'Safra',
        producer: 'Contraparte',
        producerName: 'Nome do Produtor',
        farm: 'Algodoeira',
        lotReference: 'Lote',
        currentQuantity: 'Quantidade Atual',
        allQuantity: 'Quantidade Total',
        purchaseContract: 'Contrato',
        company: 'Companhia',
        cropYear: 'Safra',
        saiCode: 'S.A.I. Code (Brz)',
        quantity: 'Quantidade',
        observation: 'Observação',
        registryDate: 'Registry Date',
        update: 'Data da Atualização',
        status: 'Status',
        type: 'Tipo',
        marks: 'MARKS',
        tranche: 'Tranche',
        takeUpReturnsAndClaims: {
          title: 'Devoluções e Sinistro Detalhe',
          id: 'Código',
          company: 'Companhia',
          contract: 'Contrato',
          cropYear: 'Safra',
          farm: 'Algodoeira',
          lotReference: 'Lote',
          saiCode: 'S.A.I. Code (Brz)',
          quantity: 'Quantidade',
          observation: 'Observação',
          registryDate: 'Data do Registro',
          update: 'Data da Atualização',
          status: 'Status',
          type: 'Tipo',
          marks: 'MARKS',
          tranche: 'Tranche',
        }
      }
    },
    constantes: {
      anoFiscal: 'Ano Fiscal',
      safra: 'Safra'
    },
    errors: {
      requiredEndPeriod: 'A data fim do período é obrigatória!',
      startDateLessEndDate:
        'A data de início deve ser menor que a data limite!',
      cropYearMax: 'Só é possível selecionar no máximo duas safras!',
      cropYearSubsequent: 'Só é possível selecionar safras subsequentes!',
      periodOutOfCrop: 'Período fora do intervalo das safras selecionadas!'
    },
    filters: {
      cleanFilter: 'Limpar filtro'
    },
    components: {
      chartIndicator: {
        effectiveSubtitle: 'E EFETIVO',
        offendingSubtitle: 'O OFENSOR'
      }
    },
    misc: {
      exportCsv: 'Exportar Csv',
      exportCsvImportInItas: 'Exportar Csv com dados para importar no ITAS'
    }
  }
}
