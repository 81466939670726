export default {
  application: {
    enums: {
      notificationEvent: {
        stuffingPortal: 'Stuffing Portal',
        takeUp: 'Take-Up',
        takeUpProducerMonitoring: 'Take-Up Portal - Producer Monitoring',
        takeUpLotReportInput: 'Take-Up Portal - Upload Take-Up',
        takeUpSchedules: 'Take-Up Portal - Schedules',
        takeUpPremiumDiscountManagement: 'Take-Up Portal - Premium/Discount',
        takeUpItasIntegration: 'Take-Up Portal - ITAS Integration',
        ibpMonthlyReview: 'IBP Monthly Review'
      },
      controlTowerNotificationEvent: {
        stuffingPortal: 'Stuffing Portal',
        takeUp: 'Take-Up',
        takeUpProducerMonitoring: 'Take-Up Portal - Producer Monitoring',
        takeUpLotReportInput: 'Take-Up Portal - Upload Take-Up',
        takeUpSchedules: 'Take-Up Portal - Schedules',
        takeUpPremiumDiscountManagement: 'Take-Up Portal - Premium/Discount',
        takeUpContractsPosition: 'Take-Up Portal - Position of Contracts',
        takeUpItasIntegration: 'Take-Up Portal - ITAS Integration',
        ibpMonthlyReview: 'IBP Monthly Review'
      },
      cockpitRecomendation: {
        replanCollect: 'Replan collect',
        replanStuffing: 'Replan stuffing',
        replanBoarding: 'Replan boarding',
        instructionSplit: 'Perform instruction split',
        evaluateResponsibleInstruction:
          'Evaluate with responsible for the instruction'
      },
      modalType: {
        road: 'Road',
        rail: 'Rail',
        waterway: 'Waterway'
      },
      logisticPortalStatusSemaphoreType: {
        red: 'Pending - Late',
        green: 'Finished',
        greenOnTime: 'Finished - On Time',
        greenLate: 'Finished - Com Atraso',
        greenFob: 'Finished - FOB',
        greyFob: 'Pending - FOB',
        orange: 'Pending - In Risk',
        grey: 'Pending - On Time',
        greyUndefined: '-'
      },
      stuffingPortalUnloadingType: {
        unloadingfinished: 'Finished',
        unloadingnotstarted: 'Not Started',
        unloadingstarted: 'Started'
      },
      stuffingPortalStatusSemaphoreBulkCompleteType: {
        red: 'Pending',
        green: 'Complete'
      },
      stuffingPortalStatusSemaphoreFreeGroupsCompleteType: {
        red: 'Pending',
        green: 'Complete'
      },
      stuffingPortalStatusSemaphoreFobControlInstructionType: {
        red: 'Evaluate LSD CAK / MAPA Inspection',
        green: 'Finished',
        orange: 'Container Withdrawal not started',
        grey: '-',
        yellow: 'Pending Loading'
      },
      stuffingPortalLoadingType: {
        loadingfinished: 'Finished',
        loadingnotstarted: 'Not Started',
        loadingstarted: 'Started'
      },
      unloadStatusType: {
        waitingunloading: 'Waiting unloading',
        unloaded: 'Unloaded',
        stuffed: 'Stuffed',
        waitingemptywithdrawal: 'Waiting container retreat'
      },
      logisticPortalVehicleLayoverStatus: {
        invalid: '-',
        notApplicable: 'Not Applicable',
        charging: 'Charging',
        unloading: 'Unloading',
        chargingAndUnloading: 'Charging And Unloading'
      },
      logisticPortalExportControlStatus: {
        pendingOnTime: 'Pending - On Time',
        pendingRisk: 'Pending - Risk',
        pendingOverdue: 'Pending - Overdue',
        doneOnTime: 'Done - On Time',
        doneWithDelay: 'Done - With delay'
      },
      logisticPortalExportControlAnalysis: {
        check: 'Check',
        ok: 'OK',
        fob: 'FOB'
      },
      logisticPortalExportControlCak: {
        fob: 'FOB',
        casa: 'CASA'
      },
      logisticPortalExportControlFreeTimeStatus: {
        undefined: '-',
        freeTimeExpired: 'Free Time Expired',
        freeTimeExpiring: 'Free Time Expiring'
      },
      logisticPortalExportControlFreeGroupsStatus: {
        pending: 'Pending',
        ok: 'OK'
      },
      stuffingPortalDocumentationType: {
        documentationwaitingstuffing: 'Waiting stuffing',
        documentationimportpermitpending:
          'Stuffing Started - Pending Import Permit',
        documentationwaitingmapinspection:
          'Stuffing Started - Waiting MAPA inspection',
        documentationwaitingstuffingfinalization:
          'Waiting for the completion of stuffing',
        documentationstuffingfinishedimportpermitpending:
          'Stuffing Finished - Pending Import Permit',
        documentationstuffingfinishedwaitingmapinspection:
          'Stuffing Finished - Waiting MAPA inspection',
        documentatonwaitingdraftsubmission: 'Waiting draft submission',
        documentationwaitingclearencedue: 'Waiting clearance DUE',
        documentationwaitingdeposit: 'Waiting deposit',
        documentationwaitingloading: 'Waiting loading',
        documentationloaded: 'Loaded'
      },
      stuffingPortalContainerWithdrawalType: {
        containerwithdrawalfinished: 'Finished',
        containerwithdrawalnotstarted: 'Not Started',
        containerwithdrawalstarted: 'Started'
      },
      stuffingPortalEmptyWithdrawalProblem: {
        absenceempty: 'Absence of empty',
        bookingcanceled: 'Booking canceled',
        absenceregistrationcarrier: 'Absence of registration in the carrier',
        awaitingwithdrawal: 'Awaiting withdrawal',
        others: 'Others'
      },
      stuffingPortalReserveType: {
        new: 'New',
        changed: 'Changed',
        containerwithdrawalfinished: 'Container Retreat - Finished',
        containerwithdrawalnotstarted: 'Container Retreat - Not Started',
        containerwithdrawalstarted: 'Container Retreat - Started',
        unloadingfinished: 'Unloading - Finished',
        unloadingnotstarted: 'Unloading  - Not started',
        unloadingstarted: 'Unloading - Started',
        loadingfinished: 'Loading - Finished',
        loadingnotstarted: 'Loading - Not Started',
        loadingstarted: 'Loading - Started',
        documentationwaitingstuffing: 'Documentation - Waiting stuffing',
        documentationimportpermitpending:
          'Documentation - Stuffing Started - Pending Import Permit',
        documentationwaitingmapinspection:
          'Documentation - Stuffing Started - Waiting MAPA inspection',
        documentationwaitingstuffingfinalization:
          'Documentation - Waiting for the completion of stuffing',
        documentationstuffingfinishedimportpermitpending:
          'Documentation - Stuffing Finished - Pending Import Permit',
        documentationstuffingfinishedwaitingmapinspection:
          'Documentation - Stuffing Finished - Waiting MAPA inspection',
        documentatonwaitingdraftsubmission:
          'Documentation - Waiting draft submission',
        documentationwaitingclearencedue:
          'Documentation - Waiting clearance DUE',
        documentationwaitingdeposit: 'Documentation - Waiting deposit',
        documentationwaitingloading: 'Documentation - Waiting loading',
        documentationloaded: 'Documentation - Loaded'
      },
      stuffingPortalChargingType: {
        chargingfinished: 'Finished',
        chargingnotstarted: 'Not started',
        chargingstarted: 'Started'
      },
      stuffingPortalImportPermitType: {
        importpermitok: 'OK',
        importpermitpending: 'Pending'
      },
      stuffingPortalFreightType: {
        freightok: 'OK',
        freightpending: 'Pending'
      },
      stuffingPortalOpenTextType: {
        opentextok: 'OK',
        opentextpending: 'Pending'
      },
      stuffingPortalStuffingType: {
        stuffingnotstarted: 'Not started',
        stuffingpartial: 'Partial',
        stuffingfinished: 'Finished'
      },
      stuffingPortalFobControlType: {
        fobcontrolwaitingcharging: 'Waiting charging',
        fobcontrolwaitingcontainerwithdrawal: 'Waiting Container Retreat',
        fobcontrolcontainerwithdrawalproblem: 'Container Retreat problem',
        fobcontrolwaitingstuffing: 'Waiting stuffing',
        fobcontrolwaitingweightworksheet: 'Waiting weight worksheet',
        fobcontrolwaitingmapinspection: 'Waiting MAPA inspection',
        fobcontrolnwaitingdraftsubmission: 'Waiting Draft submission',
        fobcontrolwaitingdeposit: 'Waiting deposit',
        fobcontrolwaitingloading: 'Waiting loading',
        fobcontrolloaded: 'Loaded'
      },
      stuffingPortalDocumentationStatus: {
        red: 'Pending',
        green: 'OK',
        grey: '-'
      },
      StuffingPortalStatusSemaphoreType: {
        RED: 'Pending',
        GREEN: 'OK',
        GREY: '-'
      },
      takeUpGreatnessType: {
        quality: 'Quality',
        contamination: 'Contamination',
        qualityAggregate: 'Quality - Aggregate'
      },
      comparisonTest: {
        gt: 'Greater than',
        lt: 'Less than',
        eq: 'Equal to'
      },
      evaluationCriteria: {
        min: 'Minimum',
        med: 'Average'
      },
      incoterm: {
        cfr: 'CFR',
        cif: 'CIF',
        casa: 'CASA',
        cip: 'CIP',
        exw: 'EXW',
        fob: 'FOB'
      },
      company: {
        br: 'BR',
        rg: 'RG'
      },
      takeUpLotStatus: {
        disapproved: 'DISAPPROVED',
        approved: 'APPROVED',
        inNegociation: 'IN NEGOCIATION'
      },
      brazilianStates: {
        ac: 'AC',
        al: 'AL',
        ap: 'AP',
        am: 'AM',
        ba: 'BA',
        ce: 'CE',
        df: 'DF',
        es: 'ES',
        go: 'GO',
        ma: 'MA',
        mt: 'MT',
        ms: 'MS',
        mg: 'MG',
        pa: 'PA',
        pb: 'PB',
        pr: 'PR',
        pe: 'PE',
        pi: 'PI',
        rj: 'RJ',
        rn: 'RN',
        rs: 'RS',
        ro: 'RO',
        rr: 'RR',
        sc: 'SC',
        sp: 'SP',
        se: 'SE',
        to: 'TO'
      },
      contaminationGreatness: {
        bark: 'BARK',
        grass: 'GRASS',
        scnSeed: 'SCN/SEED',
        fumaginaHon: 'FUMAGINA/HON',
        bidens: 'BIDENS'
      },
      gradeRating: {
        better: 'Better',
        low: 'Low'
      },
      offerStatus: {
        approved: 'Approved',
        disapproved: 'Disapproved',
        awaitingEvaluation: 'Awaiting Evaluation'
      },
      bookStatus: {
        notAvailable: 'Not Available',
        available: 'Available'
      },
      takeUpCalculationRuleType: {
        default: 'Default',
        smaller: 'Smaller'
      },
      takeUpContractMarketplace: {
        domestic: 'Domestic',
        export: 'Export'
      },
      takeUpPeriod: {
        morning: 'Morning',
        afternoon: 'Afternoon',
        night: 'Night'
      },
      takeUpProducerMonitoringStatus: {
        notStarted: 'Not started',
        reaping: 'Reaping',
        benefiting: 'Benefiting',
        waitingForHvi: 'Waiting for HVI',
        partialHvi: 'Partial HVI',
        hviCompleted: 'HVI Completed',
        takeUpAvailable: 'Take-up available',
        notApplicable: 'Not applicable'
      },
      takeUpScheduleLotStatus: {
        notStarted: 'Not Started',
        approved: 'Approved',
        rejected: 'Rejected',
        awaitingCargillReview: 'Awaiting Cargill Review',
        hviStandby: 'HVI - STANDBY',
        takeUpStandby: 'TAKE UP - STANDBY',
        awaitingProducerResponse: 'Awaiting Producer Response',
        committee: 'Committee',
        cargillDecisionPdWithoutHvi: 'Cargill Decision P/D without HVI',
        producerAgreementWithoutHvi: 'Producer Agreement without HVI',
        cargillDecisionPdWithoutTakeUp: 'Cargill Decision P/D without Take Up',
        producerAgreementWithoutTakeUp: 'Producer Agreement without Take Up',
        takeUpProducerAgreementWithoutCargillDecision:
          'Take Up Producer Agreement without Cargill Decision',
        hviProducerAgreementWithoutCargillDecision:
          'HVI Producer Agreement without Cargill Decision',
        check: 'Check'
      },
      takeUpLotDivergenceStatus: {
        ok: 'OK',
        divergent: 'Divergent'
      },
      takeUpNegotiationStatus: {
        approved: 'Approved',
        disapproved: 'Disapproved',
        inNegociation: 'In negociation'
      },
      takeUpStatusHvi: {
        ok: 'OK',
        pending: 'Pending'
      },
      takeUpUsBucketContaminationOperator: {
        equal: 'Equal',
        notEqual: 'Not equal'
      },
      takeUpUsBucketGradeOperator: {
        equal: 'Equal',
        notEqual: 'Not equal'
      },
      takeUpUsBucketCalculatorTriggerType: {
        manual: 'Manual',
        uploadTakeUp: 'Upload Take-Up'
      },
      takeUpAccomplishedStatus: {
        accomplished: 'Accomplished',
        pending: 'Pending'
      },
      takeUpHviTemplate: {
        brain: 'Template Brain',
        cargill: 'Template Cargill',
        abapa: 'Template ABAPA',
        bf: 'Template BF',
        bj: 'Template BJ',
        scheffer: 'Template SCHEFFER',
        amaggi: 'Template AMAGGI'
      },
      takeUpMarketType: {
        exp: 'EXP',
        domb: 'DOMB',
        tbd: 'TO BE DEFINED'
      },
      takeUpPremiumDiscountAgreement: {
        yes: 'Yes',
        no: 'No',
        underProducerReview: 'Under Producer Review'
      },
      takeUpPremiumDiscountCargillDecision: {
        approved: 'Approved',
        rejected: 'Rejected',
        underCargillReview: 'Under Cargill Review'
      },
      takeUpPremiumDiscountDecision: {
        approved: 'Approved',
        rejected: 'Rejected',
        underReview: 'Under Review'
      },
      takeUpContractsPositionMtm: {
        receiveTheMajor: 'Receive the Major',
        receiveTheMinor: 'Receive the Minor'
      },
      takeUpContractsPositionType: {
        recap: 'RECAP',
        standard: 'STANDARD'
      },
      ibpMonthlyReviewDashboardView: {
        default: 'Consolidated',
        usBucket: 'US Bucket',
        subRegional: 'Sub-Regional'
      },
      ibpMonthlyReviewDashboardSeries: {
        executed: 'Executed',
        planned: 'Planned',
        unrestricted: 'Unrestricted',
        sales: 'Sales',
        origination: 'Origination',
        takeUpImport: 'Take-Up Import',
        purchase: 'Purchase',
        gamePlan: 'Game Plan',
        purchased: 'Purchased',
        imported: 'Imported',
        invoiced: 'Invoiced',
        exported: 'Exported',
        programmed: 'Programmed',
        originationGamePlan: 'Origination GP',
        originalGamePlan: 'Original GP',
        attendedGamePlan: 'Attended GP',
        attendance: 'Attended',
        sold: 'Sold'
      },
      takeUpPremiumDiscountApplicationStatus: {
        pending: 'Pending',
        ok: 'OK',
        divergent: 'Divergent'
      },
      takeUpUsBucketIntrinsicComparationRule: {
        interval: 'Interval',
        greaterEqual: 'Greater or equal',
        lessEqual: 'Less ou equal'
      },
      takeUpImportsPreparationImportInItas: {
        yes: 'Yes',
        no: 'No',
        divergent: 'Divergent',
        invalid: 'Invalid'
      },
      takeUpClaimsType: {
        return: 'Retrun',
        insuranceClaim: 'insurance Claim',
        others: 'Others'
      },
      takeUpStatusReturnsAndClaims: {
        automatic: 'Automatic',
        pending: 'Pending',
        approved: 'Approved',
        refused: 'Refused'
      }
    },
    misc: {
      details: 'Details',
      filter: 'Filter',
      selectTemplate: 'Select Template',
      exportTemplate: 'Export Template',
      applyToAll: 'Apply to All',
      notPublished:
        'There is no published scenario, nor is there any publication in progress.',
      publishing:
        'There is a scenario publication in progress. A notification will be sent when publishing is complete.',
      alertMessage:
        'Você tem alguns Alertas a ser observada, Deseja continuar a Alteração mesmo assim?'
    }
  },
  controlTower: {
    pages: {
      notificationMessages: {
        teste: 'This is a test with two args: {0} and {1}',
        emptyWithdrawalProblemMessage: '{0} ({1}): Container Retreat Issue',
        draftDeadlineNotRealizedMessage:
          'Analyst {0} ({1}): Draft not submitted',
        instructionNotLoadedMessage:
          'Analyst {0} ({1}): Instruction not loaded',
        loadingDeadLineIsCommingMessage:
          'Analyst {0} ({1}): Delayed Terminal Scheduling',
        loadingNotStartedMessage: 'Analyst {0} ({1}): Loading not started',
        loadingStartedButNoContainerWithdrawalMessage:
          'Analyst {0} ({1}): Loading started and no Container Retreat',
        newCommentCargillMessage: 'Analyst {0} ({1}): Cargill left a comment',
        newCommentTerminalMessage:
          'Analyst {0} ({1}): Terminal {2} left a comment',
        newCommentTakeUpCargillMessage:
          'Analyst {0} ({1}): Cargill {2} left a comment',
        newCommentTakeUpClassifierMessage:
          'Analyst {0} ({1}): Classifier {2} left a comment',
        unloadingNotStartedMessage: 'Analyst {0} ({1}): Unloading not started',
        newNotificationTakeUpSchedulesMessage:
          'The Take-Up of lot {0} of producer {1} of contract {2} was defined as Not Approved.',
        takeUpPremiumDiscountManagementLotContractChange:
          'Crop Year/Lot {0} was changed from contract {1} to contract {2}.',
        takeUpPremiumDiscountManagementHviDisapprovedByCargill:
          'The HVI of lot {0} of contract {1} was rejected by Cargill.',
        takeUpPremiumDiscountManagementTakeUpDisapprovedByCargill:
          'The Take Up of lot {0} of contract {1} was rejected by Cargill.',
        ibpMonthlyReviewScenarioOfficializationError:
          'The data for the official scenario "{0}" could not be published in IBP Monthly Review. Contact your system administrator.',
        ibpMonthlyReviewScenarioOfficializationSuccessfullyCanceled:
          'The cancellation of the official scenario "{0}" was successfully executed in IBP Monthly Review.',
        ibpMonthlyReviewItasIntegrationError:
          'The officialization of the scenario "{0}"  was executed successfully! However, it was not possible to integrate the ITAS data. Please run the "ITAS - IBP Monthly Review" integration flow manually from the Control Tower Integration screen',
        ibpMonthlyReviewScenarioOfficializationSuccessfully:
          'The officialization of the scenario "{0}" and the integration of ITAS data were successfully executed in the  IBP Monthly Review.',
        application: {
          pages: {
            takeUpPremiumDiscountCalculator: {
              errors: {
                noReferenceGradeDefinition:
                  'The TCG to calculate the Grade Premium/Discount for lot {0} of contract {1} was not identified.',
                thereIsNoQualityTranche:
                  'No quality tranches of contract {0} were identified to calculate the Grade Premium/Discount of lot {1}.',
                thereIsNoQualityTrancheForGrade:
                  'There is no quality tranche for TCG {0} in contract {1} of lot {2} to calculate HVI/Grade Premium/Discount.',
                thereIsNoQualityTrancheForIntrinsicCharacteristics:
                  'The HVI of the lot {0} does not respect the rules of the quality tranche of the contract {1} for the TCG {2} to calculate HVI/Grade Premium/Discount.',
                noReferenceTableContamination:
                  'The contamination reference table for lot {0} of contract {1} has not been defined.',
                unableCalculateContaminationPremiumDiscountBark:
                  'Unable to calculate the Contamination Premium/Discount related to the Bark of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateContaminationPremiumDiscountBidens:
                  'Unable to calculate Contamination Premium/Discount related to Bidens of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateContaminationPremiumDiscountFumagHone:
                  'Unable to calculate Contamination Premium/Discount related to Fumag/Hone of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateContaminationPremiumDiscountGrass:
                  'Unable to calculate Contamination Premium/Discount related to Grass of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateContaminationPremiumDiscountScnSeed:
                  'Unable to calculate Contamination Premium/Discount related to the SCN/SEED of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateIntrinsicPremiumDiscountLength:
                  'Unable to calculate HVI Premium/Discount related to UHML [mm] of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateIntrinsicPremiumDiscountStrength:
                  'Unable to calculate HVI Premium/Discount related to GPT [G/tex] of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateIntrinsicPremiumDiscountMic:
                  'Unable to calculate HVI Premium/Discount related to Mic of lot {0} of contract {1}, in the reference table {2}.',
                unableCalculateIntrinsicPremiumDiscountSfi:
                  'Unable to calculate SFI-related HVI Premium/Discounto of lot {0} of contract {1}, in the reference table {2}.',
                thereIsNoRuleForGradeInReferenceTable:
                  'There is no rule for the type {0} in the contract {1} of lot {2} to calculate the Grade Premium/Discount, in the reference table {3}.',
                contractualOptionNotRegistered:
                  'Contractual option not registered fot type {0} in the contract {1} of lot {2} to calculate the Grade Premium/Discount.',
                noHviInputReferenceTable:
                  'No HVI uploaded on lot {0} of contract {1}. Unable to calculate HVI Premium/Discount with reference table.',
                noHviInputContractBase:
                  'No HVI uploaded on lot {0} of contract {1}. Unable to calculate contract based HVI Premium/Discount.',
                noTakeUpInputContamination:
                  'No Take-Up uploaded on lot {0} of contract {1}. Unable to calculate Contamination Premium/Discount.',
                noTakeUpInputGrade:
                  'No Take-Up uploaded on lot {0} of contract {1}. Unable to calculate Grade Premium/Discount.',
                noHeaderContract: 'The contract header {0} was not found.',
                noOfferReferenceGrade:
                  'Grade has not been registered for lot {0} of offer {1} and there is no Take-Up. Unable to calculate HVI/Grade Premium/Discount.',
                offerWithoutReferenceContractOrReferenceTable:
                  'Lot {0} of offer {1} without definition of reference contract/reference table. Unable to calculate HVI/Grade Premium/Discount.',
                thereIsFinalDecisionForPd:
                  'Unable to calculate Premium/Discount of lot {0} of contract {1}. There is final decision for P/D HVI and Take-Up.',
                thereIsFinalDecisionForPdHvi:
                  'Unable to calculate HVI Premium/Discount of lot {0} of contract {1}. There is final decision for P/D HVI.',
                thereIsFinalDecisionForPdTakeUp:
                  'Unable to calculate Grade/Contamination Premium/Discount of lot {0} of contract {1}. There is final decision for P/D Take-Up.',
                invalidbasetypecontract:
                  'Purchase Contract with base type (tranche) not registered. Contract: {0}. Type {1}.'
              }
            },
            takeUpContractsPositionCalculator: {
              errors: {
                headerContractWasNotCreated:
                  'The header contract was not created by the contracts integration! Contracts with this issue: {0}.',
                thereIsNoUnitConversion:
                  'There is no unit conversion between unit {0} ({1}) and unit {2} ({3}).'
              }
            },
            takeUpPurchaseContractItas: {
              errors: {
                producerChangeError:
                  'Contract producer {0} has been modified in ITAS. Take-Up data for this contract is INVALID. Former producer: {1}. Current producer: {2}.'
              }
            }
          }
        }
      },
      notificationUser: {
        title: 'Notifications'
      },
      controlTowerNotification: {
        title: 'Notifications',
        message: 'Message',
        notificationDate: 'Notification Date',
        notificationEvent: 'Event',
        notificationModule: 'Module',
        notificationType: 'Type',
        readDate: 'Read in',
        isRead: 'Read'
      },
      ibpMonthlyReviewExecutedExport: {
        title: 'IBP Monthly Review - Executed Export'
      },
      ibpMonthlyReviewExecutedDomesticMarket: {
        title: 'IBP Monthly Review - Executed Domestic Market'
      },
      ibpMonthlyReviewExecutedOrigination: {
        title: 'IBP Monthly Review - Executed Origination'
      },
      ibpMonthlyReviewExecutedCarrierUtilization: {
        title: 'IBP Monthly Review - Executed Carrier Utilization'
      },
      stuffingPortalInstruction: {
        title: 'Instruction'
      },
      stuffingPortalCompaniesUsers: {
        title: 'Users'
      },
      stuffingPortalContainersWithdrawal: {
        title: 'Container Retreat'
      },
      stuffingPortalContainersWithdrawalContainer: {
        title: 'Withdrawal Container'
      },
      stuffingPortalDocumentation: {
        title: 'Documentation'
      },
      stuffingPortalDocumentationRequest: {
        title: 'Documentation Request'
      },
      stuffingPortalDocumentationBatch: {
        title: 'Batch'
      },
      stuffingPortalLoading: {
        title: 'Shipment'
      },
      stuffingPortalLoadingContainer: {
        title: 'Loading Container'
      },
      stuffingPortalReserve: {
        title: 'Reservation'
      },
      stuffingPortalReserveRequest: {
        title: 'Requisição de Reserva'
      },
      stuffingPortalUnloading: {
        title: 'Unloading'
      },
      stuffingPortalUnloadingBatch: {
        title: 'Batch'
      },
      stuffingPortalUnloadingDriver: {
        title: 'Driver'
      },
      logisticPortalExportControl: {
        title: 'CASA + FOB Control'
      },
      logisticPortalExportControlInventory: {
        title: 'CASA + FOB Control - Inventory'
      },
      inventoryItas: {
        title: 'ITAS - Inventory'
      },
      takeUpInventoryItas: {
        title: 'Take-Up - ITAS - Inventory'
      },
      purchasesAndSalesItas: {
        title: 'ITAS - Contract (Purchase/Sale)'
      },
      bookingItas: {
        title: 'ITAS - Booking'
      },
      openContractItas: {
        title: 'ITAS - Open Contract'
      },
      stuffingPortalCompanies: {
        title: 'Companies',
        code: 'Code',
        name: 'Name',
        fullAccess: 'Full Access',
        stuffingPortalCompaniesUsers: {
          title: 'Users',
          user: 'User',
          company: 'Company'
        }
      },
      shipmentItasDto: {
        title: 'Shipment - ITAS'
      },
      logisticPortal: {
        title: 'Logistic Portal'
      },
      stuffingPortal: {
        title: 'Export (Port)',
        export: 'Export',
        transferContainers: 'Container Transfer',
        transferOfContainers: 'Container Transfer',
        import: 'Import Data',
        viewData: 'View Data',
        reserve: 'Reservation',
        containersWithdrawal: 'Container Retreat',
        unload: 'Unloading',
        boarding: 'Shipment',
        documentation: 'Documentation',
        period: 'Period',
        comments: 'Comments',
        leaveComment: 'Leave a Commentary',
        deleteSuccess: 'Deletion Sucessfull',
        deleteError: 'Deletion failed',
        commentSendSuccess: 'Comment Successfully Sended',
        commentSendError: 'Error Sending Comment',
        emptyCommentError: 'Error: Empty Comment',
        control: 'CASA + FOB Control',
        errors: {
          dateNotGreatherOrEqualToday:
            'Date must be greather or equal than today',
          dateGreatherAndNotEqualToday: 'Date must be less or equal than today'
        },
        reserveTable: {
          title: 'Reservation',
          stuffingPortalReserve: {
            update: 'Update Reserve',
            registryDate: 'Registry Date',
            instruction: 'Instruction',
            registryStatus: 'Registry Status',
            stuffingTerminal: 'Stuffing Terminal',
            draftDeadline: 'Draft DDL',
            loadDeadline: 'Load DDL',
            containersQuantity: 'Containers Quantity',
            startUnloadWindow: 'Start Unload Window',
            endUnloadWindow: 'End Unload Window',
            cadency: 'Cadency',
            terminalAproval: 'Terminal Approval',
            awareChanges: 'Aware of Changes',
            originColectDate: 'Origin Colect Date',
            actions: 'Actions',
            lastStatusUpdateDate: 'Last Update Date',
            incoterm: 'Incoterm',
            partialAllowed: 'Partial Allowed',
            cropYear: 'Crop Year',
            statusItas: 'Status ITAS',
            incotermDetail: 'Incoterm Detail',
            balesQuantity: 'Bales Quantity',
            cakLsd: 'CAK LSD',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK',
            destinationPort: 'Destination Port',
            freightForwarder: 'Freight FRWD',
            netWeight: 'Net Weight (KG)',
            logisticResponsible: 'Resp Crgl Log:',
            weightController: 'Weight Controller',
            fumigation: 'Fumigation',
            forwardingAgent: 'Forwarding Agent',
            booking: 'Booking',
            ets: 'Ets',
            carrier: 'Carrier',
            travel: 'Travel',
            ship: 'Ship',
            destinationCountry: 'Destiny Country'
          }
        },
        containersWithdrawalTable: {
          title: 'Container Retreat',
          errors: {
            invalidWithdrawalDate:
              'InvalidWithdrawalDate must be less or equal of today',
            invalidTare: 'InvalidTare must be greather than 0'
          },
          stuffingPortalContainersWithdrawal: {
            update: 'Update Container',
            containerWithdrawal: 'Container Retreat',
            registryDate: 'Registry Date',
            instruction: 'Instruction',
            containerWithdrawalStatus: 'Container Retreat Status',
            emptyWithdrawalProblem: 'Container Retreat Issue',
            actions: 'Actions',
            stuffingTerminal: 'Stuffing Terminal',
            lastStatusUpdateDate: 'Last Update Date',
            incotermDetail: 'Incoterm Detail',
            cropYear: 'Crop Year',
            statusItas: 'Status ITAS',
            containersQuantity: 'Requested Cntrs',
            containersRemovedQuantity: 'Removed Cntrs',
            booking: 'Booking',
            carrier: 'Carrier',
            logisticResponsible: 'Resp Crgl Log:'
          },
          stuffingPortalContainersWithdrawalContainer: {
            addwithdrawalcontainer: 'Add Container Retreat',
            update: 'Update Container Retreat',
            containerCode: 'Container Number',
            tare: 'Tare (KG)',
            seal: 'Seal',
            withdrawalDate: 'Withdrawal Date',
            actions: 'Actions',
            oppositeMargin: 'Same Margin?'
          }
        },
        unloadTable: {
          title: 'Unloading',
          errors: {
            invalidStartDate: 'StartDate must be greather than today',
            invalidEndDate: 'InvalidEndDate must be greather than today',
            invalidRequestedDate:
              'InvalidRequestedDate must be greather than today',
            invalidScheduledDate:
              'InvalidScheduledDate must be greather than today',
            invalidVehiclePresentingDate:
              'InvalidVehiclePresentingDate must be greather than today',
            invalidUnloadDate: 'InvalidUnloadDate must be greather than today',
            invalidExpeditionDate:
              'InvalidExpeditionDate must be less or equal of today'
          },
          stuffingPortalUnloading: {
            adddriver: 'Add Driver',
            update: 'Atualizar Unloading',
            updatedriver: 'Update Driver',
            registryDate: 'Registry date',
            instruction: 'Instruction',
            stuffingTerminal: 'Stuffing Terminal',
            deadlineDraft: 'Draft DDL',
            instructionStatus: 'Unloading Status',
            actions: 'Actions',
            lastStatusUpdateDate: 'Last Update Date',
            cropYear: 'Crop Year',
            statusItas: 'Status ITAS',
            booking: 'Booking',
            logisticResponsible: 'Resp Crgl Log:',
            loadDeadline: 'Load DDL'
          },
          stuffingPortalUnloadingDriver: {
            adddriver: 'Add Driver',
            addbatch: 'Add Batch',
            update: 'Update Driver',
            breakdown: 'Breakdown',
            sinister: 'Sinister',
            startDate: 'Arrival Minimum Date',
            endDate: 'Arrival Maximum Date',
            requestedDate: 'Requested Date',
            scheduledDate: 'Scheduled Date',
            vehiclePresentingDate: 'Vehicle Presenting Date',
            unloadDate: 'Unload Date',
            billingTicket: 'Billing Ticket',
            expeditionDate: 'Expedition',
            firstWeight: 'First Weight (KG)',
            secondWeight: 'Second Weight (KG)',
            netWeight: 'Net Weight (KG)',
            actions: 'Actions',
            shippingCompany: 'Shipping Company',
            horsePlate: 'Licence plate',
            originLoadingDate: 'Origin Loading date',
            groupNumber: 'Group Number',
            planId: 'PlanId',
            purchaseContractReference: 'Purchase Contract',
            productFiscalNumber: 'Producer Invoice',
            remittanceFiscalNumber: 'Remittance Fiscal Number',
            remittanceFiscalNumberState: 'Remittance Fiscal Number State',
            batch: 'Batch',
            originBalesQuantityUnloaded: 'Origin Bales Quantity Loaded',
            balesQuantityUnloaded: 'Bales Quantity Unloaded',
            balesBalance: 'Bales Balance',
            bulkCompleteStatus: 'Bulk Status',
            freeGroupsCompleteStatus: 'Free Groups Status',
            expectedEndLoadDate: 'Expected Start Load Date',
            expectedStartLoadDate: 'Expected End Load Date',
            purchaseContractIncoterm: 'Incoterm',
            warehouse: 'Warehouse'
          }
        },
        boardingTable: {
          title: 'Shipment',
          stuffingPortalLoading: {
            update: 'Update Loading',
            stuffingTerminal: 'Stuffing Terminal',
            registryDate: 'Registry Date',
            instruction: 'Instruction',
            draftDeadline: 'Draft DDL',
            loadDeadline: 'Load DDL',
            booking: 'Booking',
            ets: 'ETS',
            dueNumber: 'Due Number',
            exportNFNumber: 'Export Fiscal Number',
            instructionStatus: 'Stuffing Status',
            loadingStatus: 'Deposit status',
            actions: 'Actions',
            lastStatusUpdateDate: 'Last Update Date',
            incotermDetail: 'Incoterm Detalhe',
            cropYear: 'Crop Year',
            statusItas: 'Status ITAS',
            openText: 'OpenText',
            freight: 'Freight',
            logisticResponsible: 'Resp Crgl Log:',
            ship: 'Ship',
            stuffedContainers: 'Stuffed Containers'
          },
          stuffingPortalLoadingContainer: {
            update: 'Update Loading Container',
            containerCode: 'Container Number',
            loadingTerminalSchedule: 'Boarding terminal schedule',
            windowPending: 'Window Pending',
            depositDate: 'Deposit Date',
            carrierContractedFreeTime: 'Carrier Contracted Free Time',
            carrierRemainingFreeTime: 'Carrier Remaining Free Time',
            stuffingTerminalContractedFreeTime:
              'Stuffing Terminal Contracted Free Time',
            stuffingTerminalRemainingFreeTime:
              'Stuffing Terminal Remaining Free Time',
            actions: 'Actions',
            bales: 'Stuffed Bales',
            stuffingStatus: 'Stuffing Status',
            stuffingDate: 'Stuffing Date'
          }
        },
        documentationTable: {
          title: 'Documentation',
          stuffingPortalDocumentation: {
            update: 'Update Documentation',
            adddocumentation: 'Add Documentation',
            registryDate: 'Registry Date',
            instruction: 'Instruction',
            stuffingTerminal: 'Stuffing Terminal',
            status: 'Status',
            weightSupervisor: 'Weight supervisor',
            fumigation: 'Fumigation',
            forwardingAgent: 'Forwarding Agent',
            actions: 'Actions',
            container: 'Container',
            realizationMapDate: 'Map Realization',
            mapInspectionStatus: 'Map Inspection Status',
            mapFumigationStatus: 'Fumigation Status',
            fumigationDate: 'Fumigation Date',
            draftDeadlineDate: 'Draft DDL',
            draftDeadlineRealizationDate: 'Draft DDL Realization Date',
            vgmDeadlineDate: 'VGM DDL',
            loadDeadlineDate: 'Load DDL Date',
            vgmDate: 'VGM Date',
            vgmSubmissionDate: 'VGM Submission',
            dueClearanceDate: 'Due Clearance',
            dueNumber: 'Due Number',
            exportationFiscalNumber: 'Export Fiscal Number',
            loadingDate: 'Load Date (BL)',
            scrolling: 'Scrolling',
            scrollingReason: 'Scrolling Reason',
            checkweigherSealNumber: 'VGM',
            lastStatusUpdateDate: 'Last Update Date',
            deadlineSubmitted: 'DDL Submited',
            incoterm: 'Incoterm',
            approvalWeightWorksheetDate: 'Weight Worksheet Approval',
            partialAllowed: 'Partial Allowed',
            cropYear: 'Crop Year',
            statusItas: 'Status ITAS',
            incotermDetail: 'Incoterm Detail',
            importPermit: 'Import Permit',
            logisticResponsible: 'Resp Crgl Log:',
            documentation: 'Resp Crgl Doc:',
            cakLsd: 'CAK LSD',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK'
          },
          stuffingPortalDocumentationRequest: {
            checkweigherSealNumber: 'VGM',
            adddocumentationrequest: 'Add Documentation Request',
            update: 'Update Documentation Request',
            container: 'Container',
            seal: 'Seal',
            batch: 'Batch',
            balesQuantity: 'Bales Quantity',
            fiscalNumber: 'Fiscal Number',
            grossWeight: 'Gross Weight',
            tareCargo: 'Tare',
            netWeight: 'Net Weight',
            average: 'Average',
            m3: 'M3',
            density: 'Density',
            actions: 'Actions'
          },
          stuffingPortalDocumentationBatch: {
            adddocumentationbatch: 'Add Batch',
            update: 'Update Batch',
            batch: 'Batch',
            balesQuantity: 'Bales Quantity',
            fiscalNumber: 'NF',
            actions: 'Actions'
          }
        },
        controlTable: {
          title: 'CASA + FOB Control',
          logisticPortalExportControl: {
            title: 'CASA + FOB Control',
            registryDate: 'Registry Date',
            cak: 'CAK',
            instruction: 'Instruction',
            cropYear: 'Crop Year',
            instructionCode: 'Instruction',
            statusItas: 'Status ITAS',
            logisticResponsible: 'Resp Crgl Log:',
            status: 'Status',
            instructionStatus: 'Instruction Status',
            instructionStatusExcel: 'Instruction Status',
            documentation: 'Resp Crgl Doc:',
            loadStatus: 'Load Status',
            unloadStatus: 'Unload Status',
            quantity: 'Quantity (Tons)',
            balesQuantity: 'Bales Quantity',
            loadedBalesQuantity: 'Loaded Bales Quantity',
            booking: 'Booking',
            containersQuantity: 'Containers Quantity',
            ship: 'Vessel',
            travel: 'Travel',
            ets: 'ETS',
            draftDeadline: 'Draft DDL',
            loadDeadline: 'Load DDL',
            destinationCountry: 'Destination Country',
            destinationPort: 'Destination Port',
            carrier: 'Carrier',
            freightForwarder: 'Freight FRWD',
            originPort: 'Origin Port',
            departureTerminal: 'Departure Terminal',
            chargingStatus: 'Charging',
            containerWithdrawalStatus: 'Container Retreat',
            stuffingTerminal: 'Stuffing Terminal',
            qtyContainerRetreat: 'Qty Container Retreat',
            emptyWithdrawalProblem: 'Container Retreat Issue',
            firstDateContainerRetreat: '1st Date Container Retreat',
            firstStuffingDate: '1st Stuffing Date',
            endFreeTimeDetention: 'End (Free Time Detention)',
            statusFreeTimeDetention: 'Status (Free Time Detention)',
            endFreeTimeStorage: 'End (Free Time Storage)',
            statusFreeTimeStorage: 'Status (Free Time Storage)',
            stuffedContainers: 'Stuffed Containers',
            mtFinishedStuffing: 'MT Finished Stuffing',
            percentageFinishedStuffing: '% Finished Stuffing',
            approvalWeightWorksheetDate: 'Weight Worksheet Approval',
            updateInquiryOrFreeGroups: 'Update Inquiry / Free Groups',
            realizationMapDate: 'Realization Map Date',
            fumigation: 'Fumigation',
            draftDeadlineRealizationDate: 'Draft DDL Realization Date',
            due: 'DUE',
            containerDepositDate: 'Container Deposit Date',
            loadingDate: 'Loading Date (BL)',
            splitOrRoll: 'Split or Roll',
            reasonForRolling: 'Reason for Rolling',
            delayOrProblem: 'Delay/Problem',
            totalHcUsageDays: 'Total HC Usage (Days)',
            freetimeDetention: 'Freetime Detention',
            detentionAnalysis: 'Detention Analysis',
            freetimeTerminalStuffing: 'Freetime Terminal Stuffing',
            terminalStuffingAnalysis: 'Terminal Stuffing Analysis',
            freetimeDepartureTerminal: 'Freetime Departure Terminal',
            departureTerminalAnalysis: 'Departure Terminal Analysis',
            oppositeMargin: 'Opposite Margin',
            cakLsd: 'CAK LSD',
            partialAllowed: 'Partial Allowed',
            isVipOrPnlOrRisk: 'VIP/PNL/RISK',
            supplier: 'Supplier',
            freight: 'Freight',
            openText: 'OpenText',
            lastStatusUpdateDate: 'Last Status Update Date',
            lastBookingItasUpdatedDate: 'Last Booking Itas Updated Date',
            lastShipmentItasUpdatedDate: 'Last Shipment Itas Updated Date',
            actions: 'Actions',
            update: 'Update FOB Control',
            weightSupervisor: 'Weight Supervisor',
            observation: 'Observation'
          }
        }
      },
      cockpitExecution: {
        title: 'Cockpit',
        cleanFilter: 'Clean',
        exportDetails: 'Export full report',
        lastUpdate: 'Last Update:',
        harvest: 'Harvest',
        fiscalYear: 'Fiscal Year',
        weeklyPlannedVolume: 'Weekly Planned Volume',
        weeklyPlannedInstructions: 'Weekly Planned Instructions',
        roadFreight: 'Road Freight',
        instructionStatus: 'Instruction Status',
        loading: 'Loading',
        stuffing: 'Stuffing',
        boarding: 'Boarding',
        contractBoogey: 'Contract/Boogey',
        hired: 'Hired',
        boogey: 'Boogey',
        lost: 'Lost',
        late: 'Late',
        risky: 'Risky',
        biInTime: 'Bi in Time',
        planned: 'Planned',
        fulfilled: 'Fulfilled',
        accumulatedLoadingVolume: 'Accumulated Loading Volume',
        accumulatedStuffingVolume: 'Accumulated Stuffing Volume',
        accumulatedBoardingVolume: 'Accumulated Boarding Volume',
        cargoLoading: 'Loading',
        gerencialInformations: 'Gerencial Informations',
        instructionStatusMultiple: 'Instructions Status',
        currentWeek: 'Current Week (W0)',
        loadingTitle: 'Loading (Farm)',
        stuffingTitle: 'Stuffing (Stuffing Terminal)',
        stuffingTerminal: 'Stuffing (Stuffing Terminal)',
        weightSupervisor: 'Weight Supervisor',
        boardingTitle: 'Boarding (Boarding Terminal)',
        accumulatedFulfilledDemand: 'Accumulated Fulfilled Demand',
        warning: 'Warning',
        error: 'Error',
        information: 'Information',
        externalMarket: 'External Market (EM)',
        internalMarket: 'Internal Market (IM)',
        market: 'Market',
        unityMeasure: 'Unity Measure',
        zoomLevel:
          'The resolution scale for this screen is above 100% and may result in layout breaking',
        instructionsDetailing: 'Instructions Detailing',
        instructionsLegend: 'Legend',
        table: {
          cockpitInstructionDetailsMasterMetaData: {
            instructionCode: 'Instruction',
            destinyCountry: 'Destiny Country',
            modality: 'Modality',
            contractVolumeKg: 'Contract Volume (KG)',
            loading: 'Loading',
            stuffing: 'Stuffing',
            boarding: 'Boarding',
            deadline: 'Deadline',
            instruction: 'Instruction',
            lateBoarding: 'Late Boarding',
            recomendation: 'Recommendation regarding load delay',
            incoterm: 'Incoterm',
            instructionVolume: 'Total Instruction Volume (KG)',
            instructionStatus: 'Instruction Load Status',
            stuffingStatus: 'Instruction Stuffing Status',
            boardingStatus: 'Boarding Status',
            deadLineDate: 'Cargo Deadline (ME) / Delivery Client (MI)',
            concludedStatus: '% Instruction Complete',
            // instruction: 'Instruction',
            loadingStatus: 'Instruction Loading Status',
            destinyContry: 'Destination Country',
            // modality: 'Modality',
            contractKgVolume: 'Vol. Contract.(kg)',
            planID: 'Plan ID',
            planId: 'Plan ID',
            cottonOrigin: 'Origin Cotton Tree',
            shippingCompany: 'Carrier',
            originCity: 'Origin City',
            destinyCity: 'Destiny City',
            contractBalesVolume: 'Contract Volume(Bales)',
            port: 'Port',
            carrier: 'Shipowner',
            booking: 'Booking',
            ship: 'Ship',

            effectiveLoadingStartDate:
              'Effective start of Load. at Algodoeira (Data Prog.)',
            effectiveUnLoadingEndDate: 'Loading End (Effective Date)',
            effectiveLoadingProgramedDate: 'End of Loading (Scheduled Date)',
            loadingBalesVolume: 'Loaded Volume (Bales)',
            balesBalance: 'Bales (Bales)',
            terminalPrecisionArrivalDate:
              'Arrival accuracy at the ME (Calculated) or Wiring (MI) terminal',
            vehiclePresentingDate:
              'Vehicle Presentation Date at the unloading terminal (ME) or Wiring (MI)',
            loadingstatus: 'Loading Status',
            effectiveUnloadingStartDate: 'Effective Unloading Start Date',
            unloadingStartDate: 'Unloading Start (Scheduled Date)',
            unloadingEndDate: 'Unloading End Date',
            unloadingContainerDate: 'Containers Unloading Date',
            freeTimeAvailable: 'Free time Available',
            instructionContainersQuantity: 'Qty. of Containers in Instruction',
            stuffedContainersQuantity: 'Qty. of Steamed Containers',
            // stuffingStatus: 'Stuffing Status',
            mapInspectionDate: 'Map Inspection Date',
            fumigationDate: 'Fumigation Date',
            draftDeadline: 'Draft Deadline',
            vGMSubmissionDate: 'VGM Submission Date',
            fiscalExportNoteNumber: 'Export Invoice Number',
            dueClearanceDate: 'Dues clearance date',
            loadingDate: 'Shipment Date',
            // loadingStatus: 'Shipment Status',
            loadDeadline: 'Deadline of load (ME) delivery customer (MI)',
            doneInstructionPercentage: '%Instruction Complete',
            loadLateRecommendation: 'Recommendation regarding load delay'
          },
          instructionDetailsMetaData: {
            company: 'Company',
            harvest: 'Harvest',
            cropYear: 'Crop Year',
            responsible: 'Logistic Responsible',
            biReceiptDate: 'BI Receipt Date',
            statusItas: 'Status ITAS',
            itas: 'Status ITAS',
            originPort: 'Origin Port',
            stuffingEnds: 'Stuffing Terminal',
            fumigation: 'Fumigation',
            forwardingAgent: 'Forwarding Agent',
            weightSupervisor: 'Weight Supervisor',
            availableCarrierFreeTime:
              'Free Time Available From Shipowner (Calculated)',
            availableStuffingTerminalFreeTime:
              'Free Time Available From Stowing Terminal (Calculated)',
            withdrawalContainersDate:
              'Date Calculated to Start Withdrawing Containers',
            removedContainersQuantity: 'Containers Removed',
            voidRemovalProblem: 'Empty Removal Problem',
            calculatedMapInspectionDate: 'Map Inspection Date (Calculated)',
            effectiveMapInspectionDate: 'Map Inspection Effective Date',
            effectiveFumigationDate: 'Effective Fumigation Date',
            calculatedDraftDeadlineDate: 'Draft Deadline (Calculated)',
            draftDeadlineDate: 'Draft Deadline',
            calculatedDueClearanceDate: 'Due Clearance Date (Calculated)',
            dueClearanceDate: 'Due Clearance Date',
            calculatedSchedulingBoardingTerminalDate:
              'Schedule of the Boarding Terminal (Calculated)',
            schedulingBoardingTerminalDate: 'Schedule of the Boarding Terminal',
            schedulingTerminalBoardingStatus:
              'Shipment Terminal Schedule Status',
            calculatedVgmDate: 'Vgm Deadline (Calculated)',
            calculatedDepositDate: 'Deposit Date (Calculated)',
            depositStatus: 'Deposit Status',
            effectiveEtsDate: 'Ets',
            calculatedEtsDate: 'Ets (Calculated)',
            boardingDate: 'Boarding Date',
            scrollingQuantity: 'Scrolling QTY',
            scrollingReason: 'Scrolling',
            vgmSubmissionDate: 'VGM Submission Date',
            planId: 'Plan Id',
            farmProducer: 'Cotton/Producer',
            modalType: 'Modal',
            shippingCompany: 'Transperator',
            destination: 'Destination',
            originCityState: 'Origin City/UF',
            destinationCityState: 'Destination City/UF',
            bogeyTable: 'Bogey Table',
            bogeyTableUnity: 'Unity - Bogey Table',
            contractedFreight: 'Contracted Freight',
            contractedFreightUnity: 'Unity - Contracted Freight',
            originLoadingStartDate:
              'Start of Loading at Origin (Scheduled Date)',
            loadingEndDate: 'End of Load',
            effectiveLoadingEndDate: 'End of Loading (Effective Date)',
            planIdStatus: 'Plan Id Load Status',
            contractedBalesQuantity: 'Contracted Bale Quantity',
            loadedBalesQuantity: 'Quantity of Bales Loaded',
            balesBalance: 'Balance (Bales)',
            vehiclePlate: 'Vehicle Plate',
            expectedDestinationArrivalDate:
              'Prediction of Arrival at the Destination (Scheduled Date)',
            destinationArrivalShippingCompanyDate:
              'Arrival at the Destination (Conveyor Vision)',
            destinationArrivalTerminalDate:
              'Arrival at the Destination (Terminal View / Wiring)',
            unLoadingStartDate: 'Loading Start (Scheduled Date)',
            effectiveUnLoadingStartDate: 'Effective Date of Start of Discharge',
            unLoadingEndDate: 'End of Unloading (Scheduled Date)',
            effectiveUnLoadingEndDate: 'Effective Unloading End Date',
            statusByUnLoadingByPlanId: 'Loading Status by Plan Id',
            unloadingTerminal: 'Unloading Terminal(EM) or Wiring(IM)'
          },
          planIdVehicleContractedLotVolumePlateDetailsMetaData: {
            contractedLotVolume: 'Lot Volume (KG)',
            contractedLotReferenceCode: 'Contracted Reference Batch Code',
            loadedLotCode: 'Loaded Batch Code',
            salesInvoice: 'Sale Invoice (Producer)',
            cargillShippingInvoice: 'Cargill Shipping Invoice (EM)',
            cargillSalesInvoice: 'Cargill Sales Invoice (IM)',
            counterNote: 'Against Note',
            stuffingDate: 'Stocking Date',
            totalStuffingVolume: 'Stowed Volume'
          },
          cellRenderLegend: {
            notStarted: 'Not Started',
            onTime: 'On Time',
            riskOfDelay: 'Risk of Delay',
            late: 'Late'
          }
        }
      },
      totalPlanDashboard: {
        title: 'Total Plan',
        headerTitle: 'Total Plan',
        cropYear: 'Crop Year',
        viewSelector: {
          title: 'View',
          clear: 'Clear View',
          viewType: 'View Type',
          series: 'Series'
        },
        pptExport: {
          exportConsolidatedTitle: 'Exportation',
          domesticMarketConsolidatedTitle: 'Domestic Market',
          originationConsolidatedTitle: 'Origination',
          bookingCapacity: 'Bookings Capacities'
        },

        exportConsolidated: {
          title: 'Export'
        },
        domesticMarketConsolidated: {
          title: 'Domestic Market'
        },
        originationConsolidated: {
          title: 'Origination'
        },
        pdConsolidated: {
          title: 'Premium and Discount'
        },
        storageCostConsolidated: {
          title: 'Storage Cost'
        },
        executionTitle: 'Execution',
        programTitle: 'Program',
        executionConsolidated: {
          title: 'Execution - Consolidated',
          exportConsolidatedTitle: 'Export',
          domesticMarketConsolidatedTitle: 'Domestic Market',
          originationConsolidatedTitle: 'Origination'
        },
        programConsolidated: {
          title: 'Program - Consolidated',
          exportConsolidatedTitle: 'Export',
          domesticMarketConsolidatedTitle: 'Domestic Market',
          originationConsolidatedTitle: 'Origination',
          pdConsolidatedTitle: 'Premium and Discount',
          storageCostConsolidatedTitle: 'Storage Cost'
        },
        domesticMarketProgram: {
          title: 'Graph - Domestic Market Program'
        },
        bookingCapacity: {
          title: 'Graph - Bookings Capacities'
        },
        exportProgram: {
          title: 'Graph - Export Program'
        },
        originationProgram: {
          title: 'Graph - Origination Program'
        },
        tooltipStorageCost:
          'The consolidated display includes only information for future periods. The executed storage cost will not be considered.',
        tooltipPD:
          'Displayed P/D includes only information for future periods. The calculation of P/D of realized volumes will not be considered.'
      },
      controlTower: {
        title: 'Control Tower'
      },
      controlTowerUnity: {
        title: 'Unit',
        code: 'Code',
        description: 'Description',
        symbol: 'Symbol',
        category: 'Category'
      },
      controlTowerUnityConversion: {
        title: 'Unit Conversion',
        beginHorizon: 'Begin Horizon',
        endHorizon: 'End Horizon',
        fromUnity: 'From Unit',
        toUnity: 'To Unit',
        active: 'Active',
        factor: 'Conversion Factor',
        errors: {
          sameUnits: 'Cannot convert between the same unit'
        },
        hints: {
          factor:
            'The conversion factor is a multiplication related to the FROM unit.'
        }
      },
      forecast: {
        forecast: 'Forecast',
        consolidated: 'Consolidated'
      },
      forecastSales: {
        title: 'Forecast Sales Accuracy',
        graphTitle: 'Sales Accuracy',
        consolidated: 'Consolidated',
        period: 'Period',
        filter: 'Filter',
        till: 'Till',
        indicator: {
          title: 'Indicators',
          fa: 'MAIN FA OFFENSIVES',
          clients: 'MAIN CLIENT OFFENSIVES',
          destiny: 'MAIN DESTINY CONTRY OFFENSIVES'
        }
      },
      forecastOrigin: {
        title: 'Origination Plan Adhrence',
        graphTitle: 'Origination Plan Adhrence',
        consolidated: 'Consolidated',
        period: 'Period',
        filter: 'Filter',
        till: 'Till',
        indicator: {
          title: 'Indicators',
          pa: 'MAIN PA OFFENSIVES',
          producers: 'MAIN PRODUCER OFFENSIVES',
          uf: 'MAIN UF OFFENSIVES'
        }
      },
      capacityUtilization: {
        title: 'Capacity Utilization',
        harvest: 'Harvest',
        fiscalYear: 'Fiscal Year',
        consolidated: 'Consolidated',
        ibp: 'IBP',
        to: 'To',
        graphTitle: 'Fulfilled and planned capacity utilization',
        fulfilled: 'Fulfilled',
        planned: 'Planned',
        indicators: {
          indicators: 'Indicators',
          utilityOpen: 'Opening of use (Planned)'
        },
        filter: {
          title: 'Filter',
          period: 'Period',
          harvest: 'Harvest',
          fiscalYear: 'Fiscal Year'
        },
        railroadCapacity: 'Railway + Waterway + Stuffing Capacity',
        shipownerCapacity: 'Shipowner Capacity',
        werehouse: 'Warehouse Capacity'
      },
      goal: {
        title: 'Goal',
        date: 'Date',
        goalType: 'Goal Type',
        target: 'Target',
        value: 'Goal'
      },
      originationFulfilled: {
        title: 'Origination Fulfilled',
        date: 'Date',
        uf: 'State',
        producer: 'Producer',
        value: 'Origination'
      },
      plannedOrigination: {
        title: 'Planned Origination',
        scenario: 'Scenario',
        date: 'Date',
        uf: 'State',
        producer: 'Producer',
        value: 'Origination'
      },
      otifMonthly: {
        title: 'On Time In Full - Monthly',
        date: 'Date',
        onTime: 'On Time',
        inFull: 'In Full',
        customer: 'Customer',
        originPort: 'Origin Port',
        destinyCountry: 'Destiny Country'
      },
      fulfilledSale: {
        title: 'Sale Fulfilled',
        date: 'Date',
        country: 'Country',
        client: 'Customer',
        customer: 'Customer',
        value: 'Sale'
      },
      plannedSale: {
        title: 'Planned Sale',
        scenario: 'Scenario',
        date: 'Date',
        country: 'Country',
        client: 'Customer',
        customer: 'Customer',
        value: 'Sale'
      },
      otifWeekly: {
        title: 'On Time In Full - Weekly',
        date: 'Date',
        onTime: 'On Time',
        inFull: 'In Full',
        customer: 'Customer',
        originPort: 'Origin Port',
        destinyCountry: 'Destiny Country'
      },
      fulfilledCapacityUtilization: {
        title: 'Fulfilled Capacity Utilization',
        scenario: 'Scenario',
        date: 'Date',
        value: 'Capacity Utilization',
        capacityUtilizationType: 'Capacity Utilization Type',
        name: 'Name'
      },
      plannedCapacityUtilization: {
        title: 'Planned Capacity Utilization',
        scenario: 'Scenario',
        date: 'Date',
        value: 'Capacity Utilization',
        capacityUtilizationType: 'Capacity Utilization Type',
        name: 'Name'
      },
      maximumCapacityUtilization: {
        title: 'Maximum Capacity Utilization',
        date: 'Date',
        name: 'Name',
        maximumCapacity: 'Maximum Capacity',
        capacityUtilizationType: 'Capacity Utilization Type'
      },
      onTimeInFull: {
        graphTitle: 'OTIF - On Time in Full',
        otifLabel: 'OTIF Fulfilled',
        metaLabel: 'OTIF Goal',
        to: 'to',
        till: 'till',
        consolidated: 'Consolidated',
        OTIF: 'OTIF',
        filter: 'Filter',
        period: 'Period',
        title: 'Planning Indicators',
        customers: 'Customers',
        destinyCountries: 'Destiny Countries',
        originPorts: 'Origin Ports',
        indicator: {
          title: 'Indicators',
          customers: 'Customers with low OTIF',
          destinyCountries: 'Destiny Countries with low OTIF',
          originPorts: 'Origin Ports with low OTIF'
        }
      },
      weeklyPlanningDashboard: {
        title: 'Weekly Planning',
        unityMeasure: 'Unit Measure',
        details: 'Details',
        filter: 'Filter'
      },
      takeUpPortal: {
        title: 'Take-Up Portal'
      },
      takeUpPurchaseContract: {
        messages: {
          generateBooks: 'Generate Books',
          confirmGenerateBooks:
            'Are you sure you want to generate Books for the contract?',
          confirmGenerateBooksByFilter:
            'Are you sure you want to generate Books for the filtered contracts?',
          confirmGenerateBooksByFilterOnAllItems:
            'There is NO filter applied! Are you sure you want to generate Books on ALL contracts?'
        },
        title: 'Contracts',
        company: 'Company',
        contractReference: 'Contract',
        parent: 'Parent Contract',
        cropYear: 'Crop Year',
        quantity: 'Quantity',
        quantityUnit: 'Quantity Unit',
        counterPartySector: 'Counterparty Sector',
        positionMonth: 'Position Month',
        contractDate: 'Contract Date',
        counterpartyName: 'Counterparty',
        originState: 'Origin State',
        ccQuality: 'Base Type (Tranche)',
        grade: 'Expected Quality',
        counterpartyCode: 'Counterparty Code',
        producer: 'Producer',
        baseTypeHeader: 'Base Type (Header)',
        brazilGradeCode: 'Brazil Grade Code',
        brazilGradePdPts: 'Brazil Grade P/d Pts',
        pdCurrency: 'P/D Currency',
        pdPriceCode: 'P/D Price Code',
        minStaple: 'Min Staple',
        maxStaple: 'Max Staple',
        minAvgStaple: 'Min Avg Staple',
        minMic: 'Min Mic',
        maxMic: 'Max Mic',
        minStrength: 'Min Strength',
        maxStrength: 'Max Strength',
        minAvgStrength: 'Min Avg Strength',
        minSfi: 'Min Sfi',
        currency: 'Currency',
        setUpDateContractMaintenace: 'Set Up Date (Contract Maintenace)',
        counterPartRef: 'Counter Part Ref',
        brazilGradeCodeFull: 'Brazil Grade Code',
        incoterm: 'Incoterm',
        tolerance: 'Tolerance'
      },
      takeUpReportInformationMaster: {
        messages: {
          calculateReport: 'Calculate Report',
          calculateReportSuccessMsg: 'Report Calculation done successfully'
        },
        title: 'Take-Up Report',
        cropYear: 'Crop Year',
        contract: 'Contract',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        month: 'Month',
        classifier: 'Classifier',
        takeUpVolume: 'Take Up Volume',
        lotQuantity: 'Lot Quantity',
        producerRequestedChange: 'Requested Change',
        hviStatus: 'HVI',
        takeUpLotReportInformationSummary: {
          title: 'Detailing',
          book: 'Book',
          takeUpInput: 'Take-Up Input',
          takeUpReportDate: 'Take up Report Date',
          purchaseNo: 'Purchase No',
          takeUpDate: 'Take up date',
          hviRecDate: 'HVI Rec Date',
          shipmentMonth: 'Shipment Month',
          lotRef: 'Lot Ref',
          noBales: 'No. Bales',
          grossWeight: 'Take-Up Volume',
          balesPerCntr: 'Bales per Cntr',
          akt: 'AKT#',
          location: 'Location',
          gin: 'Gin',
          ginCode: 'Gin',
          region: 'Region',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySummary: 'Qlty Summary',
          bark: 'Bark',
          bidens: 'Bidens',
          fumagHone: 'Fumag / Hone',
          grass: 'Grass',
          scnSeed: 'SCN / SEED',
          comments: 'Comments',
          infLength: 'Inf Length',
          minLength: 'Min Length',
          maxLength: 'Max Length',
          avLength: 'Av Length',
          infStrength: 'Inf Strength',
          minStrength: 'Min Strength',
          maxStrength: 'Max Strength',
          avStrength: 'Av Strength',
          infMic: 'Inf Mic',
          minMic: 'Min Mic',
          maxMic: 'Max Mic',
          avMic: 'Av Mic',
          supMic: 'Sup Mic',
          sfiGreaterThan10: 'SFI>10',
          avSfi: 'Av SFI',
          avSci: 'Av SCI',
          avMat: 'Av Mat',
          avUi: 'Av UI',
          avElg: 'Av Elg',
          lenLte2659: 'Len <= 26.59',
          len2660To2739: 'Len 26.60 - 27.39',
          len2740To2742: 'Len 27.40 - 27.42',
          len2743To2809: 'Len 27.43 - 28.09',
          len2810To2817: 'Len 28.10 - 28.17',
          len2818To2897: 'Len 28.18 - 28.97',
          lenGte2898: 'Len >= 28.98',
          strLte2100: 'Str <= 21.00',
          str2100To2299: 'Str 21 - 22.99',
          str2300To2499: 'Str 23 - 24.99',
          str2500To2699: 'Str 25 - 26.99',
          str2700To2799: 'Str 27 - 27.99',
          str2800To2899: 'Str 28 - 28.99',
          str2900To3199: 'Str 29 - 31.99',
          strGte3200: 'Str >= 32.00',
          micLte249: 'Mic <= 2.49',
          mic250To269: 'Mic 2.50 - 2.69',
          mic270To299: 'Mic 2.70 - 2.99',
          mic300To329: 'Mic 3.00 - 3.29',
          mic330To349: 'Mic 3.30 - 3.49',
          mic350To429: 'Mic 3.50 - 4.29',
          mic430To459: 'Mic 4.30 - 4.59',
          mic460To490: 'Mic 4.60 - 4.90',
          mic491To529: 'Mic 4.91 - 5.29',
          micGte530: 'Mic >= 5.30',
          netWeight: 'Net Weight',
          staple: 'Staple',
          controller: 'Controller',
          buyingOffice: 'Buying Office',
          part4: 'Part 4',
          status: 'Status'
        }
      },
      takeUpLocality: {
        title: 'Localities',
        city: 'City',
        state: 'State',
        subRegional: 'Sub-Regional'
      },
      takeUpContaminationValue: {
        title: 'Contamination',
        greatness: 'Greatness',
        description: 'Description',
        acceptableValue: 'Acceptable Value'
      },
      takeUpHvi: {
        title: 'Upload HVI',
        cropYear: 'Crop Year',
        contract: 'Contract',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        month: 'Month',
        classifier: 'Classifier',
        lotQuantity: 'Lot Quantity',
        takeUpStatus: 'Take-Up',
        messages: {
          deleteAllInputHvi: 'Delete contract HVI',
          confirmationToDeleteAllInputHvi:
            'Do you want to delete the HVI of the selected contract?',
          confirmationToDeleteRejectedLots:
            'There are lots rejected in the P/D. Do you want to delete HVI related to rejected lots?'
        },
        takeUpHviInput: {
          title: 'HVI',
          takeUpStatus: 'Take-Up',
          company: 'Company',
          cropYear: 'Crop Year',
          month: 'Month',
          recDate: 'Rec. Date',
          companyClass: 'Classifier',
          producerName: 'Producer',
          ginName: 'Gin',
          cak: 'Cak',
          lot: 'Lot',
          saiCode: 'S.A.I. Code (Brz)',
          baleId: 'Bale ID',
          sci: 'SCI',
          mic: 'Mic',
          mat: 'Mat',
          uhmlInch: 'UHML [inch]',
          uhmlMm: 'UHML [mm]',
          ui: 'UI [%]',
          sfi: 'SFI [%]',
          str: 'Str [G/tex]',
          elg: 'Elg [%]',
          csp: 'Csp',
          rd: 'Rd',
          plusB: '+ b',
          cGrg: 'CGrg',
          trCnt: 'Tr Cnt',
          trAr: 'Tr Ar',
          trId: 'Tr ID',
          book: 'Book',
          username: 'Imported by'
        }
      },
      qualityAndContamination: {
        title: 'Quality and Contamination'
      },
      takeUpCompany: {
        title: 'Companies',
        code: 'Code',
        name: 'Name',
        fullAccess: 'Full Access',
        takeUpCompaniesUsers: {
          title: 'Users',
          user: 'User',
          company: 'Company'
        },
        takeUpCompanyProducer: {
          title: 'Producer',
          company: 'Classifier',
          producer: 'Code',
          producerName: 'Producer'
        }
      },
      takeUpBook: {
        messages: {
          makeNotAvailable: 'Make not available',
          makeAvailable: 'Make available',
          confirmChangeBookStatus:
            'Are you sure you want to change book status?',
          confirmChangeBookStatusByFilter:
            'Are you sure you want to change the status of the filtered books?',
          confirmChangeBookStatusByFilterOnAllItems:
            'There is NO filter applied! Are you sure you want to change status on ALL books?',
          generateProducerMonitoring: 'Generate Producer Monitoring',
          confirmGenerateProducerMonitoring:
            'Are you sure you want to generate Producer Monitoring for the book?',
          confirmGenerateProducerMonitoringByFilter:
            'Are you sure you want to generate Producer Monitoring for the filtered books?',
          confirmGenerateProducerMonitoringByFilterOnAllItems:
            'There is NO filter applied! Are you sure you want to generate Producer Monitoring on ALL books?'
        },
        title: 'Books',
        cropYear: 'Crop Year',
        company: 'Company',
        contract: 'Contract',
        month: 'Month',
        volume: 'Volume (T)',
        state: 'State',
        region: 'Region',
        producerCode: 'Produtor',
        producerName: 'Producer Name',
        brainOption: 'Brain Option',
        status: 'Status'
      },
      takeUpGin: {
        title: 'Farm',
        ginSaiCode: 'Gin Code (S.A.I)',
        code: 'Code',
        companyName: 'Company Name',
        region: 'Sub-Regional',
        state: 'State',
        city: 'City',
        zipCode: 'Zip Code',
        streetRoadName: 'Street / Road Name',
        number: 'Number',
        latitude: 'Latitude',
        longitude: 'Longitude',
        routing: 'Routing',
        restriction: 'Restriction',
        isGinPreview: 'Provisional Code'
      },
      takeUpProducer: {
        title: 'Producer',
        code: 'Producer Code',
        name: 'Producer',
        responsibleMerchant: 'Responsible Merchant',
        region: 'Sub-Regional',
        state: 'Producer State',
        city: 'City',
        isProducerPreview: 'Provisional Code',
        isCreateGinPreview: 'Allow to Create Temporary Farm',
        takeUpProducerGin: {
          title: 'Farm',
          gin: 'Farm',
          producerName: 'Producer',
          ginSaiCode: 'Gin Code (S.A.I)',
          code: 'Code',
          companyName: 'Company Name',
          region: 'Sub-Regional',
          state: 'State',
          city: 'City',
          zipCode: 'Zip Code',
          streetRoadName: 'Street / Road Name',
          number: 'Number',
          latitude: 'Latitude',
          longitude: 'Longitude',
          routing: 'Routing',
          restriction: 'Restriction'
        },
        takeUpCounterPartyRef: {
          title: 'Counter Party Ref',
          producer: 'Producer Code',
          counterPartyRef: 'Counter Party Ref'
        }
      },
      takeUpReferenceTableIntrinsic: {
        title: 'Reference Table - Intrinsic',
        table: 'Table',
        currency: 'Currency',
        takeUpReferenceTableIntrinsicGradeRule: {
          title: 'Grade',
          referenceTable: 'Reference Table',
          grade: 'Grade',
          gradePd: 'Grade P/D'
        },
        takeUpReferenceTableIntrinsicRule: {
          title: 'Intrinsic',
          referenceTable: 'Reference Table',
          grade: 'Grade',
          gradePd: 'Grade P/D',
          greatness: 'Greatness',
          description: 'Description',
          importance: 'Importance',
          group: 'Group',
          calculationRuleType: 'Calculation Type',
          min: 'Min >=',
          max: 'Max <',
          avg: 'Avg >=',
          inferiorLimit: 'Inferior Limit (%)',
          superiorLimit: 'Superior Limit (%)',
          intrinsicPd: 'Intrinsic P/D'
        }
      },
      takeUpReferenceTableContamination: {
        title: 'Reference Table - Contamination',
        table: 'Table',
        default: 'Default',
        currency: 'Currency',
        makeDefault: 'Make Default',
        makeNotDefault: 'Cancel Default',
        changeDefaultMessage:
          'Are you sure you want to make the table default?',
        takeUpReferenceTableContaminationRule: {
          title: 'Contamination',
          referenceTable: 'Reference Table',
          greatness: 'Greatness',
          description: 'Description',
          importance: 'Importance',
          min: 'Min',
          max: 'Max',
          equal: 'Equal',
          notEqual: 'Not Equal',
          pd: 'P/D'
        }
      },
      takeUpGrade: {
        title: 'Grade',
        grade1: 'Grade 1',
        grade2: 'Grade 2',
        rating: 'Rating (1 out of 2)'
      },
      takeUpOffer: {
        title: 'Offers',
        contract: 'Contract',
        cropYear: 'Crop Year',
        producer: 'Producer',
        producerName: 'Producer Name',
        classifier: 'Classifier',
        status: 'Cargill Evaluation',
        insertedBy: 'Inserted By',
        takeUpOfferLot: {
          title: 'Lots',
          offer: 'Offer',
          offerContract: 'Offer',
          cropYear: 'Crop Year',
          gin: 'Gin',
          ginName: 'Gin Name',
          lotReference: 'Lot Reference',
          grade: 'Grade'
        }
      },
      takeUpCancellations: {
        title: 'Cancellations',
        company: 'Company',
        contract: 'Contract',
        cropYear: 'Crop Year',
        producer: 'Counterparty',
        farm: 'Gin',
        lotReference: 'Lot Reference',
        saiCode: 'S.A.I. Code (Brz)',
        quantity: 'Quantity (kg)',
        purchaseContract: 'PurchaseContract',
        producerName: 'Counterparty'
      },
      takeUpSchedules: {
        title: 'Schedules',
        cropYear: 'Crop Year',
        company: 'Company',
        producer: 'Producer',
        contract: 'Contract',
        lotReference: 'Lot Reference',
        volumeForecast: 'Volume Forecast (T)',
        hviProducerAgreementDate: 'HVI Producer Agreement Date',
        producerMonitoringFarmName: 'Gin Name (Producer Monitoring)',
        producerMonitoringFarmCode: 'Gin Code (Producer Monitoring)',
        hviFarmName: 'Gin Name (HVI)',
        hviFarmCode: 'Gin Code (HVI)',
        takeUpReportFarmName: 'Gin Name (Take-Up Report)',
        takeUpReportFarmCode: 'Gin Code (Take-Up Report)',
        warehouseName: 'Warehouse Name',
        warehouse: 'Warehouse Code',
        scheduledTakeUpDate: 'Scheduled Take-Up Date',
        period: 'Period',
        classifier: 'Classifier',
        controller: 'Controller (Email)',
        controllerName: 'Controller (Name)',
        takeUpState: 'Take-Up State',
        takeUpCityLabel: 'Take-Up City',
        takeUpCity: 'Take-Up City',
        takeUpDate: 'Take-Up Date',
        accomplishedVolume: 'Accomplished Volume (Post Take-Up) (kg)',
        lotStatus: 'Lot Status',
        book: 'Book'
      },
      takeUpProducerMonitoring: {
        title: 'Producer Monitoring',
        company: 'Company',
        cropYear: 'Crop Year',
        producer: 'Producer',
        contract: 'Contract',
        volumeMonth: 'Volume of the Month (t)',
        month: 'Month',
        modality: 'Modality',
        marketplace: 'Marketplace',
        classifierCode: 'Classifier',
        status: 'Status',
        lastUpdateClassifier: 'Last Update (Classifier)',
        expectedHviDate: 'Expected Date of HVI',
        expectedTakeUpDate: 'Expected Take-Up Date',
        location: 'Gin Code',
        locationCode: 'Gin Code',
        locationName: 'Gin Name',
        lastUpdatedByName: 'Last Updated By:',
        comments: 'Comments',
        leaveComment: 'Leave a Commentary',
        deleteSuccess: 'Deletion Sucessfull',
        deleteError: 'Deletion failed',
        commentSendSuccess: 'Comment Successfully Sended',
        commentSendError: 'Error Sending Comment',
        emptyCommentError: 'Error: Empty Comment',
        expectedVolume: 'Expected Volume (t)'
      },
      takeUpQualityAndContamination: {
        title: 'Quality and Contamination',
        code: 'Code',
        description: 'Description',
        type: 'Type',
        takeUpQualityAndContaminationTranslation: {
          title: 'Quality and Contamination Translation',
          qualityContamination: 'Code Quality/Contamination',
          alternativeCode: 'Alternative Code'
        }
      },
      takeUpLotReportInputMaster: {
        title: 'Take-Up Upload',
        cropYear: 'Crop Year',
        contract: 'Contract',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        month: 'Month',
        classifier: 'Classifier',
        takeUpVolume: 'Take Up Volume',
        lotQuantity: 'Lot Quantity',
        producerRequestedChange: 'Requested Change',
        hviStatus: 'HVI',
        acceptableVolume:{
          minLabel: 'Minimum Weight',
          maxLabel: 'Maximum weight'
        },
        messages: {
          deleteAllInputTakeUp: 'Delete contract Take-Up Input',
          confirmationToDeleteAllInputTakeUp:
            'Do you want to delete the Take-Up Input of the selected contract?',
          calculateUsBucket: 'Calculate US Bucket',
          calculateUsBucketSuccessMsg:
            'US Bucket calculation performed successfully. Quality Group: {qualityGroup}',
          calculateUsBucketByBookSuccessMsg:
            'US Bucket calculation performed successfully!',
          calculateUsBucketByBookWarningMsg:
            'US Bucket calculation partially performed. {hviStandbyQty} lots with HVI - STANDBY!',
          calculateUsBucketByBookErrorMsg:
            'US Bucket calculation partially performed. {errorsQty} lots with error. Log screen: {logTitle}',
          confirmationToDeleteRejectedLots:
            'There are lots rejected in the P/D. Do you want to delete Take-Up related to rejected lots?',
          settingsAcceptableVolume: 'Configure the acceptable volume',
          infoAcceptableVolume:
            'Information to validate the average bale weight'
        },
        takeUpLotReportInput: {
          title: 'Take-Up Input',
          reportDate: 'Report Date',
          producerRequestedChange: 'Producer Requested Change?',
          statusHvi: 'Status HVI',
          company: 'Company',
          cropYear: 'Crop Year',
          contract: 'PurchaseNo',
          companyClass: 'Company Class',
          takeUpDate: 'Take up date',
          hviRecDate: 'HVI Rec Date',
          lotRef: 'Lot Ref',
          bales: 'Bales',
          grossWeight: 'Take-Up Volume',
          balesCntr: 'Bales p Cntr',
          baleAvg: 'Bale Avg',
          deliveryProducerName: 'Delivery Producer Name',
          deliveryProducer: 'Delivery Producer',
          gin: 'Gin Code',
          ginRegion: 'Region',
          ginName: 'Gin Name',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySum: 'Qlty Sum.',
          bark: 'Bark',
          bidens: 'Bidens',
          fumagHone: 'Fumag / Hone',
          grass: 'Grass',
          scnSeed: 'SCN / SEED',
          comments: 'Comments',
          controller: 'Controller',
          lotStatus: 'Lot Status',
          reasonForRefusal: 'Reason for refusal',
          negotiationStatus: 'Negotiation Status',
          username: 'Imported by'
        }
      },
      takeUpSchedulesFarmReportLog: {
        title: 'Take-Up Schedules Generator Report Log',
        company: 'Company',
        contract: 'Contract',
        cropYear: 'Crop Year',
        classifier: 'Classifier',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        farmCode: 'Farm Code',
        farmName: 'Farm Name',
        producerMonitoringStatus: 'Producer Monitoring Status',
        producerMonitoringId: 'Producer Monitoring Id',
        contractId: 'Contract Id',
        offerId: 'Offer Id',
        bookId: 'Book Id',
        farmId: 'Farm Id',
        event: 'Event',
        validationEvent: 'Validation Event',
        numberOfSchedulesWithHviFarmUpdated:
          'Number Of Schedules With Hvi Farm Updated',
        schedulesLotsWithHviFarmUpdated: 'Schedules Lots With Hvi Farm Updated',
        numberOfSchedulesWithTakeUpReportFarmUpdated:
          'Number Of Schedules With Take Up Report Farm Updated',
        schedulesLotsWithTakeUpReportFarmUpdated:
          'Schedules Lots With Take Up Report Farm Updated',
        numberOfSchedulesAdded: 'Number Of Schedules Added',
        schedulesLotsAdded: 'Schedules Lots Added',
        numberOfSchedulesDeleted: 'Number Of Schedules Deleted',
        schedulesLotsDeleted: 'Schedules Lots Deleted',
        numberOfSchedulesIgnored: 'Number Of Schedules Ignored',
        schedulesLotsIgnored: 'Schedules Lots Ignored',
        triggerStart: 'Trigger Start',
        triggerEnd: 'Trigger End',
        triggerType: 'Trigger Type',
        key: 'Key',
        contractKey: 'Contract Key'
      },
      takeUpUsBucketCalculatorReportLog: {
        title: 'Take-Up US Bucket Calculator Report Log',
        triggerStart: 'Trigger Start',
        triggerType: 'Trigger Type',
        usBucketTable: 'US Bucket Table',
        company: 'Company',
        contract: 'Contract',
        cropYear: 'Crop Year',
        classifier: 'Classifier',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        ginCode: 'Gin Code',
        ginName: 'Gin Name',
        lotRef: 'Lot Ref',
        noBales: 'No Bales',
        hviNoBales: 'Hvi No Bales',
        grade: 'Grade',
        qltySum: 'Qlty Sum',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag Hone',
        grass: 'Grass',
        scnSeed: 'Scn Seed',
        minLength: 'Min Length',
        maxLength: 'Max Length',
        avLength: 'Av Length',
        minStrength: 'Min Strength',
        maxStrength: 'Max Strength',
        avStrength: 'Av Strength',
        infMic: 'Inf Mic',
        minMic: 'Min Mic',
        maxMic: 'Max Mic',
        avMic: 'Av Mic',
        supMic: 'Sup Mic',
        errorMsg: 'Error Msg'
      },
      takeUpUsBucket: {
        title: 'US Bucket',
        table: 'Table',
        default: 'Default',
        makeDefault: 'Make Default',
        makeNotDefault: 'Cancel Default',
        changeDefaultMessage:
          'Are you sure you want to make the table default?',
        removeDefaultMessage:
          'Are you sure you want to cancel the table as default?',
        takeUpUsBucketImportance: {
          title: 'Importance',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Name',
          importance: 'Importance'
        },
        takeUpUsBucketContamination: {
          title: 'Contamination',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Name',
          contamination: 'Contamination',
          operator: 'Operator',
          value: 'Value'
        },
        takeUpUsBucketGrade: {
          title: 'Grade',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Name',
          grade: 'Grade',
          operator: 'Operator'
        },
        takeUpUsBucketIntrinsic: {
          title: 'Intrinsic',
          usBucket: 'US Bucket',
          qualityGroup: 'Quality Group',
          name: 'Name',
          greatness: 'Greatness',
          min: 'Min',
          max: 'Max',
          avg: 'Avg',
          equal: 'Equal',
          comparationRule: 'Comparation Rule'
        }
      },
      takeUpPremiumDiscountManagement: {
        title: 'PD Calculation',
        showLines: 'Show lines',
        managerial: 'Managerial',
        detailing: 'Detailing',
        exportForProducer: 'Export in producer format',
        previewWeightedAverage: 'Preview weighted average',
        applyWeightedAverage: 'Apply weighted average',
        clearWeightedAverage: 'Clear weighted average',
        calculatePremiumDiscount: 'Calculate Premium/Discount',
        hviExtract: 'HVI',
        unsavedChangesMessage:
          'You have unsaved changes. Do you want to discard changes and continue?',
        confirmApplyWeightedAverage:
          'Are you sure you want to apply the weighted average to the filtered items?',
        confirmApplyWeightedAverageOnAllItems:
          'There is NO filter applied! Are you sure you want to apply the weighted average to ALL items?',
        confirmClearWeightedAverage:
          'Are you sure you want to clear the weighted average on filtered items?',
        confirmClearWeightedAverageOnAllItems:
          'There is NO filter applied! Are you sure you want to clear the weighted average on ALL items?',
        confirmFieldsStatusChange:
          'The status of fields {0} were approved for some record and were modified. Do you want to change the status?',
        confirmFieldStatusChange:
          'The status of field {0} was approved for some record and has been modified. Do you want to change the status?',
        confirmCalculatePremiumDiscount:
          'Are you sure you want to calculate Premium/Discount on filtered items?',
        confirmCalculatePremiumDiscountOnAllItems:
          'There is NO filter applied! Are you sure you want to calculate Premium/Discount on ALL items?',
        premiumDiscountActions: 'Actions',
        premiumDiscountCalculateResultsWithAlerts:
          'P/D calculation generated alerts!<br>{0} lines with alerts.<br>{1} rows successfully.',
        premiumDiscountCalculateResultItemWithAlerts:
          'P/D calculation generated {0} alerts!',
        classifier: 'Classifier',
        controller: 'Controller',
        purchaseNo: 'Purchase No',
        reportDate: 'Report Date',
        hviRecDate: 'HVI Rec Date',
        takeUpDate: 'Take Up date',
        market: 'Market',
        lotRef: 'Lot Ref',
        bales: 'Bales',
        grossWeight: 'Gross Weight',
        balesPerCntr: 'Bales p Cntr',
        baleAvg: 'Bale Avg',
        location: 'Location',
        warehouseName: 'Warehouse Name',
        warehouseCode: 'Warehouse Code',
        gin: 'Gin Code',
        ginName: 'Gin Name',
        region: 'Region',
        offerGrade: 'Offer - Grade',
        grade: 'Grade',
        tcg: 'TCG',
        cargillGrade: 'Cargill Grade (CGL)',
        cropYear: 'Crop Year',
        company: 'Company',
        producer: 'Producer Code',
        producerName: 'Producer Name',
        contract: 'Contract',
        lotOffer: 'Lot (Offer)',
        referenceQualitySummary: 'Reference Quality Summary',
        pdBrainGrade: 'P/D Brain - Grade',
        pdCargillGrade: 'P/D Cargill - Grade',
        rcpPdGrade: 'RCP - P/D Grade',
        rcpPdContamination: 'RCP - P/D Contamination',
        qualitySummary: 'Quality Summary',
        contractReference: 'Contract Reference',
        hviAndGradePdReferenceTable: 'HVI and Grade: P/D Reference Table',
        contaminationPdReferenceTable: 'Contamination - P/D Reference Table',
        gradeBrainRecommendationTakeUp:
          'Grade - Brain Recommendation - Take Up',
        brainRecommendationHvi: 'Brain Recommendation - HVI',
        brainContaminationRecommendationTakeUp:
          'Brain Contamination Recommendation - Take Up',
        pdBrainHvi: 'P/D Brain - HVI',
        pdCargillHvi: 'P/D Cargill - HVI',
        pdBrainTakeUpType: 'P/D Brain - Take Up (Type)',
        pdCargillTakeUpType: 'P/D Cargill - Take Up (Type)',
        pdBrainTakeUpContamination: 'P/D Brain - Take Up Contamination',
        pdCargillTakeUpContamination: 'P/D Cargill - Take Up Contamination',
        cargillDecisionPdHvi: 'Cargill Decision P/D - HVI',
        dateOfDecisionCargillHvi: 'Date of Decision Cargill HVI',
        hviProducerAgreement: 'HVI Producer Agreement',
        hviAgreementDate: 'HVI Agreement Date',
        cargillDecisionPdTakeUp: 'Cargill Decision P/D - Take Up',
        dateDecisionCargillPdTakeUp: 'Date Decision Cargill P/D - Take Up',
        takeUpProducerAgreement: 'Take Up Producer Agreement',
        takeUpAgreementDate: 'Take Up Agreement Date',
        lotStatus: 'Lot Status',
        totalBrainPd: 'Total Brain P/D',
        totalFinalPd: 'Total Final P/D',
        pdForRecapCases: 'P/D for Recap Cases',
        weightedAverage: 'Weighted Average',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag / Hone',
        grass: 'Grass',
        scnSeed: 'SCN / SEED',
        pdBrainContamination: 'P/D Brain - Contamination',
        pdCargillContamination: 'P/D Cargill - Contamination',
        commentsLot: 'Comments Lot',
        commentsTakeUp: 'Comments Take Up',
        infLength: 'Inf',
        minLength: 'Min',
        maxLength: 'Max',
        avLength: 'Avg',
        infStrength: 'Inf',
        minStrength: 'Min',
        maxStrength: 'Max',
        avStrength: 'Avg',
        infMic: 'Inf',
        minMic: 'Min',
        maxMic: 'Max',
        avMic: 'Avg',
        supMic: 'Sup',
        sfiGreaterThan10: 'Qty > 10%',
        avSfi: 'Avg',
        avSci: 'Avg',
        avMat: 'Avg',
        avUi: 'Avg',
        avElg: 'Avg',
        sfiPdBrain: 'P/D Brain',
        sfiPdCargill: 'P/D Cargill',
        lenLte2659: '< = 26.59',
        len2660To2739: '26.60 - 27.39',
        len2740To2742: '27.40 - 27.42',
        len2743To2809: '27.43 - 28.09',
        len2810To2817: '28.10 - 28.17',
        len2818To2897: '28.18 - 28.97',
        lenGte2898: '> = 28.98',
        lenPercentageUnder2740: '% Fibers (All under 27.40)',
        lenPercentage35: '% Fiber percentage 35',
        lenPdBrain: 'P/D Brain',
        lenPdCargill: 'P/D Cargill',
        strLte2100: '< = 21.00',
        str2100To2299: '21 - 22.99',
        str2300To2499: '23 - 24.99',
        str2500To2599: '25 - 25.99',
        str2600To2699: '26 - 26.99',
        str2700To2799: '27 - 27.99',
        str2800To2899: '28 - 28.99',
        str2900To3199: '29 - 31.99',
        strGte3200: '> = 32.00',
        strPercentageUnder2700: '% Under 27',
        strPercentage2700: '% GPT 27',
        strPdBrain: 'P/D Brain',
        strPdCargill: 'P/D Cargill',
        micLte249: '< = 2.49',
        mic250To269: '2.50 - 2.69',
        mic270To299: '2.70 - 2.99',
        mic300To329: '3.00 - 3.29',
        mic330To349: '3.30 - 3.49',
        mic350To429: '3.50 - 4.29',
        mic430To459: '4.30 - 4.59',
        mic460To490: '4.60 - 4.90',
        mic491To529: '4.91 - 5.29',
        micGte530: '> = 5.30',
        micPercentageUnder350: '% Under 3.50',
        micPercentageOver490: '% Over 4.90',
        micPdBrainInfMic: 'P/D Brain - Inf Mic',
        micPdBrainSupMic: 'P/D Brain - Sup Mic',
        micPdBrain: 'P/D Brain',
        micPdCargillInfMic: 'P/D Cargill - Inf Mic',
        micPdCargillSupMic: 'P/D Cargill - Sup Mic',
        micPdCargill: 'P/D Cargill',
        totalHviPdBrain: 'P/D Brain',
        totalHviPdCargill: 'P/D Cargill',
        finalPdBrain: 'P/D Brain',
        resultingPd: 'Resulting P/D',
        commercialArrangement: 'Commercial Arrangement',
        finalPdBrainProportional: 'Proportional',
        pdCurrency: 'P/D Currency',
        ptax: 'PTAX',
        active: 'Active',
        level1And2: 'Level 1-2 (only Incidence Bark-SCN)',
        estraneousMatter: 'Estraneous Matter',
        hviSummary: 'HVI - Summary',
        hviRanges: 'HVI - Ranges',
        mic: 'Mic',
        uhml: 'UHML [mm]',
        gpt: 'GPT [G/tex]',
        sfi: 'SFI [%]',
        sci: 'SCI',
        mat: 'Mat',
        ui: 'UI [%]',
        elg: 'Elg [%]',
        sfiPd: 'SFI',
        totalHvi: 'Total HVI',
        final: 'Final',
        comments: 'Comments'
      },
      takeUpPremiumDiscountReportLog: {
        title: 'PD Calculation - Report Log',
        executionStart: 'Execution Start',
        classifier: 'Classifier',
        controller: 'Controller',
        purchaseNo: 'Purchase No',
        reportDate: 'Report Date',
        hviRecDate: 'HVI Rec Date',
        takeUpDate: 'Take Up date',
        market: 'Market',
        lotRef: 'Lot Ref',
        bales: 'Bales',
        grossWeight: 'Gross Weight',
        balesPerCntr: 'Bales p Cntr',
        baleAvg: 'Bale Avg',
        location: 'Location',
        warehouseName: 'Warehouse Name',
        warehouseCode: 'Warehouse Code',
        gin: 'Gin Code',
        ginName: 'Gin Name',
        region: 'Region',
        grade: 'Grade',
        tcg: 'TCG',
        cargillGrade: 'Cargill Grade (CGL)',
        cropYear: 'Crop Year',
        company: 'Company',
        producer: 'Producer Code',
        producerName: 'Producer Name',
        contract: 'Contract',
        lotOffer: 'Lot (Offer)',
        referenceQualitySummary: 'Reference Quality Summary',
        pdBrainGrade: 'P/D Brain - Grade',
        pdCargillGrade: 'P/D Cargill - Grade',
        rcpPdGrade: 'RCP - P/D Grade',
        rcpPdContamination: 'RCP - P/D Contamination',
        qualitySummary: 'Quality Summary',
        contractReference: 'Contract Reference',
        hviAndGradePdReferenceTable: 'HVI and Grade: P/D Reference Table',
        contaminationPdReferenceTable: 'Contamination - P/D Reference Table',
        gradeBrainRecommendationTakeUp:
          'Grade - Brain Recommendation - Take Up',
        brainRecommendationHvi: 'Brain Recommendation - HVI',
        brainContaminationRecommendationTakeUp:
          'Brain Contamination Recommendation - Take Up',
        pdBrainHvi: 'P/D Brain - HVI',
        pdCargillHvi: 'P/D Cargill - HVI',
        pdBrainTakeUpType: 'P/D Brain - Take Up (Type)',
        pdCargillTakeUpType: 'P/D Cargill - Take Up (Type)',
        pdBrainTakeUpContamination: 'P/D Brain - Take Up Contamination',
        pdCargillTakeUpContamination: 'P/D Cargill - Take Up Contamination',
        cargillDecisionPdHvi: 'Cargill Decision P/D - HVI',
        dateOfDecisionCargillHvi: 'Date of Decision Cargill HVI',
        hviProducerAgreement: 'HVI Producer Agreement',
        hviAgreementDate: 'HVI Agreement Date',
        cargillDecisionPdTakeUp: 'Cargill Decision P/D - Take Up',
        dateDecisionCargillPdTakeUp: 'Date Decision Cargill P/D - Take Up',
        takeUpProducerAgreement: 'Take Up Producer Agreement',
        takeUpAgreementDate: 'Take Up Agreement Date',
        lotStatus: 'Lot Status',
        totalBrainPd: 'Total Brain P/D',
        totalFinalPd: 'Total Final P/D',
        pdForRecapCases: 'P/D for Recap Cases',
        weightedAverage: 'Weighted Average',
        bark: 'Bark',
        bidens: 'Bidens',
        fumagHone: 'Fumag / Hone',
        grass: 'Grass',
        scnSeed: 'SCN / SEED',
        pdBrainContamination: 'P/D Brain - Contamination',
        pdCargillContamination: 'P/D Cargill - Contamination',
        commentsLot: 'Comments Lot',
        commentsTakeUp: 'Comments Take Up',
        infLength: 'Inf',
        minLength: 'Min',
        maxLength: 'Max',
        avLength: 'Avg',
        infStrength: 'Inf',
        minStrength: 'Min',
        maxStrength: 'Max',
        avStrength: 'Avg',
        infMic: 'Inf',
        minMic: 'Min',
        maxMic: 'Max',
        avMic: 'Avg',
        supMic: 'Sup',
        sfiGreaterThan10: 'Qty > 10%',
        avSfi: 'Avg',
        avSci: 'Avg',
        avMat: 'Avg',
        avUi: 'Avg',
        avElg: 'Avg',
        sfiPdBrain: 'P/D Brain',
        sfiPdCargill: 'P/D Cargill',
        lenLte2659: '< = 26.59',
        len2660To2739: '26.60 - 27.39',
        len2740To2742: '27.40 - 27.42',
        len2743To2809: '27.43 - 28.09',
        len2810To2818: '28.10 - 28.18',
        len2819To2889: '28.19 - 28.89',
        lenGte2890: '> = 28.90',
        lenPercentageUnder2740: '% Fibers (All under 27.40)',
        lenPercentage35: '% Fiber percentage 35',
        lenPdBrain: 'P/D Brain',
        lenPdCargill: 'P/D Cargill',
        strLte2100: '< = 21.00',
        str2100To2299: '21 - 22.99',
        str2300To2499: '23 - 24.99',
        str2500To2599: '25 - 25.99',
        str2600To2699: '26 - 26.99',
        str2700To2799: '27 - 27.99',
        str2800To2899: '28 - 28.99',
        str2900To3199: '29 - 31.99',
        strGte3200: '> = 32.00',
        strPercentageUnder2700: '% Under 27',
        strPercentage2700: '% GPT 27',
        strPdBrain: 'P/D Brain',
        strPdCargill: 'P/D Cargill',
        micLte249: '< = 2.49',
        mic250To269: '2.50 - 2.69',
        mic270To299: '2.70 - 2.99',
        mic300To329: '3.00 - 3.29',
        mic330To349: '3.30 - 3.49',
        mic350To429: '3.50 - 4.29',
        mic430To459: '4.30 - 4.59',
        mic460To490: '4.60 - 4.90',
        mic491To529: '4.91 - 5.29',
        micGte530: '> = 5.30',
        micPercentageUnder350: '% Under 3.50',
        micPercentageOver490: '% Over 4.90',
        micPdBrainInfMic: 'P/D Brain - Inf Mic',
        micPdBrainSupMic: 'P/D Brain - Sup Mic',
        micPdBrain: 'P/D Brain',
        micPdCargillInfMic: 'P/D Cargill - Inf Mic',
        micPdCargillSupMic: 'P/D Cargill - Sup Mic',
        micPdCargill: 'P/D Cargill',
        totalHviPdBrain: 'P/D Brain',
        totalHviPdCargill: 'P/D Cargill',
        finalPdBrain: 'P/D Brain',
        resultingPd: 'Resulting P/D',
        commercialArrangement: 'Commercial Arrangement',
        finalPdBrainProportional: 'Proportional',
        pdCurrency: 'P/D Currency',
        ptax: 'PTAX',
        level1And2: 'Level 1-2 (only Incidence Bark-SCN)',
        estraneousMatter: 'Estraneous Matter',
        hviSummary: 'HVI - Summary',
        hviRanges: 'HVI - Ranges',
        mic: 'Mic',
        uhml: 'UHML [mm]',
        gpt: 'GPT [G/tex]',
        sfi: 'SFI [%]',
        sci: 'SCI',
        mat: 'Mat',
        ui: 'UI [%]',
        elg: 'Elg [%]',
        sfiPd: 'SFI',
        totalHvi: 'Total HVI',
        final: 'Final'
      },
      takeUpImportsPreparation: {
        title: 'Imports Preparation',
        importInItas: 'Contains data to import into ITAS',
        cropYear: 'Crop Year',
        company: 'Company',
        market: 'Market',
        producerCode: 'Producer Code',
        producerName: 'Producer Name',
        netWeight: 'Net Weight (KG)',
        takeUpImportReport: {
          title: 'Import Report',
          importInItas: 'Import in ITAS',
          cropYear: 'Crop Year',
          company: 'Company',
          market: 'Market',
          producerName: 'Producer Name',
          takeUpReportDate: 'Take up Report Date',
          purchaseNo: 'Purchase No',
          takeUpDate: 'Take up Date',
          hviRecDate: 'HVI Rec Date',
          producerAgreementDate: 'Producer Agreement',
          shipmentMonth: 'Shipment Month',
          lotRef: 'Lot Ref',
          noBales: 'No. Bales',
          grossWeight: 'Gross Weight',
          balesPerCntr: 'Bales per Cntr',
          akt: 'AKT#',
          location: 'Warehouse',
          gin: 'UK Gin',
          region: 'Region',
          grade: 'Grade',
          tcg: 'TCG',
          cargillGrade: 'Cargill Grade',
          qltySummary: 'Qlty Summary',
          bark: 'Bark',
          bidens: 'Bidens',
          fumagHone: 'Fumag / Hone',
          grass: 'Grass',
          scnSeed: 'SCN / SEED',
          comments: 'Comments',
          infLength: 'Inf Length',
          minLength: 'Min Length',
          maxLength: 'Max Length',
          avLength: 'Av Length',
          infStrength: 'Inf Strength',
          minStrength: 'Min Strength',
          maxStrength: 'Max Strength',
          avStrength: 'Av Strength',
          infMic: 'Inf Mic',
          minMic: 'Min Mic',
          maxMic: 'Max Mic',
          avMic: 'Av Mic',
          supMic: 'Sup Mic',
          sfiGreaterThan10: 'SFI>10',
          avSfi: 'Av SFI',
          avSci: 'Av SCI',
          avMat: 'Av Mat',
          avUi: 'Av UI',
          avElg: 'Av Elg',
          lenLte2659: 'Len <= 26.59',
          len2660To2739: 'Len 26.60 - 27.39',
          len2740To2742: 'Len 27.40 - 27.42',
          len2743To2809: 'Len 27.43 - 28.09',
          len2810To2817: 'Len 28.10 - 28.17',
          len2818To2897: 'Len 28.18 - 28.97',
          lenGte2898: 'Len >= 28.98',
          strLte2100: 'Str <= 21.00',
          str2100To2299: 'Str 21 - 22.99',
          str2300To2499: 'Str 23 - 24.99',
          str2500To2699: 'Str 25 - 26.99',
          str2700To2799: 'Str 27 - 27.99',
          str2800To2899: 'Str 28 - 28.99',
          str2900To3199: 'Str 29 - 31.99',
          strGte3200: 'Str >= 32.00',
          micLte249: 'Mic <= 2.49',
          mic250To269: 'Mic 2.50 - 2.69',
          mic270To299: 'Mic 2.70 - 2.99',
          mic300To329: 'Mic 3.00 - 3.29',
          mic330To349: 'Mic 3.30 - 3.49',
          mic350To429: 'Mic 3.50 - 4.29',
          mic430To459: 'Mic 4.30 - 4.59',
          mic460To490: 'Mic 4.60 - 4.90',
          mic491To529: 'Mic 4.91 - 5.29',
          micGte530: 'Mic >= 5.30',
          netWeight: 'Net Weight',
          staple: 'Staple',
          controller: 'Controller',
          buyingOffice: 'Buying Office',
          part4: 'Part4'
        }
      },
      takeUpPremiumDiscountApplication: {
        title: 'P/D Application',
        company: 'Company',
        cropYear: 'Crop Year',
        setupDate: 'Setup Date',
        contract: 'Contract',
        lotRef: 'Lot Ref',
        pd: 'P/D',
        grade: 'Purchase Grade',
        itasPd: 'ITAS P/D',
        status: 'Status',
        priceCode: 'Price Code',
        ccy: 'CCY',
        ukGin: 'UK GIN',
        ptax: 'PTAX',
        marks: 'MARKS',
        invoiced: 'Invoiced (Y/N)',
        lastItasUpdatedDate: 'Last Update ITAS'
      },
      takeUpHviExtraction: {
        title: 'HVI Extraction',
        company: 'Company',
        cropYear: 'Crop Year',
        producerName: 'Producer',
        warehouseCode: 'Warehouse',
        gin: 'UK Gin',
        contract: 'Contract',
        marks: 'MARKS',
        setupDate: 'Setup Date',
        lotRef: 'Lot',
        bale: 'Bale',
        weight: 'Weight',
        weightUnity: 'Unity',
        lastItasUpdatedDate: 'Last Update ITAS',
        messages: {
          packingListExtract: 'Packing List',
          hviExtract: 'HVI'
        }
      },
      takeUpContractsPosition: {
        messages: {
          calculate: 'Calculate',
          confirmCalculateByFilter:
            'Are you sure you want to calculate Contract Position from filtered contracts?',
          confirmCalculateByFilterOnAllItems:
            'There is NO filter applied! Are you sure you want to calculate Contract Position on ALL contracts?',
          calculateWithErrors:
            'During the calculation, errors were identified. Check Notifications for error messages.',
          calculateSuccessMsg:
            'Calculation of Position of Contracts performed successfully!'
        },
        title: 'Position of Contracts',
        contractDate: 'Contract Date',
        cropYear: 'Crop Year',
        company: 'Company',
        counterPartyName: 'Counter Party Name',
        counterPartyRef: 'Counter Party Ref',
        producerName: 'Producer',
        region: 'Region',
        type: 'Type',
        fixed: 'Fixed',
        toFix: 'To Fix',
        mtm: 'MTM',
        price: 'Price',
        currency: 'Currency',
        state: 'State',
        fas: 'FAS',
        contract: 'Contract',
        quantity: 'Quantity',
        quantityUnit: 'Quantity Unit',
        takeUpAccepted: 'Take-Up (Accepted)',
        takeUpDealing: 'Take-Up (Dealing)',
        balance: 'Balance',
        terms: 'Terms',
        controller: 'Controller',
        merchant: 'Merchant',
        jul: 'JUL',
        aug: 'AUG',
        sep: 'SEP',
        oct: 'OCT',
        nov: 'NOV',
        dec: 'DEC',
        jan: 'JAN',
        feb: 'FEB',
        mar: 'MAR',
        apr: 'APR',
        may: 'MAY',
        jun: 'JUN',
        underOver: 'Under / Over'
      },
      logisticPortalDomesticMarket: {
        title: 'Domestic Market (Schedule)',
        chargingStatus: 'Charging Status',
        unloadingStatus: 'Unloading Status',
        cropYear: 'Crop Year',
        company: 'Company',
        purchaseCounterpartyCode: 'Purchase Counter party Code',
        purchaseCounterpartyName: 'Purchase Counter Party Name',
        purchaseCounterpartyReference: 'Purchase Counter Party Reference',
        ukGin: 'Uk Gin',
        warehouse: 'Warehouse',
        target: 'Destination',
        purchaseContractReference: 'Purchase Contract',
        lotReference: 'Lot Reference',
        balesQuantiy: 'Bales Quantiy',
        netWeight: 'Net Weight',
        purchaseGrade: 'Purchase Grade',
        cargillGrade: 'Cargill Grade',
        purchaseContractPrice: 'Purchase Contract Price',
        price: 'Price',
        instruction: 'Marks',
        allocatedSale: 'Allocated Sale',
        planId: 'Plan Id',
        groupNumber: 'Group Number',
        part4: 'Part4',
        part5: 'Part5',
        purchaseInvoice: 'Purchase Invoice',
        saleInvoice: 'Sale Invoice',
        suppliersInvoiceReference: 'Suppliers Invoice Ref',
        saleFiscalNote: 'Sale Fiscal Note',
        buyer: 'Buyer',
        destination: 'Destination City',
        subType: 'SubType',
        purchaseCounterpartySector: 'Purchase Counter Party Sector',
        purchaseContractTerms: 'Purchase Contract Terms',
        saleContractTerms: 'Contract Terms',
        haulier: 'Haulier',
        vehiclePlate: 'Vehicle Plate',
        releaseDate: 'Release Date',
        requestedUnloadingDate: 'Requested Unloading Date',
        expectedStartLoadDate: 'Expected Start Load Date',
        expectedEndLoadDate: 'Expected End Load Date',
        farmArrivalDate: 'Farm Arrival Date',
        loadDate: 'Load Date',
        arrivalMinimumDate: 'Arrival Minimum Date',
        arrivalMaximumDate: 'Arrival Minimum Date',
        expectedArrivalDate: 'Expected Arrival Date',
        arrivalDate: 'Arrival Date',
        storageDate: 'Storage Date',
        vehicleLayover: 'Vehicle Layover',
        reason: 'Reason',
        inactive: 'Inactive'
      },
      logisticPortalStorage: {
        title: 'Storage (Puxinha)',
        cropYear: 'Crop Year',
        company: 'Company',
        chargingStatus: 'Charging Status',
        unloadingStatus: 'Unloading Status',
        releaseAllocation: 'Release Allocation',
        scheduleSend: 'Schedule Send',
        instruction: 'Marks',
        groupNumber: 'Group Number',
        planId: 'Plan Id',
        ukGin: 'Uk Gin',
        warehouse: 'Warehouse',
        target: 'Destination',
        purchaseContractReference: 'Purchase Contract',
        lotReference: 'Lot Reference',
        balesQuantiy: 'Bales Quantiy',
        netWeight: 'Net Weight',
        purchaseContractTerms: 'Purchase Contract Terms',
        purchaseCounterpartySector: 'Purchase Counter Party Sector',
        expectedStartLoadDate: 'Expected Start Load Date',
        expectedEndLoadDate: 'Expected End Load Date',
        farmArrivalDate: 'Farm Arrival Date',
        loadDate: 'Load Date',
        arrivalMinimumDate: 'Arrival Minimum Date',
        arrivalMaximumDate: 'Arrival Minimum Date',
        expectedArrivalDate: 'Expected Arrival Date',
        arrivalDate: 'Arrival Date',
        bulkCompleteStatus: 'Bulk Status',
        vehicleLayover: 'Vehicle Layover',
        reason: 'Reason',
        freeGroupsCompleteStatus: 'Free Groups Status',
        storageDate: 'Storage Date'
      },
      takeUpReturnsAndClaimsMaster: {
        title: 'TakeUp Returns And Claims',
        id: 'Code',
        purchaseContractCompany: 'Company',
        contract: 'Contract',
        purchaseContractCropYear: 'Crop Year',
        producerName: 'Counterparty',
        farm: 'Gin',
        company: 'Company',
        cropYear: 'Crop Year',
        lotReference: 'Lot Reference',
        currentQuantity:'Current Quantity',
        allQuantity: 'All Quantity',
        purchaseContract: 'PurchaseContract',
        saiCode: 'S.A.I. Code (Brz)',
        quantity: 'Quantity',
        observation: 'Observation',
        registryDate: 'Data do Registro',
        update: 'Update',
        status: 'Status',
        type: 'Type',
        marks: 'MARKS',
        tranche: 'Tranche',
        takeUpReturnsAndClaims: {
          title: 'Returns And Claims Details',
          id: 'Code',
          company: 'Company',
          contract: 'Contract',
          cropYear: 'Crop Year',
          farm: 'Gin',
          lotReference: 'Lot Reference',
          saiCode: 'S.A.I. Code (Brz)',
          quantity: 'Quantity',
          observation: 'Observation',
          registryDate: 'Registry Date',          
          update: 'Update',
          status: 'Status',
          type: 'Type',
          marks: 'MARKS',
          tranche: 'Tranche',
        }
      }
    },
    constantes: {
      anoFiscal: 'Fical Year',
      safra: 'Harvest'
    },
    errors: {
      requiredEndPeriod: 'Period end date is mandatory!',
      startDateLessEndDate: 'The start date must be less than the end date!',
      cropYearMax: 'You can only select a maximum of two crops!',
      cropYearSubsequent: 'You can only select subsequent crops!',
      periodOutOfCrop: 'Period outside the range of selected crops!'
    },
    filters: {
      cleanFilter: 'Clean Filter'
    },
    components: {
      chartIndicator: {
        effectiveSubtitle: 'E EFETIVO',
        offendingSubtitle: 'O OFENSOR'
      }
    },
    misc: {
      exportCsv: 'Export Csv',
      exportCsvImportInItas: 'Export Csv with data to import in ITAS'
    }
  }
}
