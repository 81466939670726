<template>
  <bc-wizard-step-panel
    :title="$t('core.import.importing.importingData')"
    :subtitle="$t('core.import.importing.description')"
    icon="fa-database"
  >
    <div class="import-step__progress-container">
      <div class="import-step__entity-name">
        <span class="text-uppercase font-weight-bold light-grey--text">
          {{ name }}
        </span>
      </div>
      <div class="import-step__file-name">
        <span class="light-grey--text">{{ getFileName() }}</span>
      </div>
      <bc-progress-linear indeterminate></bc-progress-linear>
    </div>
  </bc-wizard-step-panel>
</template>

<script>
import { BcWizardStepPanel, BcProgressLinear } from '@brain/ui'
export default {
  name: 'ImportingProcessStep',
  components: { BcWizardStepPanel, BcProgressLinear },
  data() {
    return {
      file: null,
      error: false,
      importResult: null,
      importFinish: false
    }
  },
  props: {
    name: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      require: true
    },
    selectedTabs: {
      type: Array
    },
    translate: {
      type: Function,
      require: true
    }
  },
  mounted() {
    const file = this.files.length > 0 ? this.files[0] : null
    this.submitFile(file)
  },
  methods: {
    validate() {
      return this.importResult != null
    },
    async submitFile(file) {
      try {
        if (file == null || this.service == null) return

        this.file = file

        const formData = new FormData()
        formData.append('file', await this.convertFileToBlob(file), file.name)
        const importTabs = this.selectedTabs
          .map((o) => `importTabs=${o}`)
          .join('&')
        this.importResult = await this.service.import(formData, importTabs)
        this.importFinish = true
        this.file = null

        if (!this.importResult) {
          throw new Error('Failed to import data')
        }

        this.$emit('import', this.importResult)
      } catch (err) {
        this.$emit('error', err)
      }
    },
    getFileName() {
      return this.file ? this.file.name : ''
    },
    isImportFinished() {
      return this.importFinish
    },
    async convertFileToBlob(file) {
      const buffer = await file.arrayBuffer()
      return new Blob([new Uint8Array(buffer)], { type: file.type })
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-area {
  color: #959db5;

  .entity-name {
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}
.import-step {
  &__progress-container {
    padding: 0 30px;
  }

  &__file-name {
    margin: 20px 0;
    font-size: 14px;
  }
}

::v-deep .step-container {
  min-height: 640px;
  justify-content: center;
}
</style>
