<template>
  <div>
    <div class="main-toolbar"></div>
    <bc-wizard
      ref="bcwizard"
      hideButtonsOnLastStep
      :save-action="onCreate"
      :disableButtonBack="disableButtonBack"
      :backAction="onBackAction"
      @cancel="onCloseHandler"
      @change="onChange"
    >
      <tab-content
        :title="$t('core.import.select.title')"
        :beforeChange="onSelectFileStepChange"
        v-slot="{ active }"
        lazy
      >
        <select-file-step
          v-if="active"
          ref="selectFileStep"
          :name="_name"
          v-model="files"
          :service="service"
          :label="label"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.selectTabs.title')"
        :beforeChange="onSelectTabStepChange"
        v-slot="{ active }"
        lazy
      >
        <select-tab-step
          v-if="active"
          ref="selectTabStep"
          :translate="translate"
          :name="_name"
          v-model="selectedTabs"
          :files="files"
          :templateCustomPages="templateCustomPages"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.importing.title')"
        :beforeChange="onImportingStepChange"
        v-slot="{ active }"
        lazy
      >
        <import-process-step
          v-if="active"
          ref="importProcessStep"
          :service="_service"
          :name="_name"
          :translate="translate"
          :files="files"
          :selectedTabs="selectedTabs"
          @import="onImportHandler"
          @error="onImportErrorHandler"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.result.title')"
        v-slot="{ active }"
        lazy
      >
        <import-result-step
          v-if="active"
          v-model="result"
          :name="name"
          :service="_service"
        />
      </tab-content>

      <tab-content
        :title="$t('core.import.conclusion.title')"
        v-slot="{ active }"
      >
        <import-conclusion-step
          v-if="active"
          ref="conclusionStep"
          :name="_name"
          :label="label"
          @resetImport="onResetImport()"
          @close="onCloseHandler"
        />
      </tab-content>
    </bc-wizard>
  </div>
</template>

<script>
import { BcWizard } from '@brain/ui'
import { TabContent } from 'vue-form-wizard'

import {
  SelectFileStep,
  SelectTabStep,
  ImportProcessStep
} from './CustomImportSteps/import-wizard-Custom'

import {
  ImportResultStep,
  ImportConclusionStep
} from './CustomImportSteps/single-import-wizard'

export default {
  name: 'CgCrudImportCustomWithAlert',
  components: {
    SelectFileStep,
    SelectTabStep,
    TabContent,
    BcWizard,
    ImportProcessStep,
    ImportResultStep,
    ImportConclusionStep
  },
  props: {
    name: {
      type: String,
      require: true
    },
    label: {
      type: String,
      require: true
    },
    service: {
      type: Object,
      require: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    translate: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      currentStep: 'importProcessStep',
      files: [],
      selectedTabs: [],
      result: {},
      planning: {
        planningName: null,
        copyPlanning: null
      },
      disableButtonBack: false,
      templateCustomPages: [this.label]
    }
  },
  computed: {
    _service() {
      const service = this.service
      return service
    },
    _name() {
      return this.translate(`${this.$route.params.name}.title`)
    }
  },
  methods: {
    async onCreate() {},
    async onChange(args) {
      const [, nextIndex] = args
      this.disableButtonBack = Array.from([1, 2]).includes(nextIndex)
    },
    onSelectFileStepChange() {
      const stepRef = this.$refs.selectFileStep
      if (stepRef == null) return false
      const valid = stepRef.validate()
      return valid
    },
    onSelectTabStepChange() {
      const stepRef = this.$refs.selectTabStep
      if (stepRef == null) return false
      const valid = stepRef.validate()
      return valid
    },
    onImportingStepChange() {
      return this.$refs.importProcessStep.isImportFinished()
    },
    onResetImport() {
      this.$refs.bcwizard.$refs.wizard.reset()
    },
    onImportHandler(importResult) {
      this.result = importResult
      if (this.result) {
        this.$refs.bcwizard.$refs.wizard.nextTab()
      }
      this.files = []
    },
    onImportErrorHandler(importResult) {
      this.result = importResult
      if (this.result) {
        this.$refs.bcwizard.$refs.wizard.nextTab()
      }
      this.files = []
    },
    onCloseHandler() {
      this.$emit('close', { parentRoute: this.$route.params.parentRoute })
    },
    onBackAction(args, ref) {
      ref.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
