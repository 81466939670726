<template>
  <bc-wizard-step-panel
    :status="$t('core.import.selectTabs.selectTabsImport')"
    compact
  >
    <div class="default">
      <bc-grid
        v-if="file != null"
        :gridOptions="gridOptions"
        inline
        mode="clientSide"
        rowSelection="multiple"
        :columnDefs="columns"
        :rowData="data"
        style="height: 480px"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
      />
    </div>
  </bc-wizard-step-panel>
</template>

<script>
import { BcGrid } from '@brain/grid'
import { BcWizardStepPanel } from '@brain/ui'
import _ from 'lodash'

const xlsx = require('xlsx')
const TRUNCATE_SIZE = 31
export default {
  name: 'SelectTabStep',
  components: { BcWizardStepPanel, BcGrid },
  data() {
    return {
      file: null,
      selectedTabs: [],
      data: [],
      fileTabs: [],
      columns: [],
      gridOptions: {
        isRowSelectable: (node) => this.isMatch(node.data.tab),
        pagination: false,
        defaultColDef: {
          filter: false,
          sortable: false,
          resizable: false,
          suppressMovable: true,
          suppressColumnMove: true,
          suppressColumnFilter: true,
          suppressColumnsToolPanel: true
        }
      }
    }
  },
  props: {
    name: {
      type: String,
      require: true
    },
    files: {
      type: Array,
      require: true
    },
    translate: {
      type: Function,
      require: true
    },
    templateCustomPages: {
      type: Array
    }
  },
  mounted() {
    const file = this.files.length > 0 ? this.files[0] : null
    this.loadFileData(file)
  },
  computed: {
    templateCustomPagesTruncated() {
      const pages = _.isEmpty(this.templateCustomPages)
        ? []
        : this.templateCustomPages
      return pages.map((o) => o.substring(0, TRUNCATE_SIZE))
    }
  },
  methods: {
    getColumns(file) {
      return [
        {
          headerName: this.getFileName(file),
          children: [
            {
              headerName: this.$t('core.import.selectTabs.tabs'),
              field: 'tab',
              checkboxSelection: true
            },
            {
              headerName: this.getTabsMatch(),
              field: 'match',
              cellRendererParams: {
                pages: this.getPages(),
                truncatedPages: this.getTruncatedPages()
              },
              cellRenderer: (params) => {
                let match, pageName

                match = this.templateCustomPagesTruncated.includes(params.value)
                pageName = this.templateCustomPagesTruncated.find(
                  (p) => p === params.value
                )

                const icon = match ? 'fa-check' : 'fa-exclamation-triangle'
                const text = match
                  ? pageName
                  : this.$t('core.import.selectTabs.informationNotFound')
                const color = match ? '#81DD1F' : '#FF9A00'
                return `<i class="fa ${icon}" style="color: ${color} !important"></i>
                  <span style="color: ${
                    !match ? color : ''
                  }">&nbsp; ${text}</span>`
              }
            }
          ]
        }
      ]
    },
    getData() {
      return this.fileTabs.map((tab) => ({
        tab: tab,
        match: tab
      }))
    },
    getPages() {
      return [this.name]
    },
    getTruncatedPages() {
      const pages = this.getPages()
      return pages.map((o) => o.substring(0, TRUNCATE_SIZE))
    },
    loadFileData(file) {
      const _vm = this
      var r = new FileReader()
      r.onload = () => {
        const workbook = xlsx.read(r.result, {
          type: 'binary',
          bookSheets: true
        })
        _vm.fileTabs = workbook.SheetNames
        _vm.columns = _vm.getColumns(file)
        _vm.data = _vm.getData(file)
        _vm.file = file
      }
      r.readAsBinaryString(file)
    },
    getFileName(file) {
      return file?.name ?? ''
    },
    getMatchedCount() {
      var matched = 0
      const pages = this.getTruncatedPages()
      this.fileTabs.forEach((tab) => {
        if (pages.includes(tab)) matched = matched + 1
      })
      return matched
    },
    getTabsMatch() {
      const total = this.fileTabs.length
      const matched = this.getMatchedCount()
      return `Matched ${matched}/${total}`
    },
    isMatch(value) {
      const pagesTruncated = this.getTruncatedPages()
      return pagesTruncated.includes(value)
    },
    onSelectionChanged(params) {
      this.selectedTabs = params.api.getSelectedRows().map((o) => o.tab)
      this.$emit('input', this.selectedTabs)
    },
    onGridReady(params) {
      this.selectedTabs = this.templateCustomPages
      this.$emit('input', this.selectedTabs)
      params.api.forEachNode((node) => {
        node.setSelected(this.isMatch(node.data.tab))
      })
    },
    validate() {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.loading-area {
  color: #959db5;

  .entity-name {
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}
.import-step {
  &__progress-container {
    padding: 0 30px;
  }

  &__file-name {
    margin: 20px 0;
    font-size: 14px;
  }
}
</style>
